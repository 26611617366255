import React, { Component } from "react";
import { StoreState } from "../../reducers";
import { Auth, FC_GetCommunicationChannels } from "../../actions";
import { connect } from "react-redux";
import { FC_GetServiceComplaintsListByUnit } from "../../actions/complaints.action";
import { UserAccessList, isAccessAuthorized } from "../../config/userAccess";
import { AllUnitsComplaintsList } from "./AllUnitsComplaintsList";
import { UnitComplaintsList } from "./UnitComplaintsList";

export enum ComplaintStatus {
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
  REJECTED = "REJECTED",
  PROGRESS = "PROGRESS",
}

export enum ComplaintTypeEnum {
  SERVICE = "SERVICE",
  PRODUCT = "PRODUCT",
}

export enum LanguageEnum {
  KINY = "KINY",
  ENG = "ENG",
  FR = "FR",
}

export interface ServiceComplaintType {
  complaint_reason_id: string;
  complaint_reason: string;
}

export const ComplaintStatusDiv = (status: ComplaintStatus) => {
  switch (status) {
    case ComplaintStatus.COMPLETED:
      return (
        <div className="px-2 rounded-full bg-green-100 text-green-700 text-xs w-max border border-green-300 py-1">
          Completed
        </div>
      );
    case ComplaintStatus.PENDING:
      return (
        <div className="px-2 rounded-full bg-yellow-100 text-yellow-700 text-xs w-max border border-yellow-300 py-1">
          Pending
        </div>
      );
    case ComplaintStatus.PROGRESS:
      return (
        <div className="px-2 rounded-full bg-primary-50 text-primary-700 text-xs w-max border border-primary-300 py-1">
          In Progress
        </div>
      );
    case ComplaintStatus.REJECTED:
      return (
        <div className="px-2 rounded-full bg-red-100 text-red-700 text-xs w-max border border-red-300 py-1">
          Rejected
        </div>
      );
  }
};

interface ComplaintsListProps {
  auth: Auth;
  serviceType: ComplaintTypeEnum;
  FC_GetCommunicationChannels: (callBack: (loading: boolean) => void) => void;
}
interface ComplaintsListState {}

class _ComplaintsList extends Component<
  ComplaintsListProps,
  ComplaintsListState
> {
  constructor(props: ComplaintsListProps) {
    super(props);
    this.state = {};
  }
  componentDidMount(): void {}

  render() {
    if (
      isAccessAuthorized(
        this.props.auth.selectedEmployment,
        UserAccessList.VIEW_ALL_UNITS_COMPLAINTS
      ).view === true
    ) {
      return <AllUnitsComplaintsList />;
    }
    return (
      <UnitComplaintsList
        FC_GetCommunicationChannels={this.props.FC_GetCommunicationChannels}
      />
    );
  }
}

const mapStateToProps = ({
  auth,
}: StoreState): {
  auth: Auth;
} => {
  return { auth };
};

export const ComplaintsList = connect(mapStateToProps, {
  FC_GetServiceComplaintsListByUnit,
  FC_GetCommunicationChannels,
})(_ComplaintsList);
