import React, { useState } from "react";
import {
  Auth,
  ProductComplaintItemInterface,
  ServiceComplaintItemInterface,
  UnitInterface,
} from "../../actions";
import { SelectUnit } from "../../components/SelectUnit/SelectUnit";
import { MdClose } from "react-icons/md";
import { FaForward } from "react-icons/fa";
import LoaderComponent from "../../components/Loading/LoaderComponent";
import Alert, { AlertType } from "../../components/Alert/Alert";

interface ForwardQueryProps {
  auth: Auth;
  productComplaint: ProductComplaintItemInterface | null;
  serviceComplaint: ServiceComplaintItemInterface | null;
  onClose: () => void;
  onSubmitted: () => void;
  FC_ComplaintForwarding: (
    data: {
      query_id: string;
      forward_from_unit_id: string;
      forward_from_unit_name: string;
      forward_to_unit_id: string;
      forward_to_unit_name: string;
      forwarded_by: string; // UserId
    },
    callback: (
      loading: boolean,
      res: {
        type: "SUCCESS" | "ERROR";
        msg: string;
      } | null
    ) => void
  ) => void;
}

const ForwardQuery = ({
  auth,
  onClose,
  productComplaint,
  serviceComplaint,
  onSubmitted,
  FC_ComplaintForwarding,
}: ForwardQueryProps) => {
  const [loading, setLoading] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState<UnitInterface | null>(null);
  const [unitsList, setUnitsList] = useState<UnitInterface[]>([]);
  const [success, setSuccess] = useState<string>("");
  const [error, setError] = useState<string>("");

  const getUnitName = (unitId: string) => {
    const selected = unitsList.find(
      (itm) => itm.unit_id.toString() === unitId.toString()
    );
    return selected ?? { unit_id: "", unit_name: "" };
  };

  const SubmitQueryForwarding = () => {
    if (
      window.confirm("Are you sure do you want to forward this query?") ===
        true &&
      loading === false
    ) {
      setLoading(true);
      selectedUnit !== null &&
        auth.user !== null &&
        FC_ComplaintForwarding(
          {
            query_id:
              productComplaint !== null
                ? productComplaint.product_complaint_id
                : serviceComplaint !== null
                ? serviceComplaint.service_complaint_id
                : "",
            forward_from_unit_id:
              productComplaint !== null
                ? productComplaint.unit_id
                : serviceComplaint !== null
                ? serviceComplaint.unit_id
                : "",
            forward_from_unit_name: getUnitName(
              productComplaint !== null
                ? productComplaint.unit_id
                : serviceComplaint !== null
                ? serviceComplaint.unit_id
                : ""
            ).unit_name,
            forward_to_unit_id: selectedUnit.unit_id.toString(),
            forward_to_unit_name: selectedUnit.unit_name,
            forwarded_by: auth.user.user_id, // User logged
          },
          (
            loading: boolean,
            res: { type: "SUCCESS" | "ERROR"; msg: string } | null
          ) => {
            setLoading(loading);
            if (res?.type === "SUCCESS") {
              setLoading(true);
              setSuccess(res.msg);
              setError("");
              setTimeout(() => {
                onSubmitted();
              }, 1000);
            }
            if (res?.type === "ERROR") {
              setError(res.msg);
              setSuccess("");
              setLoading(false);
            }
          }
        );
    }
  };
  return (
    <div className="bg-white rounded-xl p-2 animate__animated animate__fadeInUp animate__faster shadow-xl">
      {loading === true ? (
        <LoaderComponent />
      ) : selectedUnit === null ? (
        <div className="">
          <SelectUnit
            positionsCounter={[]}
            onSelect={(unit: UnitInterface | null) => setSelectedUnit(unit)}
            onClose={() => loading === false && onClose()}
            title="Choose a unit/division you want to forward the query"
            onUnitsCallBack={(units: UnitInterface[]) => setUnitsList(units)}
          />
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center gap-2 text-center md:p-3">
          <div className="text-2xl font-bold">Query Forwarding</div>
          <div className="flex flex-row items-center gap-2">
            <div>
              <MdClose className="text-3xl text-yellow-600" />
            </div>
            <div className="text-yellow-700 line-through text-lg">
              {
                unitsList.find(
                  (itm) =>
                    itm.unit_id.toString() ===
                      productComplaint?.unit_id?.toString() ||
                    itm.unit_id.toString() ===
                      serviceComplaint?.unit_id?.toString()
                )?.unit_name
              }
            </div>
          </div>
          {/* Forward to */}
          <div className="flex flex-row items-center gap-2">
            <div>
              <FaForward className="text-3xl text-green-500" />
            </div>
            <div className="text-green-600 font-extrabold text-lg">
              {selectedUnit.unit_name}
            </div>
          </div>
          <div className="flex flex-row items-center justify-center gap-2">
            <div
              onClick={() => setSelectedUnit(null)}
              className="px-6 py-2 rounded-md bg-gray-100 hover:bg-gray-200 text-black cursor-pointer w-full"
            >
              Cancel
            </div>
            <div
              onClick={SubmitQueryForwarding}
              className="px-6 py-2 rounded-md bg-primary-700 hover:bg-primary-800 text-white cursor-pointer w-full"
            >
              <div className="truncate w-max">Save & Confirm</div>
            </div>
          </div>
        </div>
      )}
      {/* Alerts */}
      {success !== "" && (
        <div className="py-3">
          <Alert
            alertType={AlertType.SUCCESS}
            title={success}
            close={() => setSuccess("")}
          />
        </div>
      )}
      {error !== "" && (
        <div className="py-3">
          <Alert
            alertType={AlertType.DANGER}
            title={error}
            close={() => setError("")}
          />
        </div>
      )}
    </div>
  );
};

export default ForwardQuery;
