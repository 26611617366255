import React, { Component, Fragment } from "react";
import { StoreState } from "../../reducers";
import { Auth, System } from "../../actions";
import { connect } from "react-redux";
import { MdHelp } from "react-icons/md";
import {
  ServiceComplaintItemInterface,
  FC_GetServiceComplaintsListByUnit,
  ProductComplaintItemInterface,
  FC_GetProductComplaintsListByUnit,
} from "../../actions/complaints.action";
import Modal, { ModalSize, Themes } from "../../components/Modal/Modal";
import { ServiceComplaintDetails } from "./ServiceComplaintDetails";
import { ListOfServiceComplaints } from "./ListOfServiceComplaints";
import { UnitDashboard } from "./UnitDashboard";
import { UserAccessList, isAccessAuthorized } from "../../config/userAccess";
import {
  ComplaintTypeEnum,
  LanguageEnum,
  ComplaintStatus,
} from "./ComplaintsList";
import { ListOfProductComplaints } from "./ListOfProductComplaints";
import { ProductComplaintDetails } from "./ProductComplaintDetails";
import { DATE, getDateFromToday } from "../../utils/functions";

interface UnitComplaintsListProps {
  auth: Auth;
  system: System;
  FC_GetServiceComplaintsListByUnit: (
    data: {
      unit_id: string;
      start_date: string;
      ending_date: string;
    },
    callback: (
      loading: boolean,
      res: {
        type: "SUCCESS" | "ERROR";
        msg: string;
        data: ServiceComplaintItemInterface[];
      } | null
    ) => void
  ) => void;
  FC_GetProductComplaintsListByUnit: (
    data: {
      unit_id: string;
      start_date: string;
      ending_date: string;
    },
    callback: (
      loading: boolean,
      res: {
        type: "SUCCESS" | "ERROR";
        msg: string;
        data: ProductComplaintItemInterface[];
      } | null
    ) => void
  ) => void;
  FC_GetCommunicationChannels: (callBack: (loading: boolean) => void) => void;
}
interface UnitComplaintsListState {
  serviceType: ComplaintTypeEnum;
  serviceComplaintsList: ServiceComplaintItemInterface[] | null;
  productComplaintsList: ProductComplaintItemInterface[] | null;
  loading: boolean;
  error: string;
  success: string;
  language: LanguageEnum;
  selectedServiceComplaint: ServiceComplaintItemInterface | null;
  selectedProductComplaint: ProductComplaintItemInterface | null;
  openList: ComplaintStatus | null;
  start_date: string;
  ending_date: string;
}

class _UnitComplaintsList extends Component<
  UnitComplaintsListProps,
  UnitComplaintsListState
> {
  constructor(props: UnitComplaintsListProps) {
    super(props);
    this.state = {
      serviceType: ComplaintTypeEnum.SERVICE,
      serviceComplaintsList: null,
      loading: false,
      error: "",
      success: "",
      language: LanguageEnum.ENG,
      selectedServiceComplaint: null,
      openList: null,
      productComplaintsList: null,
      selectedProductComplaint: null,
      start_date: getDateFromToday(730).toUTCString(),
      ending_date: new Date().toUTCString(),
    };
  }
  GetComplaintsList = (
    start_date: string,
    ending_date: string,
    type: ComplaintTypeEnum,
    unit_id: string,
    selectedServiceComplaint: ServiceComplaintItemInterface | null,
    selectedProductComplaint: ProductComplaintItemInterface | null
  ) => {
    this.setState({ loading: true });
    if (type === ComplaintTypeEnum.SERVICE) {
      this.props.FC_GetServiceComplaintsListByUnit(
        {
          unit_id: unit_id,
          start_date: DATE(start_date, "YYYY/MM/DD"),
          ending_date: DATE(ending_date, "YYYY/MM/DD"),
        },
        (
          loading: boolean,
          res: {
            type: "SUCCESS" | "ERROR";
            msg: string;
            data: ServiceComplaintItemInterface[];
          } | null
        ) => {
          this.setState({ loading: loading });
          if (res?.type === "SUCCESS") {
            this.setState({
              loading: false,
              serviceComplaintsList: res.data,
              error: "",
              success: "",
            });
            if (selectedServiceComplaint !== null) {
              const selectedComplaintData = res.data.find(
                (itm) =>
                  itm.service_complaint_id ===
                  selectedServiceComplaint.service_complaint_id
              );
              if (selectedComplaintData !== undefined) {
                this.setState({
                  selectedServiceComplaint: selectedComplaintData,
                });
              }
            }
          }
          if (res?.type === "ERROR") {
            this.setState({
              loading: false,
              error: res.msg,
              success: "",
              serviceComplaintsList: [],
            });
          }
        }
      );
    } else {
      this.props.FC_GetProductComplaintsListByUnit(
        {
          unit_id: unit_id,
          start_date: DATE(start_date, "YYYY/MM/DD"),
          ending_date: DATE(ending_date, "YYYY/MM/DD"),
        },
        (
          loading: boolean,
          res: {
            type: "SUCCESS" | "ERROR";
            msg: string;
            data: ProductComplaintItemInterface[];
          } | null
        ) => {
          this.setState({ loading: loading });
          if (res?.type === "SUCCESS") {
            this.setState({
              loading: false,
              productComplaintsList: res.data,
              error: "",
              success: "",
            });
            if (selectedProductComplaint !== null) {
              const selectedComplaintData = res.data.find(
                (itm) =>
                  itm.product_complaint_id ===
                  selectedProductComplaint.product_complaint_id
              );
              if (selectedComplaintData !== undefined) {
                this.setState({
                  selectedProductComplaint: selectedComplaintData,
                });
              }
            }
          }
          if (res?.type === "ERROR") {
            this.setState({
              loading: false,
              error: res.msg,
              success: "",
              serviceComplaintsList: [],
            });
          }
        }
      );
    }
  };

  componentDidMount(): void {}
  onUpdateServiceComplaintStatus = (
    data: {
      complaint_status: ComplaintStatus;
      complaint_reason: string;
      service_complaint_id: string;
    },
    callBack: (completedChanges: boolean) => void
  ) => {
    callBack(false);
    this.setState({ loading: true });
    this.state.serviceComplaintsList !== null &&
      this.props.auth.selectedEmployment !== null &&
      this.GetComplaintsList(
        this.state.start_date,
        this.state.ending_date,
        this.state.serviceType,
        this.props.auth.selectedEmployment.unit_id,
        this.state.selectedServiceComplaint,
        this.state.selectedProductComplaint
      );
    if (this.state.loading === false) {
      callBack(true);
    }
  };

  onUpdateProductComplaintStatus = (
    data: {
      complaint_status: ComplaintStatus;
      complaint_reason: string;
      product_complaint_id: string;
    },
    callBack: (completedChanges: boolean) => void
  ) => {
    callBack(false);
    this.setState({ loading: true });
    this.state.serviceComplaintsList !== null &&
      this.props.auth.selectedEmployment !== null &&
      this.GetComplaintsList(
        this.state.start_date,
        this.state.ending_date,
        this.state.serviceType,
        this.props.auth.selectedEmployment.unit_id,
        this.state.selectedServiceComplaint,
        this.state.selectedProductComplaint
      );
    if (this.state.loading === false) {
      callBack(true);
    }
  };
  render() {
    return (
      <Fragment>
        <div className="pt-3">
          <div className="flex flex-row items-center justify-between gap-2 w-full pl-2">
            <div className="flex flex-row items-center gap-3">
              <div>
                <MdHelp className="text-5xl text-gray-400" />
              </div>
              <div>
                <div className="text-xl font-bold truncate">Queries</div>
                {isAccessAuthorized(
                  this.props.auth.selectedEmployment,
                  UserAccessList.VIEW_ALL_UNITS_COMPLAINTS
                ).view === true ? (
                  <div className="text-sm text-gray-600 truncate">
                    List of queries assigned in all Rwanda FDA units/divisions
                  </div>
                ) : (
                  <div className="text-sm text-gray-600 truncate">
                    List of queries assigned to the{" "}
                    {this.props.auth.selectedEmployment?.unit_name}
                  </div>
                )}
              </div>
            </div>
            {/* Right side */}
            <div className="flex flex-row items-center justify-end gap-2 w-1/2">
              <div className="bg-white rounded-md overflow-hidden">
                <div className="flex flex-row items-center">
                  <div
                    onClick={() =>
                      this.setState({ serviceType: ComplaintTypeEnum.SERVICE })
                    }
                    className={`flex flex-row items-center gap-2 px-5 py-2 border-b-4 ${
                      this.state.serviceType === ComplaintTypeEnum.SERVICE
                        ? "border-primary-700 text-primary-800 font-bold animate__animated animate__fadeIn bg-primary-50"
                        : "border-white hover:border-primary-300 hover:text-primary-800"
                    } cursor-pointer`}
                  >
                    Service
                  </div>
                  <div
                    onClick={() =>
                      this.setState({ serviceType: ComplaintTypeEnum.PRODUCT })
                    }
                    className={`flex flex-row items-center gap-2 px-5 py-2 border-b-4 ${
                      this.state.serviceType === ComplaintTypeEnum.PRODUCT
                        ? "border-primary-700 text-primary-800 font-bold animate__animated animate__fadeIn bg-primary-50"
                        : "border-white hover:border-primary-300 hover:text-primary-800"
                    } cursor-pointer`}
                  >
                    Product
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Body */}
          {this.state.openList === null ? (
            <UnitDashboard
              onOpenList={(status: ComplaintStatus) =>
                this.setState({ openList: status })
              }
              selectedType={this.state.serviceType}
              start_date={DATE(this.state.start_date, "YYYY/MM/DD")}
              ending_date={DATE(this.state.ending_date, "YYYY/MM/DD")}
              setDate={(start_date: string, ending_date: string) =>
                this.setState({
                  start_date: start_date,
                  ending_date: ending_date,
                })
              }
            />
          ) : this.state.serviceType === ComplaintTypeEnum.SERVICE ? (
            <ListOfServiceComplaints
              auth={this.props.auth}
              selectedComplaint={this.state.selectedServiceComplaint}
              setSelectedComplaint={(
                selectedComplaint: ServiceComplaintItemInterface | null
              ) =>
                this.setState({ selectedServiceComplaint: selectedComplaint })
              }
              complaintsList={
                this.state.serviceComplaintsList === null
                  ? null
                  : this.state.serviceComplaintsList.filter(
                      (itm) => itm.complaint_status === this.state.openList
                    )
              }
              loadComplaintsList={(start_date: string, ending_date: string) => {
                if (this.props.auth.selectedEmployment !== null) {
                  this.GetComplaintsList(
                    start_date,
                    ending_date,
                    this.state.serviceType,
                    this.props.auth.selectedEmployment.unit_id,
                    null,
                    null
                  );
                }
              }}
              loading={this.state.loading}
              error={this.state.error}
              success={this.state.success}
              language={this.state.language}
              setLanguage={(language: LanguageEnum) =>
                this.setState({ language: language })
              }
              onGoBack={() => this.setState({ openList: null })}
              selectedStatus={this.state.openList}
              serviceType={this.state.serviceType}
              system={this.props.system}
              start_date={DATE(this.state.start_date, "YYYY/MM/DD")}
              ending_date={DATE(this.state.ending_date, "YYYY/MM/DD")}
              setDate={(start_date: string, ending_date: string) =>
                this.setState({
                  start_date: start_date,
                  ending_date: ending_date,
                })
              }
              showUnits={false}
              units={[]}
            />
          ) : (
            <ListOfProductComplaints
              auth={this.props.auth}
              selectedComplaint={this.state.selectedProductComplaint}
              setSelectedComplaint={(
                selectedComplaint: ProductComplaintItemInterface | null
              ) =>
                this.setState({ selectedProductComplaint: selectedComplaint })
              }
              complaintsList={
                this.state.productComplaintsList === null
                  ? null
                  : this.state.productComplaintsList.filter(
                      (itm) => itm.complaint_status === this.state.openList
                    )
              }
              loadComplaintsList={(start_date: string, ending_date: string) => {
                if (this.props.auth.selectedEmployment !== null) {
                  this.GetComplaintsList(
                    start_date,
                    ending_date,
                    this.state.serviceType,
                    this.props.auth.selectedEmployment.unit_id,
                    null,
                    null
                  );
                }
              }}
              loading={this.state.loading}
              error={this.state.error}
              success={this.state.success}
              language={this.state.language}
              setLanguage={(language: LanguageEnum) =>
                this.setState({ language: language })
              }
              onGoBack={() => this.setState({ openList: null })}
              selectedStatus={this.state.openList}
              serviceType={this.state.serviceType}
              system={this.props.system}
              start_date={DATE(this.state.start_date, "YYYY/MM/DD")}
              ending_date={DATE(this.state.ending_date, "YYYY/MM/DD")}
              setDate={(start_date: string, ending_date: string) =>
                this.setState({
                  start_date: start_date,
                  ending_date: ending_date,
                })
              }
              showUnits={false}
              units={[]}
            />
          )}
        </div>
        {(this.state.selectedProductComplaint !== null ||
          this.state.selectedServiceComplaint !== null) && (
          <Modal
            backDrop={true}
            theme={Themes.default}
            close={() =>
              this.setState({
                selectedServiceComplaint: null,
                selectedProductComplaint: null,
              })
            }
            backDropClose={false}
            widthSizeClass={ModalSize.extraLarge}
            displayClose={false}
            padding={{
              title: undefined,
              body: undefined,
              footer: undefined,
            }}
          >
            {this.state.selectedServiceComplaint !== null && (
              <ServiceComplaintDetails
                complaintDetails={this.state.selectedServiceComplaint}
                selectedLanguage={this.state.language}
                onBack={() => this.setState({ selectedServiceComplaint: null })}
                onUpdateStatus={this.onUpdateServiceComplaintStatus}
                loading={this.state.loading}
                FC_GetCommunicationChannels={
                  this.props.FC_GetCommunicationChannels
                }
                onReload={() => {
                  this.setState({
                    selectedProductComplaint: null,
                    selectedServiceComplaint: null,
                  });
                  this.props.auth.selectedEmployment !== null &&
                    this.GetComplaintsList(
                      this.state.start_date,
                      this.state.ending_date,
                      this.state.serviceType,
                      this.props.auth.selectedEmployment.unit_id,
                      null,
                      null
                    );
                }}
              />
            )}
            {this.state.selectedProductComplaint !== null && (
              <ProductComplaintDetails
                complaintDetails={this.state.selectedProductComplaint}
                selectedLanguage={this.state.language}
                onBack={() => this.setState({ selectedProductComplaint: null })}
                onUpdateStatus={this.onUpdateProductComplaintStatus}
                loading={this.state.loading}
                FC_GetCommunicationChannels={
                  this.props.FC_GetCommunicationChannels
                }
                onReload={() => {
                  this.setState({
                    selectedProductComplaint: null,
                    selectedServiceComplaint: null,
                  });
                  this.props.auth.selectedEmployment !== null &&
                    this.GetComplaintsList(
                      this.state.start_date,
                      this.state.ending_date,
                      this.state.serviceType,
                      this.props.auth.selectedEmployment.unit_id,
                      null,
                      null
                    );
                }}
              />
            )}
          </Modal>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  auth,
  system,
}: StoreState): {
  auth: Auth;
  system: System;
} => {
  return { auth, system };
};

export const UnitComplaintsList = connect(mapStateToProps, {
  FC_GetServiceComplaintsListByUnit,
  FC_GetProductComplaintsListByUnit,
})(_UnitComplaintsList);
