import { Action, ActionTypes, CompanyProductStore } from "../actions";
// default state
const defaultState: CompanyProductStore = {
  productCategories: null,
  productTypes: null,
  companyCategories: null,
  companies: null,
};

/**
 * this is the action
 * @param state
 * @param action
 * @returns
 */
export const companyProductReducer = (
  state: CompanyProductStore = defaultState,
  action: Action
) => {
  switch (action.type) {
    case ActionTypes.GET_PRODUCT_CATEGORY:
      return {
        ...state,
        productCategories: action.payload,
      };
    case ActionTypes.GET_PRODUCT_TYPES:
      return {
        ...state,
        productTypes: action.payload,
      };
    case ActionTypes.GET_COMPANY_CATEGORIES:
      return {
        ...state,
        companyCategories: action.payload,
      };
    case ActionTypes.GET_ALL_COMPANIES:
      return {
        ...state,
        companies: action.payload,
      };
    case ActionTypes.LOGOUT:
      return {
        ...state,
        defaultState,
      };
    default:
      return state;
  }
};
