import React, { Component } from "react";
import { connect } from "react-redux";
import { StoreState } from "../../reducers";
import { Auth, FC_GetAllUnits, UnitInterface, UnitStore } from "../../actions";
import {
  AllUnitsTotalCountsByStatusInterface,
  AllUnitsTotalCountsInterface,
  FC_GetTotalComplaintsAllUnits,
} from "../../actions/complaints.action";
import {
  BsBarChartSteps,
  BsCheckCircle,
  BsInfoCircle,
  BsQuestionCircle,
} from "react-icons/bs";
import { MdClose, MdDashboard } from "react-icons/md";
import LoaderComponent from "../../components/Loading/LoaderComponent";
import {
  DATE,
  GetDaysFromTwoDates,
  calculateTimingFromDays,
  commaFy,
} from "../../utils/functions";
import ReactApexChart from "react-apexcharts";
import { FaChartPie } from "react-icons/fa";
import {
  ComplaintStatus,
  ComplaintStatusDiv,
  ComplaintTypeEnum,
  ServiceComplaintType,
} from "./ComplaintsList";
import Select from "../../components/Fragments/Select";
import { BiReset } from "react-icons/bi";
import SelectCustom from "../../components/SelectCustom/SelectCustom";

interface AllUnitsDashboardProps {
  auth: Auth;
  units: UnitStore;
  FC_GetTotalComplaintsAllUnits: (
    data: {
      start_date: string;
      ending_date: string;
    },
    callback: (
      loading: boolean,
      res: {
        type: "SUCCESS" | "ERROR";
        msg: string;
        data: AllUnitsTotalCountsInterface | null;
      } | null
    ) => void
  ) => void;
  onOpenList: (status: ComplaintStatus) => void;
  FC_GetAllUnits: (callback: (loading: boolean, error: string) => void) => void;
  selectedType: ComplaintTypeEnum;
  start_date: string;
  ending_date: string;
  setDate: (start_date: string, ending_date: string) => void;
}

interface AllUnitsDashboardState {
  loading: boolean;
  data: AllUnitsTotalCountsInterface | null;
  error: string;
  selectedStatus: ComplaintStatus | null;
  selectedReason: ServiceComplaintType | null;
  openSelectReason: boolean;
}

class _AllUnitsDashboard extends Component<
  AllUnitsDashboardProps,
  AllUnitsDashboardState
> {
  constructor(props: AllUnitsDashboardProps) {
    super(props);
    this.state = {
      loading: false,
      data: null,
      error: "",
      selectedStatus: null,
      openSelectReason: false,
      selectedReason: null,
    };
  }

  LoadDashboard = (start_date: string, ending_date: string) => {
    this.setState({ loading: true });
    this.props.FC_GetTotalComplaintsAllUnits(
      {
        start_date: DATE(start_date, "YYYY/MM/DD"),
        ending_date: DATE(ending_date, "YYYY/MM/DD"),
      },
      (
        loading: boolean,
        res: {
          type: "SUCCESS" | "ERROR";
          msg: string;
          data: AllUnitsTotalCountsInterface | null;
        } | null
      ) => {
        this.setState({ loading: loading });
        if (res?.type === "SUCCESS") {
          this.setState({ loading: false, data: res.data, error: "" });
        }
        if (res?.type === "ERROR") {
          this.setState({ error: res.msg, loading: false, data: null });
        }
      }
    );
  };

  GetUnitTotals = (
    data: AllUnitsTotalCountsInterface,
    unit_id: string,
    type: ComplaintTypeEnum
  ) => {
    var response: AllUnitsTotalCountsByStatusInterface = {
      total_complaint: 0,
      total_completed: 0,
      total_pending: 0,
      total_progress: 0,
      total_rejected: 0,
      unit_id: unit_id,
    };
    for (const item of (type === ComplaintTypeEnum.SERVICE
      ? data.service_count
      : data.product_count
    ).filter((itm) => itm.unit_id.toString() === unit_id.toString())) {
      response = {
        total_complaint: response.total_complaint + item.total_complaint,
        total_completed: response.total_completed + item.total_completed,
        total_pending: response.total_pending + item.total_pending,
        total_progress: response.total_progress + item.total_progress,
        total_rejected: response.total_rejected + item.total_rejected,
        unit_id: unit_id,
      };
    }
    return response;
  };

  GetAllUnitsTotals = (
    type: ComplaintTypeEnum
  ): {
    unit: UnitInterface;
    totals: AllUnitsTotalCountsByStatusInterface;
  }[] => {
    if (this.state.data === null || this.props.units.units === null) {
      return [];
    }
    var response: {
      unit: UnitInterface;
      totals: AllUnitsTotalCountsByStatusInterface;
    }[] = [];
    for (const unit of this.props.units.units.filter(
      (itm) =>
        this.state.data !== null &&
        (type === ComplaintTypeEnum.SERVICE
          ? this.state.data.service_count
          : this.state.data.product_count
        ).find((test) => test.unit_id.toString() === itm.unit_id.toString()) !==
          undefined
    )) {
      response.push({
        unit: unit,
        totals: this.GetUnitTotals(this.state.data, unit.unit_id, type),
      });
    }
    return response;
  };

  GetAllUnitsCountsByStatus = (
    selectedStatus: ComplaintStatus,
    type: ComplaintTypeEnum
  ) => {
    if (selectedStatus === ComplaintStatus.PENDING) {
      return this.GetAllUnitsTotals(type).map(
        (itm) => itm.totals.total_pending
      );
    }
    if (selectedStatus === ComplaintStatus.PROGRESS) {
      return this.GetAllUnitsTotals(type).map(
        (itm) => itm.totals.total_progress
      );
    }
    if (selectedStatus === ComplaintStatus.COMPLETED) {
      return this.GetAllUnitsTotals(type).map(
        (itm) => itm.totals.total_completed
      );
    }
    return this.GetAllUnitsTotals(type).map((itm) => itm.totals.total_rejected);
  };

  GetColor = (selectedStatus: ComplaintStatus) => {
    if (selectedStatus === ComplaintStatus.PENDING) {
      return "#fbbf24";
    }
    if (selectedStatus === ComplaintStatus.PROGRESS) {
      return "#1999d1";
    }
    if (selectedStatus === ComplaintStatus.COMPLETED) {
      return "#12b981";
    }
    return "#ef4444";
  };

  GetReasonsSummary = (
    data: AllUnitsTotalCountsInterface,
    type: ComplaintTypeEnum
  ) => {
    var response: { complaint_reason: ServiceComplaintType; total: number }[] =
      [];
    for (const item of type === ComplaintTypeEnum.SERVICE
      ? data.service_reason_count
      : data.product_reason_count) {
      if (
        response.find(
          (itm) =>
            itm.complaint_reason.complaint_reason_id.toString() ===
            item.short_reason.toString()
        ) === undefined
      ) {
        response.push({
          complaint_reason: {
            complaint_reason: item.complaint_reason_en,
            complaint_reason_id: item.short_reason,
          },
          total: item.total,
        });
      } else {
        response = response.map((itm) => ({
          ...itm,
          total:
            itm.complaint_reason.complaint_reason_id.toString() ===
            item.short_reason.toString()
              ? itm.total + item.total
              : itm.total,
        }));
      }
    }
    return response;
  };

  GetTotalsByUnitAndReason = (
    data: AllUnitsTotalCountsInterface,
    reason_id: string,
    type: ComplaintTypeEnum
  ) => {
    const response: { unit: UnitInterface; total: number }[] = [];
    for (const item of this.GetAllUnitsTotals(type)) {
      const itms = (
        type === ComplaintTypeEnum.SERVICE
          ? data.service_reason_count
          : data.product_reason_count
      ).filter(
        (itm) =>
          itm.unit_id.toString() === item.unit.unit_id.toString() &&
          itm.short_reason.toString() === reason_id.toString()
      );
      var res: { unit: UnitInterface; total: number } = {
        unit: item.unit,
        total: 0,
      };
      for (const tot of itms) {
        res = {
          unit: res.unit,
          total: res.total + tot.total,
        };
      }
      response.push(res);
    }
    return response;
  };

  componentDidMount(): void {
    if (this.props.units.units === null) {
      this.props.FC_GetAllUnits((loading: boolean, error: string) =>
        this.setState({ loading: loading, error: error })
      );
    }
    this.LoadDashboard(this.props.start_date, this.props.ending_date);
  }

  GetAllTotals = (
    data: AllUnitsTotalCountsByStatusInterface[]
  ): AllUnitsTotalCountsByStatusInterface => {
    var response: AllUnitsTotalCountsByStatusInterface = {
      total_complaint: 0,
      total_completed: 0,
      total_pending: 0,
      total_progress: 0,
      total_rejected: 0,
      unit_id: "",
    };
    for (const item of data) {
      response = {
        total_complaint: response.total_complaint + item.total_complaint,
        total_completed: response.total_completed + item.total_completed,
        total_pending: response.total_pending + item.total_pending,
        total_progress: response.total_progress + item.total_progress,
        total_rejected: response.total_rejected + item.total_rejected,
        unit_id: response.unit_id,
      };
    }
    return response;
  };

  render() {
    if (this.state.loading === true || this.state.data === null) {
      return <LoaderComponent />;
    }
    return (
      <div className="py-4">
        <div className="bg-white mb-2 rounded-lg p-3 py-2 flex flex-row items-center gap-3 justify-between w-full">
          <div className="flex flex-row items-center gap-3 w-full">
            <div>
              <MdDashboard className="text-gray-300 text-4xl" />
            </div>
            <div>
              <div className="text-lg font-bold">
                Dashboard{" "}
                {GetDaysFromTwoDates(
                  this.props.start_date,
                  this.props.ending_date
                ) < 0 ? (
                  <span
                    style={{ paddingTop: "1px", paddingBottom: "1px" }}
                    className="text-gray-500 text-sm bg-gray-100 rounded-md italic px-2"
                  >
                    Invalid dates
                  </span>
                ) : (
                  <span
                    style={{ paddingTop: "1px", paddingBottom: "1px" }}
                    className="bg-yellow-100 text-yellow-700 font-bold text-sm px-2 border border-yellow-200 rounded-lg"
                  >
                    for{" "}
                    {calculateTimingFromDays(
                      GetDaysFromTwoDates(
                        this.props.start_date,
                        this.props.ending_date
                      )
                    )}
                  </span>
                )}
              </div>
              <div className="text-sm text-gray-500">
                This shows graphic representation of submitted queries grouped
                in their statuses
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center justify-end gap-2">
            <div>
              <div className="text-xs">Start date</div>
              <input
                type="date"
                className="bg-gray-100 px-3 py-2 rounded-md text-sm font-bold"
                value={this.props.start_date}
                onChange={(e) => {
                  if (e.target.value !== "") {
                    this.LoadDashboard(e.target.value, this.props.ending_date);
                    this.props.setDate(e.target.value, this.props.ending_date);
                  }
                }}
              />
            </div>
            <div>
              <div className="text-xs">Ending date</div>
              <input
                type="date"
                className="bg-gray-100 px-3 py-2 rounded-md text-sm font-bold"
                value={this.props.ending_date}
                onChange={(e) => {
                  if (e.target.value !== "") {
                    this.LoadDashboard(this.props.start_date, e.target.value);
                    this.props.setDate(this.props.start_date, e.target.value);
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-3">
          <div className="col-span-12 md:col-span-6">
            <div className="h-max bg-white rounded-md p-3 grid grid-cols-12 gap-3">
              <div className="col-span-12 pb-2 pt-1">
                <div className="flex flex-row items-center gap-3">
                  <div>
                    <FaChartPie className="text-3xl text-primary-700" />
                  </div>
                  <div className="text-base text-gray-500 font-bold">
                    Summary by status
                  </div>
                </div>
              </div>
              <div
                onClick={() => this.props.onOpenList(ComplaintStatus.PENDING)}
                className="col-span-12 md:col-span-6 border border-yellow-400 text-black p-4 rounded-lg flex flex-row items-center gap-4 cursor-pointer hover:bg-yellow-50 group h-max animate__animated animate__fadeIn"
              >
                <div>
                  <BsInfoCircle
                    className={`text-5xl text-yellow-600 group-hover:text-yellow-700 ${
                      this.GetAllTotals(
                        this.props.selectedType === ComplaintTypeEnum.SERVICE
                          ? this.state.data.service_count
                          : this.state.data.product_count
                      ).total_pending > 0
                        ? "animate__animated animate__bounceIn animate__infinite animate__slower"
                        : ""
                    }`}
                  />
                </div>
                <div>
                  <div className="text-gray-500 group-hover:text-black">
                    Pending
                  </div>
                  <div className="text-3xl font-bold">
                    {commaFy(
                      this.GetAllTotals(
                        this.props.selectedType === ComplaintTypeEnum.SERVICE
                          ? this.state.data.service_count
                          : this.state.data.product_count
                      ).total_pending
                    )}
                  </div>
                </div>
              </div>
              <div
                onClick={() => this.props.onOpenList(ComplaintStatus.PROGRESS)}
                className="col-span-12 md:col-span-6 border border-primary-300 text-black p-4 rounded-lg flex flex-row items-center gap-4 cursor-pointer hover:bg-primary-50 group h-max animate__animated animate__fadeIn"
              >
                <div>
                  <BsQuestionCircle className="text-5xl text-primary-700" />
                </div>
                <div>
                  <div className="text-gray-500 group-hover:text-black">
                    In Progress
                  </div>
                  <div className="text-3xl font-bold">
                    {commaFy(
                      this.GetAllTotals(
                        this.props.selectedType === ComplaintTypeEnum.SERVICE
                          ? this.state.data.service_count
                          : this.state.data.product_count
                      ).total_progress
                    )}
                  </div>
                </div>
              </div>
              <div
                onClick={() => this.props.onOpenList(ComplaintStatus.COMPLETED)}
                className="col-span-12 md:col-span-6 border border-green-400 text-black p-4 rounded-lg flex flex-row items-center gap-4 cursor-pointer hover:bg-green-50 group h-max animate__animated animate__fadeIn"
              >
                <div>
                  <BsCheckCircle className="text-5xl text-green-500" />
                </div>
                <div>
                  <div className="text-gray-500 group-hover:text-black">
                    Completed
                  </div>
                  <div className="text-3xl font-bold">
                    {commaFy(
                      this.GetAllTotals(
                        this.props.selectedType === ComplaintTypeEnum.SERVICE
                          ? this.state.data.service_count
                          : this.state.data.product_count
                      ).total_completed
                    )}
                  </div>
                </div>
              </div>
              <div
                onClick={() => this.props.onOpenList(ComplaintStatus.REJECTED)}
                className="col-span-12 md:col-span-6 border border-red-400 text-black p-4 rounded-lg flex flex-row items-center gap-4 cursor-pointer hover:bg-red-50 group h-max animate__animated animate__fadeIn"
              >
                <div>
                  <MdClose className="text-5xl text-red-500" />
                </div>
                <div>
                  <div className="text-gray-500 group-hover:text-black">
                    Rejected
                  </div>
                  <div className="text-3xl font-bold">
                    {commaFy(
                      this.GetAllTotals(
                        this.props.selectedType === ComplaintTypeEnum.SERVICE
                          ? this.state.data.service_count
                          : this.state.data.product_count
                      ).total_rejected
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-12 md:col-span-6">
            <div className="bg-white p-3 py-4 rounded-md">
              <ReactApexChart
                options={{
                  chart: {
                    width: 380,
                    type: "pie",
                  },
                  labels: ["Pending", "In Progress", "Completed", "Rejected"],
                  responsive: [
                    {
                      breakpoint: 480,
                      options: {
                        chart: {
                          width: 200,
                        },
                        legend: {
                          position: "bottom",
                        },
                      },
                    },
                  ],
                  colors: ["#fbbf24", "#1999d1", "#12b981", "#ef4444"],
                  fill: {
                    colors: ["#fbbf24", "#1999d1", "#12b981", "#ef4444"],
                  },
                }}
                series={[
                  this.GetAllTotals(
                    this.props.selectedType === ComplaintTypeEnum.SERVICE
                      ? this.state.data.service_count
                      : this.state.data.product_count
                  ).total_pending,
                  this.GetAllTotals(
                    this.props.selectedType === ComplaintTypeEnum.SERVICE
                      ? this.state.data.service_count
                      : this.state.data.product_count
                  ).total_progress,
                  this.GetAllTotals(
                    this.props.selectedType === ComplaintTypeEnum.SERVICE
                      ? this.state.data.service_count
                      : this.state.data.product_count
                  ).total_completed,
                  this.GetAllTotals(
                    this.props.selectedType === ComplaintTypeEnum.SERVICE
                      ? this.state.data.service_count
                      : this.state.data.product_count
                  ).total_rejected,
                ]}
                type="pie"
                height={244}
              />
            </div>
          </div>

          <div className="col-span-12">
            <div className="bg-white rounded-md p-3">
              <div className="flex flex-row items-center gap-3">
                <div>
                  <BsBarChartSteps className="text-3xl text-gray-400" />
                </div>
                <div className="flex flex-row items-center w-full justify-between gap-3">
                  <div className="w-full">
                    <div className="flex flex-row items-center gap-1">
                      <div className="text-lg font-bold">Units queries</div>
                      {this.state.selectedStatus !== null &&
                        ComplaintStatusDiv(this.state.selectedStatus)}
                    </div>
                    <div className="text-sm text-gray-500">
                      List of queries grouped in their units
                    </div>
                  </div>
                  <div className="w-1/2">
                    <Select
                      title={"Choose query status"}
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        this.setState({
                          selectedStatus:
                            e.target.value === ""
                              ? null
                              : (e.target.value as ComplaintStatus),
                        });
                      }}
                      disabled={this.state.loading}
                      value={
                        this.state.selectedStatus === null
                          ? ""
                          : this.state.selectedStatus
                      }
                      error={""}
                      data={[
                        {
                          caption: "All Statuses",
                          value: "",
                        },
                        {
                          caption: "Pending",
                          value: ComplaintStatus.PENDING,
                        },
                        {
                          caption: "In Progress",
                          value: ComplaintStatus.PROGRESS,
                        },
                        {
                          caption: "Completed",
                          value: ComplaintStatus.COMPLETED,
                        },
                        {
                          caption: "Rejected",
                          value: ComplaintStatus.REJECTED,
                        },
                      ]}
                      className="font-bold bg-white border-2 border-primary-700"
                    />
                  </div>
                </div>
              </div>
              <div className="pt-3">
                <div>
                  <ReactApexChart
                    options={{
                      chart: {
                        type: "bar",
                        height: 430,
                      },
                      plotOptions: {
                        bar: {
                          horizontal: true,
                          dataLabels: {
                            position: "top",
                          },
                        },
                      },
                      dataLabels: {
                        enabled: true,
                        offsetX: -6,
                        style: {
                          fontSize: "9px",
                          colors: ["#fff"],
                        },
                      },
                      stroke: {
                        show: true,
                        width: 1,
                        colors: ["#fff"],
                      },
                      tooltip: {
                        shared: true,
                        intersect: false,
                      },
                      xaxis: {
                        categories: this.GetAllUnitsTotals(
                          this.props.selectedType
                        ).map((itm) => itm.unit.unit_name),
                      },
                    }}
                    series={
                      this.state.selectedStatus === null
                        ? [
                            {
                              data: this.GetAllUnitsTotals(
                                this.props.selectedType
                              ).map((itm) => itm.totals.total_pending),
                              name: "Pending",
                              color: "#fbbf24",
                            },
                            {
                              data: this.GetAllUnitsTotals(
                                this.props.selectedType
                              ).map((itm) => itm.totals.total_progress),
                              name: "In Progress",
                              color: "#1999d1",
                            },
                            {
                              data: this.GetAllUnitsTotals(
                                this.props.selectedType
                              ).map((itm) => itm.totals.total_completed),
                              name: "Completed",
                              color: "#12b981",
                            },
                            {
                              data: this.GetAllUnitsTotals(
                                this.props.selectedType
                              ).map((itm) => itm.totals.total_rejected),
                              name: "Rejected",
                              color: "#ef4444",
                            },
                          ]
                        : [
                            {
                              data: this.GetAllUnitsCountsByStatus(
                                this.state.selectedStatus,
                                this.props.selectedType
                              ),
                              name: this.state.selectedStatus,
                              color: this.GetColor(this.state.selectedStatus),
                            },
                          ]
                    }
                    type="bar"
                    height={
                      this.GetAllUnitsTotals(this.props.selectedType).length > 0
                        ? (this.state.selectedStatus !== null ? 120 : 250) *
                          this.GetAllUnitsTotals(this.props.selectedType).length
                        : undefined
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-span-12">
            <div className="bg-white rounded-md p-3">
              <div className="flex flex-row items-center gap-3">
                <div>
                  <BsBarChartSteps className="text-3xl text-gray-400" />
                </div>
                <div className="flex flex-row justify-between gap-3 w-full">
                  <div className="w-full">
                    <div className="text-lg font-bold">Queries by reason</div>
                    <div className="text-sm text-gray-500">
                      Summary of all queries grouped in reasons
                    </div>
                  </div>
                  <div className="w-1/2 relative">
                    {this.state.openSelectReason === false ? (
                      <div className="flex flex-row items-center gap-2">
                        {this.state.selectedReason !== null && (
                          <div
                            onClick={() =>
                              this.setState({ selectedReason: null })
                            }
                            className="bg-yellow-50 rounded-md flex items-center justify-center h-10 w-10 hover:bg-yellow-100 border border-yellow-400 hover:border-yellow-500"
                          >
                            <BiReset className="text-3xl text-yellow-600 hover:text-yellow-700 cursor-pointer" />
                          </div>
                        )}
                        <div
                          onClick={() =>
                            this.setState({
                              openSelectReason: !this.state.openSelectReason,
                            })
                          }
                          className="flex flex-row items-center justify-between gap-3 w-full border border-primary-700 rounded-md px-3 py-2 cursor-pointer hover:bg-primary-100 hover:text-primary-800 group"
                        >
                          {this.state.selectedReason === null ? (
                            <div className="text-gray-500 group-hover:text-primary-800">
                              Show reason in divisions
                            </div>
                          ) : (
                            <div className="text-primary-800 font-bold">
                              {this.state.selectedReason.complaint_reason}
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="absolute top-0 right-0 w-full z-20 animate__animated animate__fadeIn">
                        <div className="font-bold">Choose Reason</div>
                        <SelectCustom
                          loading={this.state.loading}
                          id={"complaint_reason_id"}
                          title={"complaint_reason"}
                          close={() =>
                            this.setState({ openSelectReason: false })
                          }
                          data={this.GetReasonsSummary(
                            this.state.data,
                            this.props.selectedType
                          ).map((item) => item.complaint_reason)}
                          click={(data: ServiceComplaintType) => {
                            this.setState({
                              selectedReason: data,
                              openSelectReason: false,
                            });
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="pt-3">
                <div>
                  <ReactApexChart
                    options={{
                      chart: {
                        type: "bar",
                        height: 430,
                      },
                      plotOptions: {
                        bar: {
                          horizontal: true,
                          dataLabels: {
                            position: "top",
                          },
                        },
                      },
                      dataLabels: {
                        enabled: true,
                        offsetX: -6,
                        style: {
                          fontSize: "9px",
                          colors: ["#fff"],
                        },
                      },
                      stroke: {
                        show: true,
                        width: 1,
                        colors: ["#fff"],
                      },
                      tooltip: {
                        shared: true,
                        intersect: false,
                      },
                      xaxis: {
                        categories:
                          this.state.selectedReason !== null
                            ? this.GetTotalsByUnitAndReason(
                                this.state.data,
                                this.state.selectedReason.complaint_reason_id,
                                this.props.selectedType
                              ).map((itm) => itm.unit.unit_name)
                            : this.GetReasonsSummary(
                                this.state.data,
                                this.props.selectedType
                              ).map(
                                (itm) => itm.complaint_reason.complaint_reason
                              ),
                      },
                    }}
                    series={[
                      {
                        data:
                          this.state.selectedReason !== null
                            ? this.GetTotalsByUnitAndReason(
                                this.state.data,
                                this.state.selectedReason.complaint_reason_id,
                                this.props.selectedType
                              ).map((itm) => itm.total)
                            : this.GetReasonsSummary(
                                this.state.data,
                                this.props.selectedType
                              ).map((itm) => itm.total),
                        name: "Total Queries",
                      },
                    ]}
                    type="bar"
                    height={
                      this.state.selectedReason !== null &&
                      this.GetTotalsByUnitAndReason(
                        this.state.data,
                        this.state.selectedReason.complaint_reason_id,
                        this.props.selectedType
                      ).length > 0
                        ? this.GetTotalsByUnitAndReason(
                            this.state.data,
                            this.state.selectedReason.complaint_reason_id,
                            this.props.selectedType
                          ).length * 120
                        : this.GetReasonsSummary(
                            this.state.data,
                            this.props.selectedType
                          ).length > 0
                        ? 80 *
                          this.GetReasonsSummary(
                            this.state.data,
                            this.props.selectedType
                          ).length
                        : undefined
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  auth,
  units,
}: StoreState): {
  auth: Auth;
  units: UnitStore;
} => {
  return { auth, units };
};

export const AllUnitsDashboard = connect(mapStateToProps, {
  FC_GetTotalComplaintsAllUnits,
  FC_GetAllUnits,
})(_AllUnitsDashboard);
