import { Auth, Action, ActionTypes, UserActiveStatus } from "../actions";
import { APP_TOKEN_NAME } from "../utils/AxiosToken";
// default state
const defaultState: Auth = {
  user: null,
  selectedEmployment: null,
  educationBackground: null,
  loading: true,
  isAuthenticated: false,
  token: "",
};

/**
 * this is the
 * @param state
 * @param action
 * @returns
 */
export const authReducer = (state: Auth = defaultState, action: Action) => {
  switch (action.type) {
    case ActionTypes.USER_LOGIN_SUCCESS_DATA:
      return {
        ...state,
        user: action.payload.data,
        token: action.payload.token,
        loading: false,
        isAuthenticated: true,
        selectedEmployment:
          action.payload.data.employment.length === 1
            ? action.payload.data.employment[0]
            : null,
      };
    case ActionTypes.SWITCH_EMPLOYMENT:
      return {
        ...state,
        selectedEmployment: action.payload,
      };
    case ActionTypes.CLEAN_USER_DETAILS:
      return {
        ...defaultState,
      };
    case ActionTypes.GET_EMPLOYEE_EDUCATION_BACKGROUND:
      return {
        ...state,
        educationBackground: action.payload,
      };
    case ActionTypes.REMOVE_EMPLOYEE_SPECIALIZATION:
      return {
        ...state,
        educationBackground:
          state.educationBackground === null
            ? null
            : {
                specialization: state.educationBackground.specialization.filter(
                  (itm) =>
                    itm.employee_specialization_id.toString() !==
                    action.payload.employee_specialization_id.toString()
                ),
                qualification: state.educationBackground.qualification,
              },
      };
    case ActionTypes.REMOVE_EMPLOYEE_QUALIFICATION:
      return {
        ...state,
        educationBackground:
          state.educationBackground === null
            ? null
            : {
                qualification: state.educationBackground.qualification.filter(
                  (itm) =>
                    itm.employee_qualification_id.toString() !==
                    action.payload.employee_qualification_id.toString()
                ),
                specialization: state.educationBackground.specialization,
              },
      };
    case ActionTypes.UPDATE_PERSONAL_INFO:
      return {
        ...state,
        user: {
          user_id: action.payload.user_id,
          first_name: action.payload.first_name,
          middle_name: action.payload.middle_name,
          last_name: action.payload.last_name,
          phone_number: action.payload.phone_number,
          email: action.payload.email,
          status:
            state.user === null ? UserActiveStatus.ACTIVE : state.user.status, //
          jwt: state.token, //
          employment: state.user === null ? [] : state.user.employment, //
          nid_number: action.payload.nid_number,
          residence: action.payload.residence,
          passport_number: action.payload.passport_number,
          gender: action.payload.gender,
          nationality: action.payload.nationality,
          dob: action.payload.dob,
          martial_status: action.payload.martial_status,
          staff_code: action.payload.staff_code,
          bank_name: action.payload.bank_name,
          account_number: action.payload.account_number,
          rssb_number: action.payload.rssb_number,
        },
      };
    case ActionTypes.LOGOUT:
      localStorage.removeItem(APP_TOKEN_NAME);
      return {
        ...defaultState,
        loading: false,
      };
    default:
      return state;
  }
};
