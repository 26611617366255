const server = "https://hrms.rwandafda.gov.rw";
const supportServer = "https://customersupport.rwandafda.gov.rw";
export const API_URL = `${server}/hrapi`;
// export const API_URL = `${server}/hrtestapi`;
export const SUPPORT_API_URL = `${supportServer}/supportapi`;
export const DISTRICTS_LOCATION = `${server}/locations.json`;

//** Socket server */
export const SOCKET_SERVER = "https://customersupport.rwandafda.gov.rw";
// Files location
export const IMAGES_PATH = {};
export enum DocFolder {
  docs = "docs",
}
// test DM: 0788648244
