import { Action, ActionTypes, TravelStore } from "../actions";
// default state
const defaultState: TravelStore = {
  employee_travels: null,
};

/**
 * this is the action
 * @param state
 * @param action
 * @returns
 */
export const travelReducer = (
  state: TravelStore = defaultState,
  action: Action
) => {
  switch (action.type) {
    case ActionTypes.GET_EMPLOYEE_TRAVELS:
      return {
        ...state,
        employee_travels: action.payload,
      };
    case ActionTypes.UPDATE_EMPLOYEE_TRAVEL_STATUS:
      return {
        ...state,
        employee_travels:
          state.employee_travels === null
            ? null
            : state.employee_travels.map((item) => ({
                ...item,
                travel_status:
                  item.employee_travel_id.toString() ===
                  action.payload.employee_travel_id.toString()
                    ? action.payload.travel_status
                    : item.travel_status,
                travel_status_comment:
                  item.employee_travel_id.toString() ===
                  action.payload.employee_travel_id.toString()
                    ? action.payload.travel_status_comment
                    : item.travel_status_comment,
              })),
      };
    case ActionTypes.REMOVE_EMPLOYEE_TRAVEL:
      return {
        ...state,
        employee_travels:
          state.employee_travels === null
            ? null
            : state.employee_travels.filter(
                (itm) =>
                  itm.employee_travel_id.toString() !==
                  action.payload.employee_travel_id.toString()
              ),
      };
    case ActionTypes.LOGOUT:
      return {
        ...state,
        employee_travels: null,
      };

    default:
      return state;
  }
};
