import React, { Component, Fragment } from "react";
import { MdClose, MdKeyboardArrowDown } from "react-icons/md";
import { NavLink } from "react-router-dom";
import { Auth } from "../../actions";
import FDA_ICON from "../../assets/logo.jpeg";
import {
  AUTHENTICATED_MENUS,
  menus_categories,
} from "../../config/AppNavigations";
import { isAccessAuthorized } from "../../config/userAccess";

interface SideNavBarProps {
  auth: Auth;
  setOpenVav: (status: boolean) => void;
  sideNavbarStatus: boolean;
}
interface SideNavBarState {
  current_path_url: string[];
}

export class SideNavBar extends Component<SideNavBarProps, SideNavBarState> {
  constructor(props: SideNavBarProps) {
    super(props);
    this.state = {
      current_path_url:
        window.location.pathname === undefined
          ? []
          : window.location.pathname.split("/").filter((itm) => itm !== ""),
    };
  }
  setCurrentPath = () => {
    this.setState({
      current_path_url:
        window.location.pathname === undefined
          ? []
          : window.location.pathname.split("/").filter((itm) => itm !== ""),
    });
  };
  render() {
    const menus = AUTHENTICATED_MENUS;
    const baseClass =
      "flex flex-row items-center gap-2 px-5 py-2 text-sm mr-1 pr-2 rounded-r-full justify-between";
    return (
      <Fragment>
        <div className="fixed w-full md:w-64 bg-white top-16 bottom-0 left-0 right-5 transition overflow-y-auto z-40">
          <div className="flex items-center justify-center px-5 mt-4">
            <img
              src={FDA_ICON}
              alt="Rwanda FDA"
              className="hidden md:block w-24"
            />
          </div>
          <div className="flex md:hidden flex-row items-center justify-between gap-2 mx-2">
            <span className="text-lg font-bold">Explore Menus</span>
            <div>
              <div
                onClick={() =>
                  this.props.setOpenVav(!this.props.sideNavbarStatus)
                }
                className="h-10 w-10 rounded-full bg-red-50 hover:bg-red-100 flex items-center justify-center cursor-pointer"
              >
                <MdClose className="text-2xl text-red-700" />
              </div>
            </div>
          </div>
          <div className="flex flex-col mb-3">
            {menus_categories()
              .filter(
                (itm) =>
                  menus.find((test) => test.menu_type === itm.key) !== undefined
              )
              .map((item, k) => (
                <div key={k + 1} className="mt-5">
                  {menus
                    .filter((itm) => itm.menu_type === item.key)
                    .filter(
                      (itm) =>
                        itm.access === "all" ||
                        (this.props.auth.selectedEmployment !== null &&
                          isAccessAuthorized(
                            this.props.auth.selectedEmployment,
                            itm.access
                          ).view === true)
                    ).length > 0 && (
                    <div className="text-gray-500 uppercase text-xs px-5 mb-1">
                      {item.title}
                    </div>
                  )}
                  {menus
                    .filter((itm) => itm.menu_type === item.key)
                    .filter(
                      (itm) =>
                        itm.access === "all" ||
                        (this.props.auth.selectedEmployment !== null &&
                          isAccessAuthorized(
                            this.props.auth.selectedEmployment,
                            itm.access
                          ).view === true)
                    )
                    .map((nav, i) => (
                      <div key={i + 1}>
                        <NavLink
                          to={nav.url}
                          className={(isActive) =>
                            isActive
                              ? `${baseClass} bg-primary-50 text-primary-800`
                              : `${baseClass} hover:bg-gray-100`
                          }
                        >
                          <div className="flex flex-row items-center gap-2">
                            <div className="text-base">{<nav.icon />}</div>
                            <span>{nav.title}</span>
                          </div>
                          {nav.submenus.length > 0 && (
                            <div>
                              <MdKeyboardArrowDown className="text-lg" />
                            </div>
                          )}
                        </NavLink>
                        {/* <div className="pl-5 bg-gray-50">slkdjfdlskjf</div> */}
                      </div>
                    ))}
                </div>
              ))}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default SideNavBar;
