import { Action, ActionTypes, TrainingEvaluationStore } from "../actions";
// default state
const defaultState: TrainingEvaluationStore = {
  evaluation_questions: null,
};

/**
 * this is the action
 * @param state
 * @param action
 * @returns
 */
export const trainingEvaluationReducer = (
  state: TrainingEvaluationStore = defaultState,
  action: Action
) => {
  switch (action.type) {
    case ActionTypes.GET_TRAINING_EVALUATION_QUESTIONS_LIST:
      return {
        ...state,
        evaluation_questions: action.payload,
      };
    case ActionTypes.LOGOUT:
      return {
        ...state,
      };

    default:
      return state;
  }
};
