import {
  SetSelectedActionPlanPeriodAction,
  SetIndicatorActionPlansListAction,
  SetSelectedQuarterAction,
  SetGetActionPlanPeriodsAction,
  SetActionPlanReportSummaryAction,
  SetGetActionPlanGeneralDashboardSummaryAction,
} from "./action.plan.action";
import {
  CleanUserDetails,
  LoginSuccessDetails,
  LogoutUser,
  SwitchEmploymentAction,
  UpdatePersonalInfoAction,
} from "./auth.action";
import {
  GetAllCompaniesAction,
  GetCompanyCategoriesAction,
  GetProductCategoriesAction,
  GetProductTypesAction,
} from "./company-product.action";
import {
  GetLiveMainDashboardDataAction,
  SetComplaintStatusChangedAction,
} from "./complaints.action";
import {
  AddEmployeeBehaviorInfoAction,
  AddEmployeeCustomAccessInfoAction,
  GetAllEmployeesInfoAction,
  GetEmployeeDetailsInfoAction,
  GetEmployeeEducationBackgroundInfoAction,
  RemoveEmployeeBehaviorInfoAction,
  RemoveEmployeeCustomAccessInfoAction,
  RemoveEmployeeQualificationInfoAction,
  RemoveEmployeeSpecializationInfoAction,
} from "./employee.action";
import {
  GetEmployeeLeavesInfoAction,
  GetLeaveCategoriesInfoAction,
  GetLeavesListByUnitStatusInfoAction,
  RemoveEmployeeLeaveInfoAction,
} from "./leave.action";
import {
  GetAllPositionsInfoAction,
  SetPositionCompetenciesAction,
  UpdatePositionAccessInfoAction,
} from "./position.action";
import {
  AddSocketComplaintDataAction,
  GetSocketComplaintDataAction,
} from "./socket.action";
import {
  GetAcademicBasicsInfoAction,
  GetAccessListDetailsInfoAction,
  GetBehaviorsListDetailsInfoAction,
  GetCommunicationChannelsAction,
  GetFrequentlyAskedQuestionsInfoAction,
  GetSystemInfoAction,
  GetTrainingOfferModesInfoAction,
  SetSystemErrorMessageAction,
  SetSystemSuccessMessageAction,
} from "./system.action";
import {
  GetTrainingEvaluationDetailsInfoAction,
  GetTrainingEvaluationQuestionsInfoAction,
} from "./training-evaluation.action";
import {
  AddTrainingAttendedReportInfoAction,
  AddTrainingPlanParticipantInfoAction,
  CreateTrainingPlanInfoAction,
  GetTrainingAttendedInfoAction,
  GetTrainingPlansByYearInfoAction,
  GetTrainingProvidersInfoAction,
  GetUserTrainingsInfoAction,
  RemoveTrainingAttendedReportInfoAction,
  UpdatedTrainingStatusInfoAction,
  UpdateTrainingPlanInfoAction,
} from "./training.action";
import {
  GetEmployeeTravelsInfoAction,
  RemoveEmployeeTravelInfoAction,
  UpdateEmployeeTravelReportingInfoAction,
  UpdateEmployeeTravelStatusInfoAction,
} from "./travel.action";
import { GetAllUnitsInfoAction } from "./units.action";

export enum ActionTypes {
  LOGIN_DETAILS = "LOGIN_DETAILS",
  USER_LOGIN_SUCCESS_DATA = "USER_LOGIN_SUCCESS_DATA",
  CLEAN_USER_DETAILS = "CLEAN_USER_DETAILS",
  LOGOUT = "LOGOUT",
  GET_SYSTEM_BASIC_INFO = "GET_SYSTEM_BASIC_INFO",
  SET_SYSTEM_ERROR_MESSAGE = "SET_SYSTEM_ERROR_MESSAGE",
  SET_SYSTEM_SUCCESS_MESSAGE = "SET_SYSTEM_SUCCESS_MESSAGE",
  SWITCH_EMPLOYMENT = "SWITCH_EMPLOYMENT",
  GET_ALL_POSITIONS = "GET_ALL_POSITIONS",
  GET_ALL_UNITS = "GET_ALL_UNITS",
  GET_ALL_ACCESS_DETAILS = "GET_ALL_ACCESS_DETAILS",
  UPDATE_POSITION_ACCESS = "UPDATE_POSITION_ACCESS",
  SET_POSITION_COMPETENCIES = "SET_POSITION_COMPETENCIES",
  GET_ALL_EMPLOYEES = "GET_ALL_EMPLOYEES",
  GET_EMPLOYEE_DETAILS = "GET_EMPLOYEE_DETAILS",
  REMOVE_EMPLOYEE_BEHAVIOR = "REMOVE_EMPLOYEE_BEHAVIOR",
  ADD_EMPLOYEE_BEHAVIOR = "ADD_EMPLOYEE_BEHAVIOR",
  GET_ALL_BEHAVIORS_DETAILS = "GET_ALL_BEHAVIORS_DETAILS",
  ADD_EMPLOYEE_CUSTOM_ACCESS = "ADD_EMPLOYEE_CUSTOM_ACCESS",
  REMOVE_EMPLOYEE_CUSTOM_ACCESS = "REMOVE_EMPLOYEE_CUSTOM_ACCESS",
  GET_TRAINING_PLANS_BY_YEAR = "GET_TRAINING_PLANS_BY_YEAR",
  CREATE_TRAINING_PLAN = "CREATE_TRAINING_PLAN",
  GET_TRAINING_OFFER_MODES = "GET_TRAINING_OFFER_MODES",
  ADD_TRAINING_PLAN_PARTICIPANT = "ADD_TRAINING_PLAN_PARTICIPANT",
  UPDATE_TRAINING_PLAN = "UPDATE_TRAINING_PLAN",
  GET_TRAINING_PROVIDERS = "GET_TRAINING_PROVIDERS",
  GET_TRAINING_ATTENDANCE = "GET_TRAINING_ATTENDANCE",
  ADD_TRAINING_ATTENDED_REPORT = "ADD_TRAINING_ATTENDED_REPORT",
  REMOVE_TRAINING_ATTENDED_REPORT = "REMOVE_TRAINING_ATTENDED_REPORT",
  GET_LEAVE_CATEGORIES = "GET_LEAVE_CATEGORIES",
  GET_EMPLOYEE_LEAVES = "GET_EMPLOYEE_LEAVES",
  REMOVE_EMPLOYEE_LEAVE = "REMOVE_EMPLOYEE_LEAVE",
  GET_LEAVES_BY_UNIT_STATUS = "GET_LEAVES_BY_UNIT_STATUS",
  GET_TRAVELS_BY_UNIT_STATUS = "GET_TRAVELS_BY_UNIT_STATUS",
  GET_EMPLOYEE_TRAVELS = "GET_EMPLOYEE_TRAVELS",
  REMOVE_EMPLOYEE_TRAVEL = "REMOVE_EMPLOYEE_TRAVEL",
  UPDATE_EMPLOYEE_TRAVEL_STATUS = "UPDATE_EMPLOYEE_TRAVEL_STATUS",
  UPDATE_EMPLOYEE_TRAVEL_REPORTING = "UPDATE_EMPLOYEE_TRAVEL_REPORTING",
  GET_TRAINING_EVALUATION_DETAILS = "GET_TRAINING_EVALUATION_DETAILS",
  GET_TRAINING_EVALUATION_QUESTIONS_LIST = "GET_TRAINING_EVALUATION_QUESTIONS_LIST",
  GET_USER_TRAININGS = "GET_USER_TRAININGS",
  UPDATE_TRAINING_STATUS = "UPDATE_TRAINING_STATUS",
  GET_EMPLOYEE_EDUCATION_BACKGROUND = "GET_EMPLOYEE_EDUCATION_BACKGROUND",
  REMOVE_EMPLOYEE_QUALIFICATION = "REMOVE_EMPLOYEE_QUALIFICATION",
  REMOVE_EMPLOYEE_SPECIALIZATION = "REMOVE_EMPLOYEE_SPECIALIZATION",
  GET_ACADEMIC_BASICS = "GET_ACADEMIC_BASICS",
  UPDATE_PERSONAL_INFO = "UPDATE_PERSONAL_INFO",
  // Customer Support
  GET_FAQ_ACTION = "GET_FAQ_ACTION",
  GET_COMMUNICATION_CHANNELS = "GET_COMMUNICATION_CHANNELS",
  GET_PRODUCT_CATEGORY = "GET_PRODUCT_CATEGORY",
  GET_PRODUCT_TYPES = "GET_PRODUCT_TYPES",
  GET_COMPANY_CATEGORIES = "GET_COMPANY_CATEGORIES",
  GET_ALL_COMPANIES = "GET_ALL_COMPANIES",
  GET_LIVE_MAIN_DASHBOARD = "GET_LIVE_MAIN_DASHBOARD",
  ADD_SOCKET_COMPLAINT_DATA = "ADD_SOCKET_COMPLAINT_DATA",
  GET_SOCKET_COMPLAINT_DATA = "GET_SOCKET_COMPLAINT_DATA",
  COMPLAINT_STATUS_CHANGED = "COMPLAINT_STATUS_CHANGED",
  // Action plan reporting
  SET_SELECTED_QUARTER = "SET_SELECTED_QUARTER",
  SET_INDICATORS_ACTION_PLANS_LIST = "SET_INDICATORS_ACTION_PLANS_LIST",
  SET_SELECTED_PERIOD = "SET_SELECTED_PERIOD",
  SET_GET_ACTION_PLAN_PERIODS = "SET_GET_ACTION_PLAN_PERIODS",
  SET_UNIT_SUBMITTED_ACTION_PLAN_REPORTS_SUMMARY = "SET_UNIT_SUBMITTED_ACTION_PLAN_REPORTS_SUMMARY",
  SET_ACTION_PLAN_GENERAL_DASHBOARD_SUMMARY = "SET_ACTION_PLAN_GENERAL_DASHBOARD_SUMMARY",
}

export type Action =
  | CleanUserDetails
  | LoginSuccessDetails
  | LogoutUser
  | GetSystemInfoAction
  | SetSystemErrorMessageAction
  | SetSystemSuccessMessageAction
  | SwitchEmploymentAction
  | GetAllPositionsInfoAction
  | GetAllUnitsInfoAction
  | GetAccessListDetailsInfoAction
  | UpdatePositionAccessInfoAction
  | SetPositionCompetenciesAction
  | GetAllEmployeesInfoAction
  | GetEmployeeDetailsInfoAction
  | RemoveEmployeeBehaviorInfoAction
  | AddEmployeeBehaviorInfoAction
  | GetBehaviorsListDetailsInfoAction
  | AddEmployeeCustomAccessInfoAction
  | RemoveEmployeeCustomAccessInfoAction
  | GetTrainingPlansByYearInfoAction
  | CreateTrainingPlanInfoAction
  | GetTrainingOfferModesInfoAction
  | AddTrainingPlanParticipantInfoAction
  | UpdateTrainingPlanInfoAction
  | GetTrainingProvidersInfoAction
  | GetTrainingAttendedInfoAction
  | AddTrainingAttendedReportInfoAction
  | RemoveTrainingAttendedReportInfoAction
  | GetLeaveCategoriesInfoAction
  | GetEmployeeLeavesInfoAction
  | RemoveEmployeeLeaveInfoAction
  | GetLeavesListByUnitStatusInfoAction
  | GetEmployeeTravelsInfoAction
  | RemoveEmployeeTravelInfoAction
  | UpdateEmployeeTravelStatusInfoAction
  | UpdateEmployeeTravelReportingInfoAction
  | GetTrainingEvaluationDetailsInfoAction
  | GetTrainingEvaluationQuestionsInfoAction
  | GetUserTrainingsInfoAction
  | UpdatedTrainingStatusInfoAction
  | GetEmployeeEducationBackgroundInfoAction
  | RemoveEmployeeQualificationInfoAction
  | RemoveEmployeeSpecializationInfoAction
  | GetAcademicBasicsInfoAction
  | UpdatePersonalInfoAction
  //*** Customer Support
  | GetFrequentlyAskedQuestionsInfoAction
  | GetCommunicationChannelsAction
  | GetProductCategoriesAction
  | GetProductTypesAction
  | GetCompanyCategoriesAction
  | GetAllCompaniesAction
  | GetLiveMainDashboardDataAction
  | AddSocketComplaintDataAction
  | GetSocketComplaintDataAction
  | SetComplaintStatusChangedAction
  //*** Action plan reporting */
  | SetSelectedQuarterAction
  | SetIndicatorActionPlansListAction
  | SetSelectedActionPlanPeriodAction
  | SetGetActionPlanPeriodsAction
  | SetActionPlanReportSummaryAction
  | SetGetActionPlanGeneralDashboardSummaryAction;
