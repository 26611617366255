import { IconType } from "react-icons";
import {
  MdAdd,
  MdOutlineDashboard,
  MdOutlineInsertChart,
  MdOutlinePersonPinCircle,
  MdTravelExplore,
} from "react-icons/md";
import { HiOutlineBriefcase, HiOutlineUser } from "react-icons/hi";
import { RiLockPasswordLine } from "react-icons/ri";
import { UserAccessList } from "./userAccess";
import {
  FaAward,
  FaChalkboardTeacher,
  FaChartBar,
  FaUsersCog,
} from "react-icons/fa";
import { IoMdAdd, IoMdHelpCircle, IoMdWalk } from "react-icons/io";
import {
  BsBarChartLine,
  BsCalendar2Check,
  BsJournalCheck,
  BsPersonLinesFill,
} from "react-icons/bs";
import { FiMonitor } from "react-icons/fi";
import { IoBriefcaseOutline } from "react-icons/io5";
import { AiFillWechat } from "react-icons/ai";
import { TbReportAnalytics } from "react-icons/tb";

export enum MENU_TYPE {
  NONE = "NONE",
  ACTION_PLAN_REPORTING = "ACTION_PLAN_REPORTING",
  SUPPORT = "SUPPORT",
  PROFILE = "PROFILE",
  ACTIVITIES = "ACTIVITIES",
  REPORTS = "REPORTS",
  SETTINGS = "SETTINGS",
}

export interface NavigationInterface {
  title: string;
  url: string;
}

export interface SubMenuInterface {
  title: string;
  url: string;
  access: UserAccessList | "all";
}

export interface SideNavigationInterface {
  title: string;
  url: string;
  icon: IconType;
  label: string;
  menu_type: MENU_TYPE;
  access: UserAccessList | "all";
  submenus: SubMenuInterface[];
}

/**
 * @description Appear allways
 * @done_by Emmy
 */
export const PUBLIC: NavigationInterface[] = [
  {
    title: "Login",
    url: "/login",
  },
];

/**
 * @description Appear once the user is not logged in
 * @done_by Emmy
 */
export const NON_AUTHENTICATED_MENUS: NavigationInterface[] = [
  {
    title: "About",
    url: "/about",
  },
  {
    title: "Login",
    url: "/login",
  },
];

/**
 * @description Appear once the user is logged in
 * @done_by Emmy
 */
export const AUTHENTICATED_MENUS: SideNavigationInterface[] = [
  {
    icon: MdOutlineDashboard,
    title: "Homepage",
    label: "Homepage",
    url: "/dashboard",
    menu_type: MENU_TYPE.NONE,
    access: "all",
    submenus: [],
  },
  {
    icon: FaChartBar,
    title: "Dashboards",
    label: "Dashboards",
    url: "/admin-dashboard",
    menu_type: MENU_TYPE.NONE,
    access: UserAccessList.DASHBOARDS_MENU,
    submenus: [],
  },
  {
    icon: HiOutlineUser,
    title: "My Profile",
    label: "My Profile",
    url: "/profile",
    menu_type: MENU_TYPE.PROFILE,
    access: "all",
    submenus: [],
  },
  {
    icon: FaAward,
    title: "Academic Background",
    label: "Academic Background",
    url: "/job-background",
    menu_type: MENU_TYPE.PROFILE,
    access: "all",
    submenus: [],
  },
  {
    icon: RiLockPasswordLine,
    title: "Change Password",
    label: "Change Password",
    url: "/change-password",
    menu_type: MENU_TYPE.PROFILE,
    access: "all",
    submenus: [],
  },
  {
    icon: HiOutlineBriefcase,
    title: "Positions Management",
    label: "Positions Management",
    url: "/positions-management",
    menu_type: MENU_TYPE.ACTIVITIES,
    access: UserAccessList.POSITIONS,
    submenus: [],
  },
  {
    icon: FaUsersCog,
    title: "Staff Management",
    label: "Staff Management",
    url: "/employees-management",
    menu_type: MENU_TYPE.ACTIVITIES,
    access: UserAccessList.EMPLOYEES_LIST,
    submenus: [],
  },
  {
    icon: FaUsersCog,
    title: "Unit staff",
    label: "Unit staff",
    url: "/unit-staffs",
    menu_type: MENU_TYPE.ACTIVITIES,
    access: UserAccessList.EMPLOYEES_BY_UNIT,
    submenus: [],
  },
  {
    icon: BsCalendar2Check,
    title: "Training Plans",
    label: "Training Plans",
    url: "/training-plans",
    menu_type: MENU_TYPE.ACTIVITIES,
    access: UserAccessList.EMPLOYEES_TRAINING_PLANS,
    submenus: [],
  },
  {
    icon: BsJournalCheck,
    title: "Training Evaluation",
    label: "Training Evaluation",
    url: "/training-evaluation",
    menu_type: MENU_TYPE.ACTIVITIES,
    access: UserAccessList.ALL_TRAININGS,
    submenus: [],
  },
  {
    icon: MdOutlinePersonPinCircle,
    title: "Staff Leaves",
    label: "Staff Leaves",
    url: "/employee-leaves-by-line-manager",
    menu_type: MENU_TYPE.ACTIVITIES,
    access: UserAccessList.EMPLOYEE_LEAVE,
    submenus: [],
  },
  {
    icon: MdOutlinePersonPinCircle,
    title: "All Leaves",
    label: "All Leaves",
    url: "/all-employee-leaves",
    menu_type: MENU_TYPE.ACTIVITIES,
    access: UserAccessList.ALL_EMPLOYEE_LEAVE,
    submenus: [],
  },
  {
    icon: BsPersonLinesFill,
    title: "Staff Travels",
    label: "Staff Travels",
    url: "/employee-travels-by-line-manager",
    menu_type: MENU_TYPE.ACTIVITIES,
    access: UserAccessList.EMPLOYEE_TRAVEL,
    submenus: [],
  },
  {
    icon: BsPersonLinesFill,
    title: "All Travels",
    label: "All Travels",
    url: "/all-employee-travels",
    menu_type: MENU_TYPE.ACTIVITIES,
    access: UserAccessList.ALL_EMPLOYEE_TRAVEL,
    submenus: [],
  },
  {
    icon: FaChalkboardTeacher,
    title: "My Training Reports",
    label: "My Training Reports",
    url: "/employee-trainings",
    menu_type: MENU_TYPE.PROFILE,
    access: UserAccessList.EMPLOYEE_TRAINING,
    submenus: [],
  },
  {
    icon: IoMdAdd,
    title: "Create Trainings",
    label: "Create Trainings",
    url: "/create-training",
    menu_type: MENU_TYPE.ACTIVITIES,
    access: UserAccessList.CREATE_TRAINING_MENU,
    submenus: [],
  },
  {
    icon: FaChalkboardTeacher,
    title: "Unit Trainings",
    label: "Unit Trainings",
    url: "/trainings-by-unit",
    menu_type: MENU_TYPE.ACTIVITIES,
    access: UserAccessList.TRAININGS_BY_UNIT,
    submenus: [],
  },
  {
    icon: FaChalkboardTeacher,
    title: "Trainings by plans",
    label: "Trainings by plans",
    url: "/trainings-by-plan",
    menu_type: MENU_TYPE.ACTIVITIES,
    access: UserAccessList.TRAININGS_BY_PLAN,
    submenus: [],
  },
  {
    icon: FaChalkboardTeacher,
    title: "All Trainings",
    label: "All Trainings",
    url: "/trainings-by-year",
    menu_type: MENU_TYPE.ACTIVITIES,
    access: UserAccessList.ALL_TRAININGS,
    submenus: [],
  },
  {
    icon: IoMdWalk,
    title: "My Leaves",
    label: "My Leaves",
    url: "/employee-leave",
    menu_type: MENU_TYPE.PROFILE,
    access: UserAccessList.LEAVES,
    submenus: [],
  },
  {
    icon: MdTravelExplore,
    title: "My Travel Clearance",
    label: "My Travel Clearance",
    url: "/employee-travel",
    menu_type: MENU_TYPE.PROFILE,
    access: UserAccessList.TRAVELS,
    submenus: [],
  },
  {
    icon: MdAdd,
    title: "Add Query",
    label: "Add Query",
    url: "/add-complaint",
    menu_type: MENU_TYPE.SUPPORT,
    access: UserAccessList.ADD_COMPLIANT,
    submenus: [],
  },
  {
    icon: IoMdHelpCircle,
    title: "Customer Queries",
    label: "Customer Queries",
    url: "/complaints-list",
    menu_type: MENU_TYPE.SUPPORT,
    access: UserAccessList.VIEW_COMPLAINTS_LIST,
    submenus: [],
  },
  {
    icon: IoMdHelpCircle,
    title: "Registered Queries",
    label: "Registered Queries",
    url: "/registered-complaints",
    menu_type: MENU_TYPE.SUPPORT,
    access: UserAccessList.REGISTERED_COMPLAINTS,
    submenus: [],
  },
  {
    icon: FiMonitor,
    title: "Support staff monitoring",
    label: "Support staff monitoring",
    url: "/customer-support-staff-monitoring",
    menu_type: MENU_TYPE.SUPPORT,
    access: UserAccessList.CUSTOMER_SUPPORT_STAFF_MONITORING,
    submenus: [],
  },
  {
    icon: IoBriefcaseOutline,
    title: "Company Customers",
    label: "Company Customers",
    url: "/company-customers",
    menu_type: MENU_TYPE.SUPPORT,
    access: UserAccessList.COMPANY_PRODUCT_PAGE,
    submenus: [],
  },
  {
    icon: AiFillWechat,
    title: "Live Chat",
    label: "Live Chat",
    url: "/live-chat",
    menu_type: MENU_TYPE.SUPPORT,
    access: UserAccessList.LIVE_CHAT,
    submenus: [],
  },
  {
    icon: BsBarChartLine,
    title: "Queries Monitor",
    label: "Queries Monitor",
    url: "/queries-monitor",
    menu_type: MENU_TYPE.SUPPORT,
    access: UserAccessList.MAIN_CUSTOMER_SUPPORT_MONITOR,
    submenus: [],
  },
  {
    icon: TbReportAnalytics,
    title: "My Reports",
    label: "My Reports",
    url: "/my-reports",
    menu_type: MENU_TYPE.ACTION_PLAN_REPORTING,
    access: UserAccessList.ACTION_PLAN_REPORTING,
    // access: "all",
    submenus: [],
  },
  {
    icon: MdOutlineInsertChart,
    title: "Reporting Dashboard",
    label: "Reporting Dashboard",
    url: "/reporting-dashboard",
    menu_type: MENU_TYPE.ACTION_PLAN_REPORTING,
    access: UserAccessList.ACTION_PLAN_REPORTING_DASHBOARD,
    // access: "all",
    submenus: [],
  },
  {
    icon: MdOutlineInsertChart,
    title: "Activity Plans Management",
    label: "Activity Plans Management",
    url: "/activity-plan-management",
    menu_type: MENU_TYPE.ACTION_PLAN_REPORTING,
    access: UserAccessList.ACTIVITY_PLANS_MANAGEMENT,
    // access: "all",
    submenus: [],
  },
];

export const menus_categories = (): { key: MENU_TYPE; title: string }[] => {
  const response: { key: MENU_TYPE; title: string }[] = [];
  for (const menu in MENU_TYPE) {
    response.push({
      key: menu as MENU_TYPE,
      title:
        menu === MENU_TYPE.PROFILE
          ? "Profile"
          : menu === MENU_TYPE.ACTIVITIES
          ? "Activities"
          : menu === MENU_TYPE.SUPPORT
          ? "Customer Support"
          : menu === MENU_TYPE.REPORTS
          ? "Reports"
          : menu === MENU_TYPE.SETTINGS
          ? "Settings"
          : menu === MENU_TYPE.ACTION_PLAN_REPORTING
          ? "Activity plan reporting"
          : "",
    });
  }
  return response.filter((element) =>
    response.find((itm) => itm.key === element.key)
  );
};
