import { combineReducers } from "redux";
import {
  ActionPlanStore,
  Auth,
  CompanyProductStore,
  ComplaintStoreInterface,
  LeaveStore,
  System,
  TrainingEvaluationStore,
  TrainingStore,
  TravelStore,
} from "../actions";
import { EmployeeStore } from "../actions/employee.action";
import { PositionStore } from "../actions/position.action";
import { UnitStore } from "../actions/units.action";
import { authReducer } from "./auth.reducer";
import { employeeReducer } from "./employee.reducer";
import { leaveReducer } from "./leave.reducer";
import { positionReducer } from "./position.reducer";
import { systemReducer } from "./system.reducer";
import { trainingsReducer } from "./training.reducer";
import { travelReducer } from "./travel.reducer";
import { trainingEvaluationReducer } from "./trining-evaluation.reducer";
import { unitsReducer } from "./units.reducer";
import { companyProductReducer } from "./company-product.reducer";
import { complaintsReducer } from "./complaints.reducer";
import { actionPlanReducer } from "./action.plan.reducer";

// define the entire state into the entire side
export interface StoreState {
  auth: Auth;
  system: System;
  position: PositionStore;
  units: UnitStore;
  employee: EmployeeStore;
  training: TrainingStore;
  leave: LeaveStore;
  travel: TravelStore;
  trainingEvaluation: TrainingEvaluationStore;
  companyProduct: CompanyProductStore;
  complaintStore: ComplaintStoreInterface;
  actionPlan: ActionPlanStore;
}

export const reducers = combineReducers<StoreState>({
  auth: authReducer,
  system: systemReducer,
  position: positionReducer,
  units: unitsReducer,
  employee: employeeReducer,
  training: trainingsReducer,
  leave: leaveReducer,
  travel: travelReducer,
  trainingEvaluation: trainingEvaluationReducer,
  companyProduct: companyProductReducer,
  complaintStore: complaintsReducer,
  actionPlan: actionPlanReducer,
});
