import {
  Action,
  ActionTypes,
  ComplaintStoreInterface,
  MainCustomerSupportMonitorDataInterface,
} from "../actions";
import { ComplaintStatus } from "../containers/ComplaintsList/ComplaintsList";
// default state
const defaultState: ComplaintStoreInterface = {
  complaintLiveDashboard: null,
  openStatusChangedPopup: null,
};

/**
 * this is the action
 * @param state
 * @param action
 * @returns
 */
export const complaintsReducer = (
  state: ComplaintStoreInterface = defaultState,
  action: Action
) => {
  switch (action.type) {
    case ActionTypes.GET_LIVE_MAIN_DASHBOARD:
      return {
        ...state,
        complaintLiveDashboard: action.payload,
      };
    case ActionTypes.LOGOUT:
      return {
        ...state,
        defaultState,
      };
    case ActionTypes.COMPLAINT_STATUS_CHANGED:
      return {
        ...state,
        openStatusChangedPopup: action.payload,
      };
    case ActionTypes.GET_SOCKET_COMPLAINT_DATA:
      var responseData: MainCustomerSupportMonitorDataInterface[] = [];
      const newObj: MainCustomerSupportMonitorDataInterface = {
        unit_id: action.payload.unit_id,
        short_reason: action.payload.reason_id,
        complaint_reason_en: action.payload.complaint_reason_en,
        total_pending:
          action.payload.complaint_status === ComplaintStatus.PENDING ? 1 : 0,
        total_progress:
          action.payload.complaint_status === ComplaintStatus.PROGRESS ? 1 : 0,
        total_completed:
          action.payload.complaint_status === ComplaintStatus.COMPLETED ? 1 : 0,
        total_rejected:
          action.payload.complaint_status === ComplaintStatus.REJECTED ? 1 : 0,
      };

      if (state.complaintLiveDashboard === null) {
        responseData = [newObj];
      } else {
        // Check if there is a row
        const testDataAvailability = state.complaintLiveDashboard.find(
          (itm) =>
            itm.unit_id.toString() === action.payload.unit_id.toString() &&
            itm.short_reason.toString() === action.payload.reason_id.toString()
        );
        if (testDataAvailability !== undefined) {
          const newFormedObject: MainCustomerSupportMonitorDataInterface = {
            complaint_reason_en: testDataAvailability.complaint_reason_en,
            short_reason: testDataAvailability.short_reason,
            unit_id: testDataAvailability.unit_id,
            total_completed:
              action.payload.complaint_status === ComplaintStatus.COMPLETED
                ? testDataAvailability.total_completed + 1
                : testDataAvailability.total_completed,
            total_pending:
              action.payload.complaint_status === ComplaintStatus.PENDING
                ? testDataAvailability.total_pending + 1
                : testDataAvailability.total_pending,
            total_progress:
              action.payload.complaint_status === ComplaintStatus.PROGRESS
                ? testDataAvailability.total_progress + 1
                : testDataAvailability.total_progress,
            total_rejected:
              action.payload.complaint_status === ComplaintStatus.REJECTED
                ? testDataAvailability.total_rejected + 1
                : testDataAvailability.total_rejected,
          };
          responseData = [
            ...state.complaintLiveDashboard.filter(
              (itm) =>
                itm.unit_id.toString() !== action.payload.unit_id.toString() ||
                itm.short_reason.toString() !==
                  action.payload.reason_id.toString()
            ),
            newFormedObject,
          ];
        } else {
          responseData = [...state.complaintLiveDashboard, newObj];
        }
      }
      return {
        ...state,
        complaintLiveDashboard: responseData,
      };
    default:
      return state;
  }
};
