import axios from "axios";
import { Dispatch } from "redux";
import { UserAccessInterface } from "../config/userAccess";
import { API_URL } from "../utils/api";
import { setAxiosToken } from "../utils/AxiosToken";
import { errorToText } from "../utils/functions";
import {
  BooleanEnum,
  GenderEnum,
  MartialStatusEnum,
  UpdatePersonalInfoAction,
  UserActiveStatus,
} from "./auth.action";
import { PositionDetailsInterface } from "./position.action";
import {
  SetSystemErrorMessageAction,
  SetSystemSuccessMessageAction,
} from "./system.action";
import { ActionTypes } from "./types";

export interface EmployeePositionItem {
  employment_id: string;
  end_date: string | null;
  is_acting: BooleanEnum;
  is_active: BooleanEnum;
  is_line_manager: BooleanEnum;
  position_id: string;
  position_name: string;
  start_date: string;
  unit_id: string;
  unit_name: string;
  user_id: string;
}

export interface EmployeeListInterface {
  user_id: string;
  first_name: string;
  middle_name: string;
  nid_number: string | null;
  last_name: string;
  phone_number: string;
  email: string;
  residence: string | null;
  passport_number: string | null;
  gender: string | null;
  nationality: string | null;
  education_field_id: string | null;
  education_level_id: string | null;
  dob: string | null;
  martial_status: string | null;
  status: UserActiveStatus;
  positions: EmployeePositionItem[];
  account_number: string;
  bank_name: string;
  rssb_number: string;
  staff_code: string;
}

export interface EmployeeCustomAccess {
  custom_access_id: string;
  user_id: string;
  access: UserAccessInterface[];
  start_date: string;
  end_date: string;
  reason: string;
}

export interface EmployeeBehavior {
  user_behavior_id: string;
  user_id: string;
  behavior_id: string;
  behavior_name: string;
  proficiency_level_id: string;
  proficiency_level: string;
  assignment_comment: string;
}

export interface AddEmployeeBehaviorDataInterface {
  user_id: string;
  behavior_id: string;
  proficiency_level_id: string;
  assignment_comment: string;
}

export interface EmployeeDetailsInterface {
  positions: PositionDetailsInterface[];
  employee_custom_access: EmployeeCustomAccess[];
  employee_behavior: EmployeeBehavior[];
}

export interface EmployeeCustomAccessDataInterface {
  user_id: string;
  access: UserAccessInterface[];
  start_date: string;
  end_date: string;
  reason: string;
}

export enum QualificationCategoryEnum {
  ACADEMIC = "ACADEMIC",
  JOB = "JOB",
}

export interface EmployeeQualificationInterface {
  employee_qualification_id: string;
  user_id: string;
  qualification_id: string;
  issued_from: string;
  issued_date: string;
  qualification_name: string;
  category: QualificationCategoryEnum;
  qualification_field: string;
}

export interface EmployeeSpecializationInterface {
  employee_specialization_id: string;
  specialization_id: string;
  user_id: string;
  specialization_name: string;
}

export interface EmployeeEducationBackground {
  qualification: EmployeeQualificationInterface[];
  specialization: EmployeeSpecializationInterface[];
}

export interface EmployeeQualificationData {
  qualification_id: string;
  user_id: string;
  issued_from: string;
  issued_date: string;
  qualification_field: string;
}

export interface EmployeeSpecializationData {
  specialization_id: string;
  user_id: string;
}

export interface EmployeesDashboardSummaryInterface {
  gender_number: {
    unit_id: string;
    unit_name: string;
    gender: "FEMALE" | "MALE";
    total_employee: number;
  }[];
  nationality_number: {
    unit_id: string;
    unit_name: string;
    nationality: string;
    total_employee: number;
  }[];
  qualification_number: {
    unit_id: string;
    unit_name: string;
    qualification_id: string;
    qualification_name: string;
    category: QualificationCategoryEnum;
    total_employee: number;
  }[];
  specialization_number: {
    unit_id: string;
    unit_name: string;
    specialization_id: string;
    specialization_name: string;
    total_employee: number;
  }[];
}

export interface UserDashboardDetailsInterface {
  training_number_has: {
    total_reported_training: number;
    total_not_reported_training: number;
  };
  leave_number_has: {
    total_pending: number;
    total_approved: number;
    total_reported: number;
    total_not_reported: number;
  };
  travel_number_has: {
    total_pending: number;
    total_approved: number;
    total_reported: number;
    total_not_reported: number;
  };
}

export interface UpdatePersonalInfoData {
  user_id: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  phone_number: string;
  email: string;
  nid_number: string;
  passport_number: string | null;
  gender: GenderEnum;
  nationality: string;
  dob: string;
  martial_status: MartialStatusEnum;
  bank_name: string;
  account_number: string;
  rssb_number: string;
  staff_code: string;
  residence: string;
}

export interface EmployeeStore {
  employees: EmployeeListInterface[] | null;
}

//* ********************** ACTION TYPE INTERFACES ********************** */

export interface GetAllEmployeesInfoAction {
  type: ActionTypes.GET_ALL_EMPLOYEES;
  payload: EmployeeListInterface[];
}

export interface GetEmployeeEducationBackgroundInfoAction {
  type: ActionTypes.GET_EMPLOYEE_EDUCATION_BACKGROUND;
  payload: EmployeeEducationBackground;
}

export interface RemoveEmployeeQualificationInfoAction {
  type: ActionTypes.REMOVE_EMPLOYEE_QUALIFICATION;
  payload: {
    employee_qualification_id: string;
  };
}

export interface RemoveEmployeeSpecializationInfoAction {
  type: ActionTypes.REMOVE_EMPLOYEE_SPECIALIZATION;
  payload: {
    employee_specialization_id: string;
  };
}

export interface RemoveEmployeeBehaviorInfoAction {
  type: ActionTypes.REMOVE_EMPLOYEE_BEHAVIOR;
  payload: {
    behavior_id: string;
  };
}

export interface RemoveEmployeeCustomAccessInfoAction {
  type: ActionTypes.REMOVE_EMPLOYEE_CUSTOM_ACCESS;
  payload: {
    custom_access_id: string;
  };
}

export interface AddEmployeeBehaviorInfoAction {
  type: ActionTypes.ADD_EMPLOYEE_BEHAVIOR;
  payload: AddEmployeeBehaviorDataInterface;
}

export interface AddEmployeeCustomAccessInfoAction {
  type: ActionTypes.ADD_EMPLOYEE_CUSTOM_ACCESS;
  payload: EmployeeCustomAccessDataInterface;
}

export interface GetEmployeeDetailsInfoAction {
  type: ActionTypes.GET_EMPLOYEE_DETAILS;
  payload: EmployeeDetailsInterface;
}

export const FC_GetAllEmployees = (
  callback: (loading: boolean, error: string) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, "");
    setAxiosToken();
    try {
      const res = await axios.get<EmployeeListInterface[]>(`${API_URL}/users`);
      console.log({ AllEmployees: res.data });
      dispatch<GetAllEmployeesInfoAction>({
        type: ActionTypes.GET_ALL_EMPLOYEES,
        payload: res.data,
      });
      callback(false, "");
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, errorToText(error));
      console.log("err: ", { ...error });
    }
  };
};

export const FC_GetAllEmployeesByUnit = (
  unit_id: string,
  callback: (loading: boolean, error: string) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, "");
    setAxiosToken();
    try {
      const res = await axios.get<EmployeeListInterface[]>(
        `${API_URL}/users/unit/${unit_id}`
      );
      console.log({ AllEmployees: res.data });
      dispatch<GetAllEmployeesInfoAction>({
        type: ActionTypes.GET_ALL_EMPLOYEES,
        payload: res.data,
      });
      callback(false, "");
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, errorToText(error));
      console.log("err: ", { ...error });
    }
  };
};

export const FC_GetEmployeeDetails = (
  user_id: string,
  callback: (
    loading: boolean,
    res: {
      res: EmployeeDetailsInterface | null;
      type: "success" | "error";
      msg: string;
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.get<EmployeeDetailsInterface>(
        `${API_URL}/users/detail/${user_id}`
      );
      console.log({ EmployeePositionDetails: res.data });
      const ResponseFormatted = {
        employee_behavior: res.data.employee_behavior,
        employee_custom_access:
          res.data.employee_custom_access.length === 0
            ? []
            : res.data.employee_custom_access.map((item) => ({
                ...item,
                access: JSON.parse(item.access as unknown as string),
              })),
        positions:
          res.data.positions.length === 0
            ? []
            : res.data.positions.map((item) => ({
                ...item,
                access: JSON.parse(item.access as unknown as string),
              })),
      };
      dispatch<GetEmployeeDetailsInfoAction>({
        type: ActionTypes.GET_EMPLOYEE_DETAILS,
        payload: ResponseFormatted,
      });
      callback(false, {
        msg: "",
        res: ResponseFormatted,
        type: "success",
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, {
        msg: errorToText(error),
        res: null,
        type: "error",
      });
      console.log("err: ", { ...error });
    }
  };
};

export const FC_RemoveEmployeeBehavior = (
  user_behavior_id: string,
  callback: (loading: boolean, error: string) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, "");
    setAxiosToken();
    try {
      const res = await axios.patch(`${API_URL}/user/behavior`, {
        user_behavior_id: user_behavior_id,
      });
      console.log({ removeEmployeeBehavior: res.data });
      dispatch<RemoveEmployeeBehaviorInfoAction>({
        type: ActionTypes.REMOVE_EMPLOYEE_BEHAVIOR,
        payload: {
          behavior_id: user_behavior_id,
        },
      });
      dispatch<SetSystemSuccessMessageAction>({
        type: ActionTypes.SET_SYSTEM_SUCCESS_MESSAGE,
        payload: "Employee behavior has removed successfully!",
      });
      callback(false, "");
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, errorToText(error));
      console.log("err: ", { ...error });
    }
  };
};

export const FC_AddEmployeeBehavior = (
  data: AddEmployeeBehaviorDataInterface,
  callback: (loading: boolean, error: string) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, "");
    setAxiosToken();
    try {
      const res = await axios.post(`${API_URL}/user/behavior`, data);
      console.log({ removeEmployeeBehavior: res.data });
      dispatch<AddEmployeeBehaviorInfoAction>({
        type: ActionTypes.ADD_EMPLOYEE_BEHAVIOR,
        payload: data,
      });
      dispatch<SetSystemSuccessMessageAction>({
        type: ActionTypes.SET_SYSTEM_SUCCESS_MESSAGE,
        payload: "Employee behavior has added successfully!",
      });
      callback(false, "");
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, errorToText(error));
      console.log("err: ", { ...error });
    }
  };
};

export const FC_AddEmployeeCustomAccess = (
  data: EmployeeCustomAccessDataInterface,
  callback: (loading: boolean, error: string) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, "");
    setAxiosToken();
    try {
      const res = await axios.post(`${API_URL}/custom/access`, data);
      console.log({ removeEmployeeBehavior: res.data });
      dispatch<AddEmployeeCustomAccessInfoAction>({
        type: ActionTypes.ADD_EMPLOYEE_CUSTOM_ACCESS,
        payload: data,
      });
      dispatch<SetSystemSuccessMessageAction>({
        type: ActionTypes.SET_SYSTEM_SUCCESS_MESSAGE,
        payload: "Employee custom access has added successfully!",
      });
      callback(false, "");
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, errorToText(error));
      console.log("err: ", { ...error });
    }
  };
};

export const FC_RemoveEmployeeCustomAccess = (
  custom_access_id: string,
  callback: (loading: boolean, error: string) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, "");
    setAxiosToken();
    try {
      const res = await axios.patch(
        `${API_URL}/custom/access/${custom_access_id}`,
        {
          custom_access_id: custom_access_id,
        }
      );
      console.log({ removeEmployeeCustomAccess: res.data });
      dispatch<RemoveEmployeeCustomAccessInfoAction>({
        type: ActionTypes.REMOVE_EMPLOYEE_CUSTOM_ACCESS,
        payload: {
          custom_access_id: custom_access_id,
        },
      });
      dispatch<SetSystemSuccessMessageAction>({
        type: ActionTypes.SET_SYSTEM_SUCCESS_MESSAGE,
        payload: "Employee custom access has removed successfully!",
      });
      callback(false, "");
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, errorToText(error));
      console.log("err: ", { ...error });
    }
  };
};

export const FC_GetEmployeeEducationBackground = (
  user_id: string,
  type: "AUTH" | "EMPLOYEE",
  callback: (
    loading: boolean,
    res: {
      type: "success" | "error";
      data: EmployeeEducationBackground;
      msg: string;
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.get<EmployeeEducationBackground>(
        `${API_URL}/users/academic/${user_id}`
      );
      console.log({ EducationBackground: res.data });
      if (type === "AUTH") {
        dispatch<GetEmployeeEducationBackgroundInfoAction>({
          type: ActionTypes.GET_EMPLOYEE_EDUCATION_BACKGROUND,
          payload: res.data,
        });
      }
      callback(false, {
        type: "success",
        data: res.data,
        msg: "",
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, {
        type: "error",
        data: {
          qualification: [],
          specialization: [],
        },
        msg: errorToText(error),
      });
      console.log("err: ", { ...error });
    }
  };
};

export const FC_RemoveEmployeeQualification = (
  employee_qualification_id: string,
  callback: (
    loading: boolean,
    res: {
      type: "success" | "error";
      msg: string;
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.delete(
        `${API_URL}/users/qualification/${employee_qualification_id}`
      );
      console.log({ REMOVE_QUALIFICATION: res });
      dispatch<RemoveEmployeeQualificationInfoAction>({
        type: ActionTypes.REMOVE_EMPLOYEE_QUALIFICATION,
        payload: {
          employee_qualification_id: employee_qualification_id,
        },
      });
      dispatch<SetSystemSuccessMessageAction>({
        type: ActionTypes.SET_SYSTEM_SUCCESS_MESSAGE,
        payload: "Removed successfully!",
      });
      callback(false, {
        type: "success",
        msg: "Removed successfully!",
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, {
        type: "error",
        msg: errorToText(error),
      });
      console.log("err: ", { ...error });
    }
  };
};

export const FC_RemoveEmployeeSpecialization = (
  employee_specialization_id: string,
  callback: (
    loading: boolean,
    res: {
      type: "success" | "error";
      msg: string;
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.delete(
        `${API_URL}/users/specialization/${employee_specialization_id}`
      );
      console.log({ REMOVE_SPECIALIZATION: res });
      dispatch<RemoveEmployeeSpecializationInfoAction>({
        type: ActionTypes.REMOVE_EMPLOYEE_SPECIALIZATION,
        payload: {
          employee_specialization_id: employee_specialization_id,
        },
      });
      dispatch<SetSystemSuccessMessageAction>({
        type: ActionTypes.SET_SYSTEM_SUCCESS_MESSAGE,
        payload: "Removed successfully!",
      });
      callback(false, {
        type: "success",
        msg: "Removed successfully!",
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, {
        type: "error",
        msg: errorToText(error),
      });
      console.log("err: ", { ...error });
    }
  };
};

export const FC_AddEmployeeQualification = (
  data: EmployeeQualificationData,
  callback: (loading: boolean, error: string) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, "");
    setAxiosToken();
    try {
      const res = await axios.post(`${API_URL}/users/qualification`, data);
      console.log({ addQualification: res.data });
      dispatch<SetSystemSuccessMessageAction>({
        type: ActionTypes.SET_SYSTEM_SUCCESS_MESSAGE,
        payload: "Employee qualification has added successfully!",
      });
      callback(false, "");
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, errorToText(error));
      console.log("err: ", { ...error });
    }
  };
};

export const FC_AddEmployeeSpecialization = (
  data: EmployeeSpecializationData,
  callback: (loading: boolean, error: string) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, "");
    setAxiosToken();
    try {
      const res = await axios.post(`${API_URL}/users/specialization`, data);
      console.log({ addSpecialization: res.data });
      dispatch<SetSystemSuccessMessageAction>({
        type: ActionTypes.SET_SYSTEM_SUCCESS_MESSAGE,
        payload: "Employee specialization has added successfully!",
      });
      callback(false, "");
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, errorToText(error));
      console.log("err: ", { ...error });
    }
  };
};

export const FC_GetEmployeeDashboardSummary = (
  callback: (
    loading: boolean,
    res: {
      type: "success" | "error";
      msg: string;
      data: EmployeesDashboardSummaryInterface;
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.get<EmployeesDashboardSummaryInterface>(
        `${API_URL}/dashboards/staff/count`
      );
      console.log({ employee_dashboard: res.data });
      callback(false, {
        type: "success",
        msg: "",
        data: res.data,
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, {
        type: "error",
        msg: errorToText(error),
        data: {
          gender_number: [],
          nationality_number: [],
          qualification_number: [],
          specialization_number: [],
        },
      });
      console.log("err: ", { ...error });
    }
  };
};

export const FC_GetUserDashboardDetails = (
  user_id: string,
  callback: (
    loading: boolean,
    res: {
      type: "success" | "error";
      msg: string;
      data: UserDashboardDetailsInterface | null;
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.get<UserDashboardDetailsInterface>(
        `${API_URL}/dashboards/staff/count/${user_id}`
      );
      console.log({ user_dashboard: res.data });
      callback(false, {
        type: "success",
        msg: "",
        data: res.data,
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, {
        type: "error",
        msg: errorToText(error),
        data: null,
      });
      console.log("err: ", { ...error });
    }
  };
};

export const FC_UpdateEmployeePersonalInfo = (
  employeeData: UpdatePersonalInfoData,
  type: "auth" | "employee",
  callback: (
    loading: boolean,
    res: {
      type: "success" | "error";
      msg: string;
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.patch(`${API_URL}/user/modify`, employeeData);
      console.log({ update_user_info: res.data });
      if (type === "auth") {
        // Update personal info
        dispatch<UpdatePersonalInfoAction>({
          type: ActionTypes.UPDATE_PERSONAL_INFO,
          payload: employeeData,
        });
      }
      dispatch<SetSystemSuccessMessageAction>({
        type: ActionTypes.SET_SYSTEM_SUCCESS_MESSAGE,
        payload: "Profile updated successfully!",
      });
      callback(false, {
        type: "success",
        msg: "Profile updated successfully!",
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, {
        type: "error",
        msg: errorToText(error),
      });
      console.log("err: ", { ...error });
    }
  };
};
