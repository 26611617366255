import axios from "axios";
import { Dispatch } from "redux";
import { API_URL } from "../utils/api";
import { setAxiosToken } from "../utils/AxiosToken";
import { errorToText } from "../utils/functions";
import { BooleanEnum } from "./auth.action";
import {
  SetSystemErrorMessageAction,
  SetSystemSuccessMessageAction,
} from "./system.action";
import { ActionTypes } from "./types";

export enum QuestionTypeEnum {
  OPEN = "OPEN",
  OPEN_CHOICE = "OPEN_CHOICE",
}

export enum TrainingEvaluationValidationStatus {
  DRAFT = "DRAFT",
  COMPLETED = "COMPLETED",
}

export interface TrainingEvaluationQuestion__Interface {
  training_evaluation_question_id: string;
  main_title: string | null; // Choice
  subtitle: string | null; //Open
  type: QuestionTypeEnum;
}

export interface TrainingEvaluationAnswers__Interface {
  mainTitleAnswer: BooleanEnum | null;
  main_title: string | null;
  subTitleAnswer: string | null;
  subtitle: string | null;
  training_evaluation_question_id: string;
  type: QuestionTypeEnum;
}

export interface EmployeeTrainingEvaluation__Interface {
  employee_training_evaluation_id: string;
  evaluation_unit_id: string;
  employee_id: string;
  training_id: string;
  answers: TrainingEvaluationAnswers__Interface[] | null;
  status: TrainingEvaluationValidationStatus;
  title: string;
  conclusion: string | null;
  done_by: string;
  done_by_name: string;
  done_by_middle_name: string | null;
  done_by_last_name: string;
  done_by_nid_number: string;
  done_by_phone_number: string;
  done_at: string | null;
}

export interface QuestionAnswerSubmittedInterface {
  employee_training_evaluation_id: string;
  answers: {
    training_evaluation_question_id: string;
    main_title: string | null;
    mainTitleAnswer: string | null;
    subtitle: string | null;
    subTitleAnswer: string | null;
    type: QuestionTypeEnum;
  }[];
}

export interface TrainingEvaluationStore {
  evaluation_questions: TrainingEvaluationQuestion__Interface[] | null;
}

export interface GetTrainingEvaluationDetailsInfoAction {
  type: ActionTypes.GET_TRAINING_EVALUATION_DETAILS;
  payload: EmployeeTrainingEvaluation__Interface[];
}

export interface GetTrainingEvaluationQuestionsInfoAction {
  type: ActionTypes.GET_TRAINING_EVALUATION_QUESTIONS_LIST;
  payload: TrainingEvaluationQuestion__Interface[];
}

export const FC_GetTrainingEvaluationQuestionsList = (
  callback: (
    loading: boolean,
    res: { type: "success" | "error"; msg: string } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.get<TrainingEvaluationQuestion__Interface[]>(
        `${API_URL}/questions`
      );
      console.log({ TrainingEvaluationQuestions: res.data });
      dispatch<GetTrainingEvaluationQuestionsInfoAction>({
        type: ActionTypes.GET_TRAINING_EVALUATION_QUESTIONS_LIST,
        payload: res.data,
      });
      callback(false, {
        type: "success",
        msg: "",
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, {
        type: "error",
        msg: errorToText(error),
      });
      console.log("err: ", { ...error });
    }
  };
};

export const FC_GetTrainingEvaluationDetails = (
  user_id: string,
  training_id: string,
  callback: (
    loading: boolean,
    res: {
      type: "success" | "error";
      msg: string;
      data: EmployeeTrainingEvaluation__Interface[];
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.get<EmployeeTrainingEvaluation__Interface[]>(
        `${API_URL}/evaluations/employee/${user_id}/${training_id}`
      );
      const response_data: EmployeeTrainingEvaluation__Interface[] =
        res.data.map((item) => ({
          ...item,
          answers: JSON.parse(
            item.answers as unknown as string
          ) as TrainingEvaluationAnswers__Interface[],
        }));
      console.log({ TrainingEvaluationsDetails: response_data });
      dispatch<GetTrainingEvaluationDetailsInfoAction>({
        type: ActionTypes.GET_TRAINING_EVALUATION_DETAILS,
        payload: response_data,
      });
      callback(false, {
        type: "success",
        msg: "",
        data: response_data,
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, {
        type: "error",
        msg: errorToText(error),
        data: [],
      });
      console.log("err: ", { ...error });
    }
  };
};

export const FC_CreateTrainingEvaluationDraft = (
  data: {
    evaluation_unit_id: string;
    employee_id: string;
    training_id: string;
  },
  callback: (
    loading: boolean,
    res: { type: "success" | "error"; msg: string } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.post(`${API_URL}/evaluations/draft`, data);
      console.log({ CreateTrainingEvaluationDraft: res.data });
      callback(false, {
        type: "success",
        msg: "Training Evaluation Draft created successfully!, Please fill the following questions",
      });
      dispatch<SetSystemSuccessMessageAction>({
        type: ActionTypes.SET_SYSTEM_SUCCESS_MESSAGE,
        payload:
          "Training Evaluation Draft created successfully!, Please fill the following questions",
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, {
        type: "error",
        msg: errorToText(error),
      });
      console.log("err: ", { ...error });
    }
  };
};

export const FC_SubmitQuestionAnswers = (
  data: QuestionAnswerSubmittedInterface,
  callback: (
    loading: boolean,
    res: { type: "success" | "error"; msg: string } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.patch(`${API_URL}/evaluations/answer`, data);
      console.log({ SubmitTrainingEvaluationQuestionAnswer: res.data });
      callback(false, {
        type: "success",
        msg: "Training Evaluation Answer added successfully!",
      });
      dispatch<SetSystemSuccessMessageAction>({
        type: ActionTypes.SET_SYSTEM_SUCCESS_MESSAGE,
        payload: "Training Evaluation Answer added successfully!",
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, {
        type: "error",
        msg: errorToText(error),
      });
      console.log("err: ", { ...error });
    }
  };
};

export const FC_SubmitTrainingEvaluationStatus = (
  data: {
    employee_training_evaluation_id: string;
    conclusion: string;
    status: "COMPLETED" | "DAFT";
  },
  callback: (
    loading: boolean,
    res: { type: "success" | "error"; msg: string } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.patch(`${API_URL}/evaluations/approval`, data);
      console.log({ SubmitTrainingEvaluationApproval: res.data });
      callback(false, {
        type: "success",
        msg: "Training Evaluation status updated successfully!",
      });
      dispatch<SetSystemSuccessMessageAction>({
        type: ActionTypes.SET_SYSTEM_SUCCESS_MESSAGE,
        payload: "Training Evaluation status updated successfully!",
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, {
        type: "error",
        msg: errorToText(error),
      });
      console.log("err: ", { ...error });
    }
  };
};
