import axios from "axios";
import { setAxiosToken } from "../utils/AxiosToken";
import { SUPPORT_API_URL } from "../utils/api";
import { errorToText } from "../utils/functions";
import {
  SetSystemErrorMessageAction,
  SetSystemSuccessMessageAction,
} from "./system.action";
import { ActionTypes } from "./types";
import { Dispatch } from "redux";

export interface ProductCategory {
  product_category_id: number;
  product_category: string;
}

export interface ProductType {
  product_type_id: number;
  product_type: string;
}

export interface CompanyCategory {
  company_category_id: number;
  company_category: string;
}

export interface CompanyInterface {
  company_id: number;
  company_name: string;
  tin_number: string;
  official_phone_number: string;
  official_email: string;
  province: string;
  district: string;
  sector: string;
  cell: string;
  managing_director: string;
  product_category_id: number;
  product_category: string;
  product_type_id: number;
  product_type: string;
  company_category_id: number;
  company_category: string;
}

export interface GetProductCategoriesAction {
  type: ActionTypes.GET_PRODUCT_CATEGORY;
  payload: ProductCategory[];
}

export interface GetProductTypesAction {
  type: ActionTypes.GET_PRODUCT_TYPES;
  payload: ProductType[];
}

export interface GetCompanyCategoriesAction {
  type: ActionTypes.GET_COMPANY_CATEGORIES;
  payload: CompanyCategory[];
}

export interface GetAllCompaniesAction {
  type: ActionTypes.GET_ALL_COMPANIES;
  payload: CompanyInterface[];
}

export interface CompanyProductStore {
  productCategories: ProductCategory[] | null;
  productTypes: ProductType[] | null;
  companyCategories: CompanyCategory[] | null;
  companies: CompanyInterface[] | null;
}

export const FC_GetProductCategories = (
  callback: (
    loading: boolean,
    res: {
      type: "SUCCESS" | "ERROR";
      msg: string;
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.get<ProductCategory[]>(
        `${SUPPORT_API_URL}/products/category`
      );
      console.log({ ProductCategories: res.data });
      dispatch<GetProductCategoriesAction>({
        type: ActionTypes.GET_PRODUCT_CATEGORY,
        payload: res.data,
      });
      callback(false, {
        type: "SUCCESS",
        msg: "",
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, {
        type: "ERROR",
        msg: errorToText(error),
      });
      console.log("err: ", { ...error });
    }
  };
};

export const FC_GetProductTypes = (
  callback: (
    loading: boolean,
    res: {
      type: "SUCCESS" | "ERROR";
      msg: string;
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.get<ProductType[]>(
        `${SUPPORT_API_URL}/products/type`
      );
      console.log({ ProductTypes: res.data });
      dispatch<GetProductTypesAction>({
        type: ActionTypes.GET_PRODUCT_TYPES,
        payload: res.data,
      });
      callback(false, {
        type: "SUCCESS",
        msg: "",
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, {
        type: "ERROR",
        msg: errorToText(error),
      });
      console.log("err: ", { ...error });
    }
  };
};

export const FC_GetCompanyCategories = (
  callback: (
    loading: boolean,
    res: {
      type: "SUCCESS" | "ERROR";
      msg: string;
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.get<CompanyCategory[]>(
        `${SUPPORT_API_URL}/companies/category`
      );
      console.log({ CompanyCategories: res.data });
      dispatch<GetCompanyCategoriesAction>({
        type: ActionTypes.GET_COMPANY_CATEGORIES,
        payload: res.data,
      });
      callback(false, {
        type: "SUCCESS",
        msg: "",
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, {
        type: "ERROR",
        msg: errorToText(error),
      });
      console.log("err: ", { ...error });
    }
  };
};

export const FC_GetAllCompanies = (
  callback: (
    loading: boolean,
    res: {
      type: "SUCCESS" | "ERROR";
      msg: string;
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.get<CompanyInterface[]>(
        `${SUPPORT_API_URL}/companies`
      );
      console.log({ AllCompanies: res.data });
      dispatch<GetAllCompaniesAction>({
        type: ActionTypes.GET_ALL_COMPANIES,
        payload: res.data,
      });
      callback(false, {
        type: "SUCCESS",
        msg: "",
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, {
        type: "ERROR",
        msg: errorToText(error),
      });
      console.log("err: ", { ...error });
    }
  };
};

export const FC_GetCompaniesByCompanyCategory = (
  company_category_id: string,
  callback: (
    loading: boolean,
    res: {
      type: "SUCCESS" | "ERROR";
      msg: string;
      data: CompanyInterface[];
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.get<CompanyInterface[]>(
        `${SUPPORT_API_URL}/companies/category/${company_category_id}`
      );
      console.log({ CompaniesByCategory: res.data });
      callback(false, {
        type: "SUCCESS",
        msg: "",
        data: res.data,
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, {
        type: "ERROR",
        msg: errorToText(error),
        data: [],
      });
      console.log("err: ", { ...error });
    }
  };
};

export interface BroadCastingMessageData {
  message: string;
  company_category_id: number | null;
  product_category_id: number | null;
  product_type_id: number | null;
  done_by: string;
}

export const FC_SubmitCustomersBroadCastingMessage = (
  data: BroadCastingMessageData,
  type: "company_category" | "product_category" | "product_type",
  callback: (
    loading: boolean,
    res: {
      type: "SUCCESS" | "ERROR";
      msg: string;
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      var LinkType = "/broadcast/company/category";
      switch (type) {
        case "company_category":
          LinkType = "/broadcast/company/category";
          break;
        case "product_category":
          LinkType = "/broadcast/company/product/category";
          break;
        case "product_type":
          LinkType = "/broadcast/company/product/type";
          break;
        default:
          LinkType = "/broadcast/company/category";
      }
      const res = await axios.post(`${SUPPORT_API_URL}${LinkType}`, data);
      console.log({ submitMessage: res.data });
      callback(false, {
        type: "SUCCESS",
        msg: "Message sent successfully!",
      });
      dispatch<SetSystemSuccessMessageAction>({
        type: ActionTypes.SET_SYSTEM_SUCCESS_MESSAGE,
        payload: "Message sent successfully!",
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, {
        type: "ERROR",
        msg: errorToText(error),
      });
      console.log("err: ", { ...error });
    }
  };
};

export interface BroadcastingMessageInterface {
  company_category: string | number;
  company_category_id: number | null;
  message_sent: string;
  message_tracking_id: number | null;
  product_category: string | null;
  product_category_id: number | null;
  product_type: string | null;
  product_type_id: number | null;
  sent_at: string;
}

export const FC_BroadcastingMessagesListByDates = (
  data: {
    start_date: string;
    ending_date: string;
  },
  callback: (
    loading: boolean,
    res: {
      type: "SUCCESS" | "ERROR";
      msg: string;
      data: BroadcastingMessageInterface[];
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.get<BroadcastingMessageInterface[]>(
        `${SUPPORT_API_URL}/broadcast/message/${data.start_date}/${data.ending_date}`
      );
      console.log({ BroadcastingMessage: res.data });
      callback(false, {
        type: "SUCCESS",
        msg: "",
        data: res.data,
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, {
        type: "ERROR",
        msg: errorToText(error),
        data: [],
      });
      console.log("err: ", { ...error });
    }
  };
};
