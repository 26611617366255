import React, { Fragment, lazy, Suspense } from "react";
import { connect } from "react-redux";
import {
  Auth,
  FC_CheckLoggedIn,
  FC_GetSystemInfo,
  FC_Logout,
  System,
  FC_SetError,
  FC_SetSuccess,
  EmploymentItem,
  FC_SwitchSelectedEmployment,
  FC_GetActionPlanPeriods,
  ActionPlanStore,
} from "./actions";
import { StoreState } from "./reducers";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import LazyLoading from "./components/LazyLoading/LazyLoading";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import { Homepage } from "./containers/Homepage/Homepage";
import MainLoading from "./components/MainLoading/MainLoading";
import { NavBar } from "./components/NavBar/NavBar";
import SideNavBar from "./components/SideNavBar/SideNavBar";
import AppLoading from "./components/AppLoading/AppLoading";
import Alert, { AlertType } from "./components/Alert/Alert";
import NetworkError from "./components/NetworkError/NetworkError";
import { SwitchEmployment } from "./components/SwitchEmployment/SwitchEmployment";
import { isAccessAuthorized, UserAccessList } from "./config/userAccess";

//* Components

const Dashboard = lazy(() =>
  import("./containers/Dashboard/Dashboard").then(({ Dashboard }) => ({
    default: Dashboard,
  }))
);
const Profile = lazy(() =>
  import("./containers/Profile/Profile").then(({ Profile }) => ({
    default: Profile,
  }))
);
const ChangePassword = lazy(() =>
  import("./containers/ChangePassword/ChangePassword").then(
    ({ ChangePassword }) => ({
      default: ChangePassword,
    })
  )
);
// Private pages with access
const PositionsManagement = lazy(() =>
  import("./containers/PositionsManagement/PositionsManagement").then(
    ({ PositionsManagement }) => ({
      default: PositionsManagement,
    })
  )
);
const EmployeesManagement = lazy(() =>
  import("./containers/EmployeesManagement/EmployeesManagement").then(
    ({ EmployeesManagement }) => ({
      default: EmployeesManagement,
    })
  )
);

const TrainingPlans = lazy(() =>
  import("./containers/TrainingPlans/TrainingPlans").then(
    ({ TrainingPlans }) => ({
      default: TrainingPlans,
    })
  )
);

const EmployeeTraining = lazy(() =>
  import("./containers/EmployeeTraining/EmployeeTraining").then(
    ({ EmployeeTraining }) => ({
      default: EmployeeTraining,
    })
  )
);

const EmployeeLeaves = lazy(() =>
  import("./containers/EmployeeLeave/EmployeeLeave").then(
    ({ EmployeeLeaves }) => ({
      default: EmployeeLeaves,
    })
  )
);

const EmployeeLeavesByLineManager = lazy(() =>
  import(
    "./containers/EmployeeLeavesByLineManager/EmployeeLeavesByLineManager"
  ).then(({ EmployeeLeavesByLineManager }) => ({
    default: EmployeeLeavesByLineManager,
  }))
);

const EmployeeTravel = lazy(() =>
  import("./containers/EmployeeTravel/EmployeeTravel").then(
    ({ EmployeeTravel }) => ({
      default: EmployeeTravel,
    })
  )
);

const EmployeeTravelsByLineManager = lazy(() =>
  import(
    "./containers/EmployeeTravelsByLineManager/EmployeeTravelsByLineManager"
  ).then(({ EmployeeTravelsByLineManager }) => ({
    default: EmployeeTravelsByLineManager,
  }))
);

const AllEmployeeTravels = lazy(() =>
  import("./containers/AllEmployeeTravels/AllEmployeeTravels").then(
    ({ AllEmployeeTravels }) => ({
      default: AllEmployeeTravels,
    })
  )
);

const TrainingEvaluation = lazy(() =>
  import("./containers/TrainingEvaluation/TrainingEvaluation").then(
    ({ TrainingEvaluation }) => ({
      default: TrainingEvaluation,
    })
  )
);

const TrainingsByCurrentUnit = lazy(() =>
  import("./containers/TrainingsByCurrentUnit/TrainingsByCurrentUnit").then(
    ({ TrainingsByCurrentUnit }) => ({
      default: TrainingsByCurrentUnit,
    })
  )
);

const TrainingsByPlan = lazy(() =>
  import("./containers/TrainingsByPlan/TrainingsByPlan").then(
    ({ TrainingsByPlan }) => ({
      default: TrainingsByPlan,
    })
  )
);

const TrainingsByYear = lazy(() =>
  import("./containers/TrainingsByYear/TrainingsByYear").then(
    ({ TrainingsByYear }) => ({
      default: TrainingsByYear,
    })
  )
);

const CreateTraining = lazy(() =>
  import("./containers/CreateTraining/CreateTraining").then(
    ({ CreateTraining }) => ({
      default: CreateTraining,
    })
  )
);

const EmployeeJobBackground = lazy(() =>
  import("./containers/EmployeeJobBackground/EmployeeJobBackground").then(
    ({ EmployeeJobBackground }) => ({
      default: EmployeeJobBackground,
    })
  )
);

const AdminDashboards = lazy(() =>
  import("./containers/AdminDashboards/AdminDashboards").then(
    ({ AdminDashboards }) => ({
      default: AdminDashboards,
    })
  )
);

const UnitStaffs = lazy(() =>
  import("./containers/EmployeesManagement/UnitStaffs").then(
    ({ UnitStaffs }) => ({
      default: UnitStaffs,
    })
  )
);

const AllEmployeeLeaves = lazy(() =>
  import("./containers/AllEmployeeLeaves/AllEmployeeLeaves").then(
    ({ AllEmployeeLeaves }) => ({
      default: AllEmployeeLeaves,
    })
  )
);

const ComplaintsList = lazy(() =>
  import("./containers/ComplaintsList/ComplaintsList").then(
    ({ ComplaintsList }) => ({
      default: ComplaintsList,
    })
  )
);

const AddComplaint = lazy(() =>
  import("./containers/AddComplaint/AddComplaint").then(({ AddComplaint }) => ({
    default: AddComplaint,
  }))
);

const RegisteredComplaints = lazy(() =>
  import("./containers/RegisteredComplaints/RegisteredComplaints").then(
    ({ RegisteredComplaints }) => ({
      default: RegisteredComplaints,
    })
  )
);

const CompanyCustomers = lazy(() =>
  import("./containers/CompanyCustomers/CompanyCustomers").then(
    ({ CompanyCustomers }) => ({
      default: CompanyCustomers,
    })
  )
);

const LiveChat = lazy(() =>
  import("./containers/LiveChat/LiveChat").then(({ LiveChat }) => ({
    default: LiveChat,
  }))
);

const MainCustomerSupportMonitor = lazy(() =>
  import(
    "./containers/MainCustomerSupportMonitor/MainCustomerSupportMonitor"
  ).then(({ MainCustomerSupportMonitor }) => ({
    default: MainCustomerSupportMonitor,
  }))
);

// Activity plan reporting
const StaffReportedReports = lazy(() =>
  import(
    "./containers/activityPlanModule/StaffReportedReports/StaffReportedReports"
  ).then(({ StaffReportedReports }) => ({
    default: StaffReportedReports,
  }))
);

const ActivityPlanReportingDashboard = lazy(() =>
  import(
    "./containers/activityPlanModule/ActivityPlanReportingDashboard/ActivityPlanReportingDashboard"
  ).then(({ ActivityPlanReportingDashboard }) => ({
    default: ActivityPlanReportingDashboard,
  }))
);

const ActivityPlanManagement = lazy(() =>
  import(
    "./containers/activityPlanModule/ActivityPlanManagement/ActivityPlanManagement"
  ).then(({ ActivityPlanManagement }) => ({
    default: ActivityPlanManagement,
  }))
);

//* Interfaces
// props for the component
interface AppProps {
  auth: Auth;
  system: System;
  actionPlan: ActionPlanStore;
  FC_CheckLoggedIn: (callBack: (status: boolean) => void) => void;
  FC_Logout: () => void;
  FC_GetSystemInfo: (callback: (loading: boolean) => void) => void;
  FC_SetError: (msg: string) => void;
  FC_SetSuccess: (msg: string) => void;
  FC_SwitchSelectedEmployment: (employment: EmploymentItem | null) => void;
  FC_GetActionPlanPeriods: (
    callback: (
      loading: boolean,
      res: { type: "success" | "error"; msg: string } | null
    ) => void
  ) => void;
}

interface AppState {
  loading: boolean;
  openSideNav: boolean;
}

class _App extends React.Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);

    this.state = {
      loading: false,
      openSideNav: true,
    };
  }

  onGetPeriods = () => {
    this.props.FC_GetActionPlanPeriods(
      (
        loading: boolean,
        res: { type: "success" | "error"; msg: string } | null
      ) => {
        this.setState({ loading: loading });
        console.log(res?.msg);
        if (res?.type === "success") {
          this.setState({ loading: false });
        }
      }
    );
  };

  componentDidMount() {
    //* check if the user is logged in
    this.setState({ loading: true });

    this.props.FC_CheckLoggedIn((status: boolean) => {
      if (status === true) {
        if (this.props.actionPlan.actionPlanPeriods !== null) {
          this.setState({ loading: false });
        } else {
          // Load action plan periods
          this.onGetPeriods();
        }
      }
    });
  }

  render() {
    // constants
    const authenticationPath = "/login";

    if (this.props.auth.loading === true) {
      return <MainLoading />;
    }

    if (this.state.loading === true) {
      return <AppLoading />;
    }
    if (
      this.props.auth.selectedEmployment === null &&
      this.props.auth.isAuthenticated === true
    ) {
      return (
        <SwitchEmployment
          auth={this.props.auth}
          setEmploymentItem={this.props.FC_SwitchSelectedEmployment}
          logout={() => this.props.FC_Logout()}
        />
      );
    }
    return (
      <Fragment>
        {/* <Router basename="hrtest"> */}
        <Router basename="/">
          <div className="h-screen">
            {this.props.system.success !== "" && (
              <div className="fixed right-3 top-14 z-50 animate__animated animate__zoomInUp animate__fast pt-3">
                <Alert
                  alertType={AlertType.SUCCESS}
                  title={"Done successfully!"}
                  description={this.props.system.success}
                  close={() => this.props.FC_SetSuccess("")}
                  className="shadow-xl"
                />
              </div>
            )}
            {this.props.system.error !== "" && (
              <div className="fixed right-3 top-14 z-50 animate__animated animate__zoomInUp animate__fast pt-3">
                <Alert
                  alertType={AlertType.DANGER}
                  title={"Error occurred!"}
                  description={this.props.system.error}
                  close={() => this.props.FC_SetError("")}
                  className="shadow-xl"
                />
              </div>
            )}
            {/* Check connectivity */}
            {navigator.onLine === false && <NetworkError />}
            {
              <NavBar
                auth={this.props.auth}
                FC_Logout={this.props.FC_Logout}
                sideNavbarStatus={this.state.openSideNav}
                setOpenVav={(status: boolean) =>
                  this.setState({ openSideNav: status })
                }
                SwitchEmployment={() =>
                  this.props.FC_SwitchSelectedEmployment(null)
                }
              />
            }
            <div
              className={`${
                this.props.auth.isAuthenticated === true
                  ? `bg-gray-200 h-full ${
                      this.state.openSideNav === true ? " w-full md:pl-64" : ""
                    } pt-14 overflow-y-auto`
                  : ""
              }`}
              style={{ zIndex: 9 }}
            >
              {this.props.auth.isAuthenticated === true &&
                this.state.openSideNav === true && (
                  <SideNavBar
                    auth={this.props.auth}
                    sideNavbarStatus={this.state.openSideNav}
                    setOpenVav={(status: boolean) =>
                      this.setState({ openSideNav: status })
                    }
                  />
                )}
              <div
                className={`${
                  this.props.auth.isAuthenticated === true
                    ? "p-1 md:p-2 h-full mt-5 md:mt-0 container mx-auto lg:px-2"
                    : ""
                }`}
              >
                <Switch>
                  <Route exact path="/" component={Homepage} />
                  <Route exact path="/login" component={Homepage} />
                  <Suspense fallback={<LazyLoading />}>
                    <ProtectedRoute
                      path="/dashboard"
                      component={Dashboard}
                      isAuthenticated={this.props.auth.isAuthenticated}
                      authenticationPath={authenticationPath}
                      loading={this.state.loading}
                      exact
                    />

                    <ProtectedRoute
                      path="/profile"
                      component={Profile}
                      isAuthenticated={this.props.auth.isAuthenticated}
                      authenticationPath={authenticationPath}
                      loading={this.state.loading}
                      exact
                    />
                    <ProtectedRoute
                      path="/change-password"
                      component={ChangePassword}
                      isAuthenticated={this.props.auth.isAuthenticated}
                      authenticationPath={authenticationPath}
                      loading={this.state.loading}
                      exact
                    />
                    {isAccessAuthorized(
                      this.props.auth.selectedEmployment,
                      UserAccessList.POSITIONS
                    ).view === true && (
                      <ProtectedRoute
                        path="/positions-management"
                        component={PositionsManagement}
                        isAuthenticated={this.props.auth.isAuthenticated}
                        authenticationPath={authenticationPath}
                        loading={this.state.loading}
                        exact
                      />
                    )}
                    {isAccessAuthorized(
                      this.props.auth.selectedEmployment,
                      UserAccessList.EMPLOYEES_LIST
                    ).view === true && (
                      <ProtectedRoute
                        path="/employees-management"
                        component={EmployeesManagement}
                        isAuthenticated={this.props.auth.isAuthenticated}
                        authenticationPath={authenticationPath}
                        loading={this.state.loading}
                        exact
                      />
                    )}
                    {isAccessAuthorized(
                      this.props.auth.selectedEmployment,
                      UserAccessList.EMPLOYEES_BY_UNIT
                    ).view === true && (
                      <ProtectedRoute
                        path="/unit-staffs"
                        component={UnitStaffs}
                        isAuthenticated={this.props.auth.isAuthenticated}
                        authenticationPath={authenticationPath}
                        loading={this.state.loading}
                        exact
                      />
                    )}
                    {isAccessAuthorized(
                      this.props.auth.selectedEmployment,
                      UserAccessList.EMPLOYEES_TRAINING_PLANS
                    ).view === true && (
                      <ProtectedRoute
                        path="/training-plans"
                        component={TrainingPlans}
                        isAuthenticated={this.props.auth.isAuthenticated}
                        authenticationPath={authenticationPath}
                        loading={this.state.loading}
                        exact
                      />
                    )}
                    {isAccessAuthorized(
                      this.props.auth.selectedEmployment,
                      UserAccessList.EMPLOYEE_TRAINING
                    ).view === true && (
                      <ProtectedRoute
                        path="/employee-trainings"
                        component={EmployeeTraining}
                        isAuthenticated={this.props.auth.isAuthenticated}
                        authenticationPath={authenticationPath}
                        loading={this.state.loading}
                        exact
                      />
                    )}
                    {isAccessAuthorized(
                      this.props.auth.selectedEmployment,
                      UserAccessList.LEAVES
                    ).view === true && (
                      <ProtectedRoute
                        path="/employee-leave"
                        component={EmployeeLeaves}
                        isAuthenticated={this.props.auth.isAuthenticated}
                        authenticationPath={authenticationPath}
                        loading={this.state.loading}
                        exact
                      />
                    )}
                    {isAccessAuthorized(
                      this.props.auth.selectedEmployment,
                      UserAccessList.EMPLOYEE_LEAVE
                    ).view === true && (
                      <ProtectedRoute
                        path="/employee-leaves-by-line-manager"
                        component={EmployeeLeavesByLineManager}
                        isAuthenticated={this.props.auth.isAuthenticated}
                        authenticationPath={authenticationPath}
                        loading={this.state.loading}
                        exact
                      />
                    )}
                    {isAccessAuthorized(
                      this.props.auth.selectedEmployment,
                      UserAccessList.ALL_EMPLOYEE_LEAVE
                    ).view === true && (
                      <ProtectedRoute
                        path="/all-employee-leaves"
                        component={AllEmployeeLeaves}
                        isAuthenticated={this.props.auth.isAuthenticated}
                        authenticationPath={authenticationPath}
                        loading={this.state.loading}
                        exact
                      />
                    )}
                    {isAccessAuthorized(
                      this.props.auth.selectedEmployment,
                      UserAccessList.TRAVELS
                    ).view === true && (
                      <ProtectedRoute
                        path="/employee-travel"
                        component={EmployeeTravel}
                        isAuthenticated={this.props.auth.isAuthenticated}
                        authenticationPath={authenticationPath}
                        loading={this.state.loading}
                        exact
                      />
                    )}
                    {isAccessAuthorized(
                      this.props.auth.selectedEmployment,
                      UserAccessList.EMPLOYEE_TRAVEL
                    ).view === true && (
                      <ProtectedRoute
                        path="/employee-travels-by-line-manager"
                        component={EmployeeTravelsByLineManager}
                        isAuthenticated={this.props.auth.isAuthenticated}
                        authenticationPath={authenticationPath}
                        loading={this.state.loading}
                        exact
                      />
                    )}
                    {isAccessAuthorized(
                      this.props.auth.selectedEmployment,
                      UserAccessList.ALL_EMPLOYEE_TRAVEL
                    ).view === true && (
                      <ProtectedRoute
                        path="/all-employee-travels"
                        component={AllEmployeeTravels}
                        isAuthenticated={this.props.auth.isAuthenticated}
                        authenticationPath={authenticationPath}
                        loading={this.state.loading}
                        exact
                      />
                    )}
                    {isAccessAuthorized(
                      this.props.auth.selectedEmployment,
                      UserAccessList.ALL_TRAININGS
                    ).view === true && (
                      <ProtectedRoute
                        path="/training-evaluation"
                        component={TrainingEvaluation}
                        isAuthenticated={this.props.auth.isAuthenticated}
                        authenticationPath={authenticationPath}
                        loading={this.state.loading}
                        exact
                      />
                    )}
                    {isAccessAuthorized(
                      this.props.auth.selectedEmployment,
                      UserAccessList.TRAININGS_BY_PLAN
                    ).view === true && (
                      <ProtectedRoute
                        path="/trainings-by-plan"
                        component={TrainingsByPlan}
                        isAuthenticated={this.props.auth.isAuthenticated}
                        authenticationPath={authenticationPath}
                        loading={this.state.loading}
                        exact
                      />
                    )}
                    {isAccessAuthorized(
                      this.props.auth.selectedEmployment,
                      UserAccessList.TRAININGS_BY_UNIT
                    ).view === true && (
                      <ProtectedRoute
                        path="/trainings-by-unit"
                        component={TrainingsByCurrentUnit}
                        isAuthenticated={this.props.auth.isAuthenticated}
                        authenticationPath={authenticationPath}
                        loading={this.state.loading}
                        exact
                      />
                    )}
                    {isAccessAuthorized(
                      this.props.auth.selectedEmployment,
                      UserAccessList.ALL_TRAININGS
                    ).view === true && (
                      <ProtectedRoute
                        path="/trainings-by-year"
                        component={TrainingsByYear}
                        isAuthenticated={this.props.auth.isAuthenticated}
                        authenticationPath={authenticationPath}
                        loading={this.state.loading}
                        exact
                      />
                    )}
                    <ProtectedRoute
                      path="/job-background"
                      component={EmployeeJobBackground}
                      isAuthenticated={this.props.auth.isAuthenticated}
                      authenticationPath={authenticationPath}
                      loading={this.state.loading}
                      exact
                    />
                    {(isAccessAuthorized(
                      this.props.auth.selectedEmployment,
                      UserAccessList.ALL_TRAININGS
                    ).create === true ||
                      isAccessAuthorized(
                        this.props.auth.selectedEmployment,
                        UserAccessList.TRAININGS_BY_PLAN
                      ).create === true ||
                      isAccessAuthorized(
                        this.props.auth.selectedEmployment,
                        UserAccessList.TRAININGS_BY_UNIT
                      ).create === true ||
                      isAccessAuthorized(
                        this.props.auth.selectedEmployment,
                        UserAccessList.CREATE_TRAINING_MENU
                      ).view === true) && (
                      <ProtectedRoute
                        path="/create-training"
                        component={CreateTraining}
                        isAuthenticated={this.props.auth.isAuthenticated}
                        authenticationPath={authenticationPath}
                        loading={this.state.loading}
                        exact
                      />
                    )}
                    {isAccessAuthorized(
                      this.props.auth.selectedEmployment,
                      UserAccessList.DASHBOARDS_MENU
                    ).view === true && (
                      <ProtectedRoute
                        path="/admin-dashboard"
                        component={AdminDashboards}
                        isAuthenticated={this.props.auth.isAuthenticated}
                        authenticationPath={authenticationPath}
                        loading={this.state.loading}
                        exact
                      />
                    )}
                    {isAccessAuthorized(
                      this.props.auth.selectedEmployment,
                      UserAccessList.VIEW_COMPLAINTS_LIST
                    ).view === true && (
                      <ProtectedRoute
                        path="/complaints-list"
                        component={ComplaintsList}
                        isAuthenticated={this.props.auth.isAuthenticated}
                        authenticationPath={authenticationPath}
                        loading={this.state.loading}
                        exact
                      />
                    )}
                    {isAccessAuthorized(
                      this.props.auth.selectedEmployment,
                      UserAccessList.ADD_COMPLIANT
                    ).view === true && (
                      <ProtectedRoute
                        path="/add-complaint"
                        component={AddComplaint}
                        isAuthenticated={this.props.auth.isAuthenticated}
                        authenticationPath={authenticationPath}
                        loading={this.state.loading}
                        exact
                      />
                    )}
                    {isAccessAuthorized(
                      this.props.auth.selectedEmployment,
                      UserAccessList.REGISTERED_COMPLAINTS
                    ).view === true && (
                      <ProtectedRoute
                        path="/registered-complaints"
                        component={RegisteredComplaints}
                        isAuthenticated={this.props.auth.isAuthenticated}
                        authenticationPath={authenticationPath}
                        loading={this.state.loading}
                        exact
                      />
                    )}
                    {isAccessAuthorized(
                      this.props.auth.selectedEmployment,
                      UserAccessList.COMPANY_PRODUCT_PAGE
                    ).view === true && (
                      <ProtectedRoute
                        path="/company-customers"
                        component={CompanyCustomers}
                        isAuthenticated={this.props.auth.isAuthenticated}
                        authenticationPath={authenticationPath}
                        loading={this.state.loading}
                        exact
                      />
                    )}
                    {isAccessAuthorized(
                      this.props.auth.selectedEmployment,
                      UserAccessList.LIVE_CHAT
                    ).view === true && (
                      <ProtectedRoute
                        path="/live-chat"
                        component={LiveChat}
                        isAuthenticated={this.props.auth.isAuthenticated}
                        authenticationPath={authenticationPath}
                        loading={this.state.loading}
                        exact
                      />
                    )}
                    {isAccessAuthorized(
                      this.props.auth.selectedEmployment,
                      UserAccessList.MAIN_CUSTOMER_SUPPORT_MONITOR
                    ).view === true && (
                      <ProtectedRoute
                        path="/queries-monitor"
                        component={MainCustomerSupportMonitor}
                        isAuthenticated={this.props.auth.isAuthenticated}
                        authenticationPath={authenticationPath}
                        loading={this.state.loading}
                        exact
                      />
                    )}
                    {/* Activity plan reporting */}
                    {isAccessAuthorized(
                      this.props.auth.selectedEmployment,
                      UserAccessList.ACTION_PLAN_REPORTING
                    ).view === true && (
                      <ProtectedRoute
                        path="/my-reports"
                        component={StaffReportedReports}
                        isAuthenticated={this.props.auth.isAuthenticated}
                        authenticationPath={authenticationPath}
                        loading={this.state.loading}
                        exact
                      />
                    )}
                    {isAccessAuthorized(
                      this.props.auth.selectedEmployment,
                      UserAccessList.ACTION_PLAN_REPORTING_DASHBOARD
                    ).view === true && (
                      <ProtectedRoute
                        path="/reporting-dashboard"
                        component={ActivityPlanReportingDashboard}
                        isAuthenticated={this.props.auth.isAuthenticated}
                        authenticationPath={authenticationPath}
                        loading={this.state.loading}
                        exact
                      />
                    )}
                    {isAccessAuthorized(
                      this.props.auth.selectedEmployment,
                      UserAccessList.ACTIVITY_PLANS_MANAGEMENT
                    ).view === true && (
                      <ProtectedRoute
                        path="/activity-plan-management"
                        component={ActivityPlanManagement}
                        isAuthenticated={this.props.auth.isAuthenticated}
                        authenticationPath={authenticationPath}
                        loading={this.state.loading}
                        exact
                      />
                    )}
                  </Suspense>
                </Switch>
              </div>
            </div>
            {/* Footer */}
            {/* {this.props.auth.isAuthenticated === false && <Footer />} */}
          </div>
        </Router>
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  auth,
  system,
  actionPlan,
}: StoreState): {
  auth: Auth;
  system: System;
  actionPlan: ActionPlanStore;
} => {
  return {
    auth,
    system,
    actionPlan,
  };
};

export const App = connect(mapStateToProps, {
  FC_CheckLoggedIn,
  FC_Logout,
  FC_GetSystemInfo,
  FC_SetError,
  FC_SetSuccess,
  FC_SwitchSelectedEmployment,
  FC_GetActionPlanPeriods,
})(_App);
