import { Dispatch } from "redux";
import { errorToText } from "../utils/functions";
import { ActionTypes } from "./types";
import axios from "axios";
import { API_URL } from "../utils/api";

export interface QuarterInterface {
  id: string;
  name: string;
  type: string;
  start_date: string;
  end_date: string;
  parent_id: string;
  status: 1 | 0;
}

export interface ActionPlanPeriodInterface {
  id: string;
  name: string;
  type: string;
  start_date: string;
  end_date: string;
  parent_id: string | null;
  status: 1 | 0;
  quarters: QuarterInterface[];
}

export interface IndicatorActionPlan {
  unit_id: string;
  unit_name: string;
  programme_title: string;
  outcome: string;
  programme_output_id: string;
  programme_output_title: string;
  sub_programme_id: string;
  sub_programme_title: string;
  indicator_id: string;
  indicator_name: string;
  plan_id: string;
  baseline: string;
  quarter_target: {
    value: string;
    quarter: string;
  }[];
  annual_target: number;
  activity_budget: {
    budget: number;
    activity: string;
  }[];
  plan_year: string;
  created_at: string;
}

export interface ActionPlanStore {
  actionPlanPeriods: ActionPlanPeriodInterface[] | null;
  selectedPeriod: ActionPlanPeriodInterface | null;
  selectedQuarter: QuarterInterface | null;
  actionPlans: IndicatorActionPlan[] | null;
  submittedReportsSummary: UnitSubmittedReportsSummary[] | null;
  generalDashboardSummary:
    | GetActionPlanGeneralDashboardByPeriodInterface[]
    | null;
}

export interface SetSelectedQuarterAction {
  type: ActionTypes.SET_SELECTED_QUARTER;
  payload: QuarterInterface;
}

export interface SetGetActionPlanPeriodsAction {
  type: ActionTypes.SET_GET_ACTION_PLAN_PERIODS;
  payload: ActionPlanPeriodInterface[];
}

export interface SetSelectedActionPlanPeriodAction {
  type: ActionTypes.SET_SELECTED_PERIOD;
  payload: ActionPlanPeriodInterface;
}

export const FC_SetSelectedQuarter = (quarter: QuarterInterface) => {
  return (dispatch: Dispatch) => {
    dispatch<SetSelectedQuarterAction>({
      type: ActionTypes.SET_SELECTED_QUARTER,
      payload: quarter,
    });
  };
};

export interface SetIndicatorActionPlansListAction {
  type: ActionTypes.SET_INDICATORS_ACTION_PLANS_LIST;
  payload: IndicatorActionPlan[];
}

export const FC_GetActionPlanPeriods = (
  callback: (
    loading: boolean,
    res: { type: "success" | "error"; msg: string } | null
  ) => void
) => {
  callback(true, null);
  return async (dispatch: Dispatch) => {
    try {
      const res = await axios.get<ActionPlanPeriodInterface[]>(
        `${API_URL}/plans/periods/active/year`
      );
      dispatch<SetGetActionPlanPeriodsAction>({
        type: ActionTypes.SET_GET_ACTION_PLAN_PERIODS,
        payload: res.data,
      });
      callback(false, {
        msg: "",
        type: "success",
      });
    } catch (error: any) {
      callback(false, {
        msg: errorToText(error),
        type: "error",
      });
    }
  };
};

export const FC_SetSelectedPeriod = (period: ActionPlanPeriodInterface) => {
  return (dispatch: Dispatch) => {
    dispatch<SetSelectedActionPlanPeriodAction>({
      type: ActionTypes.SET_SELECTED_PERIOD,
      payload: period,
    });
  };
};

export const FC_GetIndicatorsActionPlansByYear = (
  plan_year: string,
  unit_id: string,
  callback: (
    loading: boolean,
    res: { type: "success" | "error"; msg: string } | null
  ) => void
) => {
  callback(true, null);
  return async (dispatch: Dispatch) => {
    try {
      const res = await axios.get<IndicatorActionPlan[]>(
        `${API_URL}/plans/unit/${unit_id}/${plan_year}`
      );
      dispatch<SetIndicatorActionPlansListAction>({
        type: ActionTypes.SET_INDICATORS_ACTION_PLANS_LIST,
        payload: res.data,
      });
      callback(false, {
        msg: "",
        type: "success",
      });
    } catch (error: any) {
      callback(false, {
        msg: errorToText(error),
        type: "error",
      });
    }
  };
};

export interface IndicatorActivityInterface {
  activity_id: string;
  indicator_id: string;
  activity_title: string;
}

export const FC_GetIndicatorActivities = async (
  indicator_id: string,
  callback: (
    loading: boolean,
    res: {
      type: "success" | "error";
      msg: string;
      data: IndicatorActivityInterface[];
    } | null
  ) => void
) => {
  callback(true, null);
  try {
    const res = await axios.get<IndicatorActivityInterface[]>(
      `${API_URL}/plans/activities/indicator/${indicator_id}`
    );
    callback(false, {
      msg: "",
      type: "success",
      data: res.data,
    });
  } catch (error: any) {
    callback(false, {
      msg: errorToText(error),
      type: "error",
      data: [],
    });
  }
};

export interface SubmitReportDataInterface {
  user_id: string;
  unit_id: string;
  plan_id: string;
  indicator_id: string;
  quarter: string;
  plan_year: string;
  total_used_budget: string;
  achieved: string;
  report_activity: {
    activity_id: string;
    used_budget: string;
    sponsor_id: string;
  }[];
  key_lesson_learnt: string;
  challenge: string;
  remedial_action: string;
  recommendation: string;
}

export const FC_SubmitIndicatorReport = async (
  data: SubmitReportDataInterface,
  callback: (
    loading: boolean,
    res: {
      type: "success" | "error";
      msg: string;
    } | null
  ) => void
) => {
  callback(true, null);
  try {
    await axios.post(`${API_URL}/plans/reports`, data);
    callback(false, {
      msg: "Indicator activities report submitted successfully!",
      type: "success",
    });
  } catch (error: any) {
    callback(false, {
      msg: errorToText(error),
      type: "error",
    });
  }
};

export interface UnitSubmittedReportsSummary {
  indicator_id: string;
  quarter: string;
  total_used_budget: number;
  total_achieved: number;
}

export interface SetActionPlanReportSummaryAction {
  type: ActionTypes.SET_UNIT_SUBMITTED_ACTION_PLAN_REPORTS_SUMMARY;
  payload: UnitSubmittedReportsSummary[];
}

export const FC_GetUnitSubmittedReportsSummaryByYear = (
  plan_year: string,
  unit_id: string,
  callback: (
    loading: boolean,
    res: { type: "success" | "error"; msg: string } | null
  ) => void
) => {
  callback(true, null);
  return async (dispatch: Dispatch) => {
    try {
      const res = await axios.get<UnitSubmittedReportsSummary[]>(
        `${API_URL}/plans/reports/count/unit/year/${unit_id}/${plan_year}`
      );
      dispatch<SetActionPlanReportSummaryAction>({
        type: ActionTypes.SET_UNIT_SUBMITTED_ACTION_PLAN_REPORTS_SUMMARY,
        payload: res.data,
      });
      callback(false, {
        msg: "",
        type: "success",
      });
    } catch (error: any) {
      callback(false, {
        msg: errorToText(error),
        type: "error",
      });
    }
  };
};

export interface IndicatorSubmittedReport {
  unit_id: string;
  unit_name: string;
  report_id: string;
  staff_code: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  phone_number: string;
  email: string;
  user_id: string;
  plan_id: string;
  indicator_id: string;
  quarter: string;
  total_used_budget: number;
  plan_year: string;
  key_lesson_learnt: string | null;
  challenge: string | null;
  remedial_action: string | null;
  recommendation: string | null;
  report_activity: {
    sponsor_id: string;
    activity_id: string;
    activity_title: string;
    used_budget: string;
  }[];
  achieved: string;
  created_at: string;
}

export const FC_GetSubmittedReportsByIndicator = async (
  plan_id: string,
  indicator_id: string,
  callback: (
    loading: boolean,
    res: {
      type: "success" | "error";
      msg: string;
      data: IndicatorSubmittedReport[];
    } | null
  ) => void
) => {
  callback(true, null);
  try {
    const res = await axios.get<IndicatorSubmittedReport[]>(
      `${API_URL}/plans/reports/plan/indicator/${plan_id}/${indicator_id}`
    );
    callback(false, {
      msg: "",
      type: "success",
      data: res.data,
    });
  } catch (error: any) {
    callback(false, {
      msg: errorToText(error),
      type: "error",
      data: [],
    });
  }
};

export const FC_RemoveIndicatorReport = async (
  report_id: string,
  callback: (
    loading: boolean,
    res: {
      type: "success" | "error";
      msg: string;
    } | null
  ) => void
) => {
  callback(true, null);
  try {
    await axios.delete(`${API_URL}/plans/reports/${report_id}`);
    callback(false, {
      msg: "Report disabled successfully!",
      type: "success",
    });
  } catch (error: any) {
    callback(false, {
      msg: errorToText(error),
      type: "error",
    });
  }
};

export interface GetActionPlanGeneralDashboardByPeriodInterface {
  unit_id: string;
  unit_name: string;
  indicators_report: {
    quarter_id: string;
    indicator_id: string;
    total_used_budget: string;
    total_achieved: string;
    // total_planned: string;
  }[];
  indicator_planned: {
    indicator_id: string;
    indicator_name: string;
    total_planned_budget: number;
    quarter_target: {
      value: string;
      quarter: string;
    }[];
  }[];
}

export interface SetGetActionPlanGeneralDashboardSummaryAction {
  type: ActionTypes.SET_ACTION_PLAN_GENERAL_DASHBOARD_SUMMARY;
  payload: GetActionPlanGeneralDashboardByPeriodInterface[];
}

export const FC_GetActionPlanGeneralDashboardSummary = (
  plan_year_id: string,
  callback: (
    loading: boolean,
    res: {
      type: "success" | "error";
      msg: string;
    } | null
  ) => void
) => {
  callback(true, null);
  return async (dispatch: Dispatch) => {
    try {
      const res = await axios.get<
        GetActionPlanGeneralDashboardByPeriodInterface[]
      >(`${API_URL}/plans/dashboard/year/${plan_year_id}`);
      dispatch<SetGetActionPlanGeneralDashboardSummaryAction>({
        type: ActionTypes.SET_ACTION_PLAN_GENERAL_DASHBOARD_SUMMARY,
        payload: res.data,
      });
      callback(false, {
        msg: "",
        type: "success",
      });
    } catch (error: any) {
      callback(false, {
        msg: errorToText(error),
        type: "error",
      });
    }
  };
};

export const FC_UpdateUsedBudget = async (
  data: {
    report_id: string;
    total_used_budget: number;
    report_activity: {
      activity_id: string;
      used_budget: number;
      sponsor_id: number;
    }[];
  },
  callback: (
    loading: boolean,
    res: {
      type: "success" | "error";
      msg: string;
    } | null
  ) => void
) => {
  callback(true, null);
  try {
    await axios.patch(`${API_URL}/plans/reports/activity/budget`, data);
    callback(false, {
      msg: "Report budget updated successfully!",
      type: "success",
    });
  } catch (error: any) {
    callback(false, {
      msg: errorToText(error),
      type: "error",
    });
  }
};

export interface IndicatorInterface {
  indicator_id: string;
  indicator_name: string;
  programme_output_id: string;
}

export const FC_GetIndicatorsListByUnit = async (
  unit_id: string,
  callback: (
    loading: boolean,
    res: {
      type: "success" | "error";
      msg: string;
      data: IndicatorInterface[];
    } | null
  ) => void
) => {
  callback(true, null);
  try {
    const res = await axios.get<IndicatorInterface[]>(
      `${API_URL}/plans/indicators/unit/${unit_id}`
    );
    callback(false, {
      msg: "",
      type: "success",
      data: res.data.map((item) => ({
        ...item,
        indicator_id: item.indicator_id.toString(),
      })),
    });
  } catch (error: any) {
    callback(false, {
      msg: errorToText(error),
      type: "error",
      data: [],
    });
  }
};

export const FC_CreatePlan = async (
  data: {
    unit_id: string;
    indicator_id: string;
    baseline: string;
    plan_year: string;
    annual_target: string;
    total_plan_budget: string;
    activity_budget: {
      activity: string;
      budget: number;
    }[];
    quarter_target: {
      quarter: string;
      value: number;
    }[];
  },
  callback: (
    loading: boolean,
    res: {
      type: "success" | "error";
      msg: string;
    } | null
  ) => void
) => {
  callback(true, null);
  try {
    await axios.post(`${API_URL}/plans`, data);
    callback(false, {
      msg: "Plan created successfully!",
      type: "success",
    });
  } catch (error: any) {
    callback(false, {
      msg: errorToText(error),
      type: "error",
    });
  }
};

export interface ActionPlanReportSubmitted {
  user_id: string;
  plan_id: string;
  indicator_id: string;
  indicator_name: string;
  quarter: string;
  total_used_budget: string;
  report_activity: {
    sponsor_id: string;
    activity_id: string;
    used_budget: string;
  }[];
  achieved: string;
  plan_year: string;
  key_lesson_learnt: string;
  challenge: string;
  remedial_action: string;
  recommendation: string;
  created_at: string;
}

export const FC_GetReportsByDates = async (
  unit_id: string,
  start_date: string,
  end_date: string,
  callback: (
    loading: boolean,
    res: {
      type: "success" | "error";
      msg: string;
      data: ActionPlanReportSubmitted[];
    } | null
  ) => void
) => {
  callback(true, null);
  try {
    const res = await axios.get<ActionPlanReportSubmitted[]>(
      `${API_URL}/plans/reports/daterange/unit/${unit_id}/${start_date}/${end_date}`
    );
    callback(false, {
      msg: "",
      type: "success",
      data: res.data,
    });
  } catch (error: any) {
    callback(false, {
      msg: errorToText(error),
      type: "error",
      data: [],
    });
  }
};
