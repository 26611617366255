import React, { Component } from "react";
import { connect } from "react-redux";
import { StoreState } from "../../reducers";
import { Auth } from "../../actions";
import {
  ServiceComplaintItemInterface,
  FC_SubmitServiceComplaintResponse,
} from "../../actions/complaints.action";
import { ComplaintStatus, LanguageEnum } from "./ComplaintsList";
import Textarea from "../../components/Fragments/Textarea";
import BackButton from "../../components/Fragments/BackButton";
import { BiMessageRoundedCheck } from "react-icons/bi";
import LoaderComponent from "../../components/Loading/LoaderComponent";
import Alert, { AlertType } from "../../components/Alert/Alert";
import { BsCheckCircleFill } from "react-icons/bs";
import { ImRadioUnchecked } from "react-icons/im";
import {
  FC_AddSocketComplaint,
  SocketComplaintData,
} from "../../actions/socket.action";

interface ComplaintResponseFormProps {
  complaintDetails: ServiceComplaintItemInterface;
  onBack: () => void;
  onSubmitted: (
    data: {
      complaint_status: ComplaintStatus;
      complaint_reason: string;
      service_complaint_id: string;
    },
    callBack: (completedChanges: boolean) => void
  ) => void;
  FC_SubmitServiceComplaintResponse: (
    data: {
      complaint_status: ComplaintStatus;
      complaint_reason: string;
      service_complaint_id: string;
      complaint_lang: LanguageEnum;
    },
    callback: (
      loading: boolean,
      res: {
        type: "SUCCESS" | "ERROR";
        msg: string;
        data: ServiceComplaintItemInterface[];
      } | null
    ) => void
  ) => void;
  FC_AddSocketComplaint: (data: SocketComplaintData) => void;
}
interface ComplaintResponseFormState {
  complaint_status: ComplaintStatus | null;
  complaint_reason: string;
  service_complaint_id: string;
  openSelectStatus: boolean;
  error: { target: "main" | "complaint_reason"; msg: string } | null;
  success: string;
  loading: boolean;
}

class _ComplaintResponseForm extends Component<
  ComplaintResponseFormProps,
  ComplaintResponseFormState
> {
  constructor(props: ComplaintResponseFormProps) {
    super(props);
    this.state = {
      complaint_reason: "",
      complaint_status: null,
      service_complaint_id: this.props.complaintDetails.service_complaint_id,
      openSelectStatus: true,
      error: null,
      success: "",
      loading: false,
    };
  }
  SubmitComplaint = () => {
    if (this.state.complaint_reason === "") {
      return this.setState({
        error: {
          target: "complaint_reason",
          msg: "Please fill query response message details",
        },
      });
    }
    if (this.state.complaint_status === null) {
      return this.setState({
        error: {
          target: "main",
          msg: "Please select query status",
        },
      });
    }
    this.setState({ error: null, success: "", loading: true });
    const data = {
      complaint_reason: this.state.complaint_reason,
      complaint_status: this.state.complaint_status,
      service_complaint_id: this.state.service_complaint_id,
      complaint_lang: this.props.complaintDetails.complaint_lang,
    };
    this.props.FC_SubmitServiceComplaintResponse(
      data,
      (
        loading: boolean,
        res: {
          type: "SUCCESS" | "ERROR";
          msg: string;
          data: ServiceComplaintItemInterface[];
        } | null
      ) => {
        this.setState({ loading: loading });
        if (res?.type === "SUCCESS") {
          this.state.complaint_status !== null &&
            this.props.FC_AddSocketComplaint({
              complaint_status: this.state.complaint_status,
              unit_id: this.props.complaintDetails.unit_id,
              reason_id: this.props.complaintDetails.short_reason,
              complaint_reason_en:
                this.props.complaintDetails.complaint_reason_en,
            });
          this.setState({ success: res.msg, loading: true, error: null });
          setTimeout(() => {
            this.props.onSubmitted(data, (completedChanges: boolean) =>
              this.props.onBack()
            );
          }, 500);
        }
        if (res?.type === "ERROR") {
          this.setState({
            error: { target: "main", msg: res.msg },
            success: "",
            loading: false,
          });
        }
      }
    );
  };
  render() {
    const ComplaintStatusesList: {
      key: ComplaintStatus;
      value: string;
      description: string;
    }[] = [
      {
        key: ComplaintStatus.PROGRESS,
        value: "In Progress",
        description: "When you are still working on the query",
      },
      {
        key: ComplaintStatus.COMPLETED,
        value: "Completed",
        description: "When you are going to submit final response",
      },
      {
        key: ComplaintStatus.REJECTED,
        value: "Rejected",
        description: "When you do not accept the submitted query",
      },
    ];
    return (
      <div className="bg-gray-200 rounded-md p-4">
        <div className="flex flex-row items-center gap-2 mb-2 border-b border-gray-300 pb-2">
          <BackButton
            title="Back"
            className="bg-white hover:bg-primary-700 hover:text-white rounded-md"
            onClick={() => this.state.loading === false && this.props.onBack()}
          />
          <div className="font-bold text-xl">Submit Response</div>
        </div>
        <div className="">
          <div className="pt-3">
            <Textarea
              title={"Response Message to be sent to the customer"}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                this.setState({
                  complaint_reason: e.target.value,
                  error: null,
                  success: "",
                })
              }
              disabled={this.state.loading}
              value={this.state.complaint_reason}
              error={
                this.state.error?.target === "complaint_reason"
                  ? this.state.error.msg
                  : ""
              }
              className="bg-white border-2 border-gray-400"
            />
          </div>

          <div className="text-sm">Choose response status</div>
          {/* <div className="relative">
            {this.state.openSelectStatus === false ? (
              <div
                onClick={() =>
                  this.state.loading === false &&
                  this.setState({
                    openSelectStatus: !this.state.openSelectStatus,
                  })
                }
                className={`flex flex-row items-center justify-between gap-2 w-full border-2 ${
                  this.state.complaint_status === ComplaintStatus.COMPLETED
                    ? "border-green-600 bg-green-50"
                    : this.state.complaint_status === ComplaintStatus.PROGRESS
                    ? "border-primary-700 bg-primary-50"
                    : "border-red-600 bg-red-50"
                } focus:outline-none px-3 py-2 rounded-md cursor-pointer`}
              >
                <div className="font-bold">
                  {
                    ComplaintStatusesList.find(
                      (itm) => itm.key === this.state.complaint_status
                    )?.value
                  }
                </div>
                <div>
                  <MdKeyboardArrowDown className="text-2xl text-gray-400" />
                </div>
              </div>
            ) : (
              <div className="relative">
                {this.state.loading === false && (
                  <SelectCustom
                    loading={false}
                    id={"key"}
                    title={"value"}
                    close={() => this.setState({ openSelectStatus: false })}
                    data={ComplaintStatusesList}
                    click={(data: { key: ComplaintStatus; value: string }) => {
                      this.state.loading === false &&
                        this.setState({
                          complaint_status: data.key,
                          openSelectStatus: false,
                          error: null,
                          success: "",
                        });
                    }}
                  />
                )}
              </div>
            )}
          </div> */}
          <div className="grid grid-cols-12 gap-3 my-2">
            {ComplaintStatusesList.map((item, i) => (
              <div
                onClick={() =>
                  this.state.loading === false &&
                  this.setState({
                    complaint_status: item.key,
                    openSelectStatus: false,
                    error: null,
                    success: "",
                  })
                }
                key={i + 1}
                className="col-span-12 md:col-span-4"
              >
                <div
                  className={`flex flex-row items-center gap-2 w-full p-3 ${
                    this.state.complaint_status !== item.key
                      ? "bg-white hover:bg-primary-100"
                      : item.key === ComplaintStatus.COMPLETED
                      ? "bg-green-100 border-2 border-green-500"
                      : item.key === ComplaintStatus.PROGRESS
                      ? "bg-primary-100 border-2 border-primary-700"
                      : "bg-red-100 border-2 border-red-500"
                  } ${
                    item.key === ComplaintStatus.COMPLETED
                      ? "border-2 border-green-500"
                      : item.key === ComplaintStatus.PROGRESS
                      ? "border-2 border-primary-700"
                      : "border-2 border-red-500"
                  } rounded-lg cursor-pointer`}
                >
                  <div>
                    {this.state.complaint_status === item.key ? (
                      <BsCheckCircleFill
                        className={`text-4xl ${
                          item.key === ComplaintStatus.COMPLETED
                            ? "text-green-600"
                            : item.key === ComplaintStatus.PROGRESS
                            ? "text-primary-800"
                            : "text-red-500"
                        } animate__animated animate__bounceIn`}
                      />
                    ) : (
                      <ImRadioUnchecked className="text-4xl text-gray-400" />
                    )}
                  </div>
                  <div className="">
                    <div
                      className={`font-bold ${
                        item.key === ComplaintStatus.COMPLETED
                          ? "text-green-600"
                          : item.key === ComplaintStatus.PROGRESS
                          ? "text-primary-800"
                          : "text-red-500"
                      }`}
                    >
                      {item.value}
                    </div>
                    <div
                      className={`text-sm ${
                        this.state.complaint_status === item.key
                          ? "text-black"
                          : "text-gray-500"
                      }`}
                    >
                      {item.description}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {this.state.error?.target === "main" && (
            <div className="">
              <Alert
                alertType={AlertType.DANGER}
                title={this.state.error.msg}
                close={() => this.setState({ error: null, success: "" })}
              />
            </div>
          )}
          {this.state.success !== "" && (
            <div className="">
              <Alert
                alertType={AlertType.SUCCESS}
                title={this.state.success}
                close={() => this.setState({ error: null, success: "" })}
              />
            </div>
          )}
          <div className="flex flex-row items-center justify-end gap-2">
            {this.state.loading === false && (
              <div
                onClick={() => this.SubmitComplaint()}
                className="flex flex-row items-center justify-center gap-2 w-max px-3 py-2 rounded-md bg-green-600 hover:bg-green-700 text-white cursor-pointer mt-3"
              >
                <div>
                  <BiMessageRoundedCheck className="text-2xl" />
                </div>
                <span>Submit Response</span>
              </div>
            )}
            {this.state.loading === true && (
              <div className="w-full">
                <LoaderComponent title="Sending Query Response..." />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  auth,
}: StoreState): {
  auth: Auth;
} => {
  return { auth };
};

export const ServiceComplaintResponseForm = connect(mapStateToProps, {
  FC_SubmitServiceComplaintResponse,
  FC_AddSocketComplaint,
})(_ComplaintResponseForm);
