import axios from "axios";
import FileSaver from "file-saver";
import { Dispatch } from "redux";
import { API_URL } from "../utils/api";
import { setAxiosToken } from "../utils/AxiosToken";
import { errorToText } from "../utils/functions";
import {
  SetSystemErrorMessageAction,
  SetSystemSuccessMessageAction,
} from "./system.action";
import { ActionTypes } from "./types";

export enum TravelValidationStatus {
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  PENDING = "PENDING",
}

export enum TransportAllowanceEnum {
  PUBLIC = "PUBLIC",
  PRIVATE = "PRIVATE",
}

export enum TravelTypeEnum {
  INTERNATIONAL = "INTERNATIONAL",
  NATIONAL = "NATIONAL",
}

export interface EmployeeTravelInterface {
  employee_travel_id: string;
  unit_id: string;
  position_id: string;
  mission_purpose: string;
  expected_results: string;
  destination: string;
  departure_date: string;
  return_date: string;
  mission_allowance: number;
  account_number: string;
  transport_allowance: TransportAllowanceEnum;
  source_of_funds: string;
  travel_status: TravelValidationStatus;
  travel_status_comment: string;
  report_date: string;
  report_comment: string;
  report_doc: string;
  position_name: string;
  unit_name: string;
  issued_to: string;
  travel_type: TravelTypeEnum;
  other_comment: string;
}

export interface EmployeesTravelList {
  employee_travel_id: string;
  unit_id: string;
  unit_name: string;
  position_id: string;
  mission_purpose: string;
  expected_results: string;
  destination: string;
  departure_date: string;
  return_date: string;
  other_comment: string;
  mission_allowance: number;
  transport_allowance: TransportAllowanceEnum;
  source_of_funds: string;
  travel_status: TravelValidationStatus;
  travel_type: TravelTypeEnum;
  travel_status_comment: string | null;
  report_date: string | null;
  report_comment: string | null;
  report_doc: string | null;
  issued_to: string;
  first_name: string;
  middle_name: string | null;
  last_name: string;
  nid_number: string;
  phone_number: string;
  position_name: string;
}

export interface CreateTravelData {
  issued_to: string; //USER_ID
  mission_purpose: string;
  expected_results: string;
  destination: string;
  departure_date: string;
  return_date: string;
  mission_allowance: number;
  transport_allowance: TransportAllowanceEnum;
  source_of_funds: string;
  position_id: string;
  unit_id: string;
  other_comment: string;
  travel_type: TravelTypeEnum;
}

export interface TravelStore {
  employee_travels: EmployeeTravelInterface[] | null;
}

//* ********************** ACTION TYPE INTERFACES ********************** */
export interface GetTravelsListByUnitStatusInfoAction {
  type: ActionTypes.GET_TRAVELS_BY_UNIT_STATUS;
  payload: EmployeesTravelList[];
}

export interface GetEmployeeTravelsInfoAction {
  type: ActionTypes.GET_EMPLOYEE_TRAVELS;
  payload: EmployeeTravelInterface[];
}
export interface RemoveEmployeeTravelInfoAction {
  type: ActionTypes.REMOVE_EMPLOYEE_TRAVEL;
  payload: {
    employee_travel_id: string;
  };
}
export interface UpdateEmployeeTravelStatusInfoAction {
  type: ActionTypes.UPDATE_EMPLOYEE_TRAVEL_STATUS;
  payload: {
    employee_travel_id: string;
    travel_status: TravelValidationStatus;
    travel_status_comment: string;
  };
}
export interface UpdateEmployeeTravelReportingInfoAction {
  type: ActionTypes.UPDATE_EMPLOYEE_TRAVEL_REPORTING;
  payload: {
    employee_travel_id: string;
    report_date: string;
    report_comment: string;
    report_doc: File;
  };
}
export const FC_GetEmployeeTravels = (
  user_id: string,
  callback: (loading: boolean, error: string) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, "");
    setAxiosToken();
    try {
      const res = await axios.get<EmployeeTravelInterface[]>(
        `${API_URL}/travel/employee/${user_id}`
      );
      console.log({ EmployeeTravels: res.data });
      dispatch<GetEmployeeTravelsInfoAction>({
        type: ActionTypes.GET_EMPLOYEE_TRAVELS,
        payload: res.data,
      });
      callback(false, "");
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, errorToText(error));
      console.log("err: ", { ...error });
    }
  };
};

export const FC_GetCustomEmployeeTravels = (
  user_id: string,
  callback: (
    loading: boolean,
    res: {
      type: "success" | "error";
      msg: string;
      data: EmployeeTravelInterface[];
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.get<EmployeeTravelInterface[]>(
        `${API_URL}/travel/employee/${user_id}`
      );
      console.log({ EmployeeTravels: res.data });
      callback(false, {
        type: "success",
        msg: "",
        data: res.data,
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, {
        type: "error",
        msg: errorToText(error),
        data: [],
      });
      console.log("err: ", { ...error });
    }
  };
};

export const FC_CreateTravelRequest = (
  data: CreateTravelData,
  callback: (
    loading: boolean,
    res: { type: "success" | "error"; msg: string } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.post(`${API_URL}/travel/employee`, data);
      console.log({ CreateTravel: res.data });
      callback(false, {
        type: "success",
        msg: "Travel Request sent successfully",
      });
      dispatch<SetSystemSuccessMessageAction>({
        type: ActionTypes.SET_SYSTEM_SUCCESS_MESSAGE,
        payload: "Travel Request sent successfully",
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, { type: "error", msg: errorToText(error) });
      console.log("err: ", { ...error });
    }
  };
};

export const FC_RemoveEmployeeTravel = (
  employee_travel_id: string,
  callback: (
    loading: boolean,
    res: { type: "success" | "error"; msg: string } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.delete(
        `${API_URL}/travel/employee/${employee_travel_id}`
      );
      console.log({ RemoveTravel: res.data });
      callback(false, {
        type: "success",
        msg: "Travel removed successfully",
      });
      dispatch<RemoveEmployeeTravelInfoAction>({
        type: ActionTypes.REMOVE_EMPLOYEE_TRAVEL,
        payload: {
          employee_travel_id: employee_travel_id,
        },
      });
      dispatch<SetSystemSuccessMessageAction>({
        type: ActionTypes.SET_SYSTEM_SUCCESS_MESSAGE,
        payload: "Travel removed successfully",
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, { type: "error", msg: errorToText(error) });
      console.log("err: ", { ...error });
    }
  };
};

export const FC_ChangeEmployeeTravelStatus = (
  employee_travel_id: string,
  travel_status: TravelValidationStatus,
  travel_status_comment: string,
  callback: (
    loading: boolean,
    res: { type: "success" | "error"; msg: string } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.patch(
        `${API_URL}/travel/employee/change/status`,
        {
          travel_status: travel_status,
          travel_status_comment: travel_status_comment,
          employee_travel_id: employee_travel_id,
        }
      );
      console.log({ UpdateTravelStatus: res.data });
      dispatch<UpdateEmployeeTravelStatusInfoAction>({
        type: ActionTypes.UPDATE_EMPLOYEE_TRAVEL_STATUS,
        payload: {
          travel_status: travel_status,
          travel_status_comment: travel_status_comment,
          employee_travel_id: employee_travel_id,
        },
      });
      callback(false, {
        type: "success",
        msg: "Status updated successfully!",
      });
      dispatch<SetSystemSuccessMessageAction>({
        type: ActionTypes.SET_SYSTEM_SUCCESS_MESSAGE,
        payload: "Status updated successfully!",
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, { type: "error", msg: errorToText(error) });
      console.log("err: ", { ...error });
    }
  };
};

export const FC_GetEmployeesTravelsByUnitStatus = (
  unit_id: string,
  status: TravelValidationStatus,
  callback: (
    loading: boolean,
    res: {
      type: "success" | "error";
      msg: string;
      data: EmployeesTravelList[];
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.get<EmployeesTravelList[]>(
        `${API_URL}/travel/unit/status/${unit_id}/${status}`
      );
      console.log({ TravelsList: res.data });
      dispatch<GetTravelsListByUnitStatusInfoAction>({
        type: ActionTypes.GET_TRAVELS_BY_UNIT_STATUS,
        payload: res.data,
      });
      callback(false, {
        type: "success",
        data: res.data,
        msg: "",
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, {
        type: "error",
        data: [],
        msg: errorToText(error),
      });
      console.log("err: ", { ...error });
    }
  };
};

export const FC_GetEmployeesTravelsByStatus = (
  status: TravelValidationStatus,
  callback: (
    loading: boolean,
    res: {
      type: "success" | "error";
      msg: string;
      data: EmployeesTravelList[];
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.get<EmployeesTravelList[]>(
        `${API_URL}/travel/status/${status}`
      );
      console.log({ TravelsList: res.data });
      dispatch<GetTravelsListByUnitStatusInfoAction>({
        type: ActionTypes.GET_TRAVELS_BY_UNIT_STATUS,
        payload: res.data,
      });
      callback(false, {
        type: "success",
        data: res.data,
        msg: "",
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, {
        type: "error",
        data: [],
        msg: errorToText(error),
      });
      console.log("err: ", { ...error });
    }
  };
};

export const FC_EmployeeTravelReturn = (
  employee_travel_id: string,
  report_date: string,
  report_comment: string,
  report_doc: File,
  callback: (
    loading: boolean,
    res: { type: "success" | "error"; msg: string } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    const formData = new FormData();
    formData.append("employee_travel_id", employee_travel_id.toString());
    formData.append("report_date", report_date);
    formData.append("report_comment", report_comment);
    formData.append("report_doc", report_doc);
    try {
      const res = await axios.post(
        `${API_URL}/travel/employee/return`,
        formData
      );
      console.log({ UpdateTravelReturn: res.data });
      dispatch<UpdateEmployeeTravelReportingInfoAction>({
        type: ActionTypes.UPDATE_EMPLOYEE_TRAVEL_REPORTING,
        payload: {
          employee_travel_id: employee_travel_id,
          report_date: report_date,
          report_comment: report_comment,
          report_doc: report_doc,
        },
      });
      callback(false, {
        type: "success",
        msg: "Return status updated successfully!",
      });
      dispatch<SetSystemSuccessMessageAction>({
        type: ActionTypes.SET_SYSTEM_SUCCESS_MESSAGE,
        payload: "Return status updated successfully!",
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, { type: "error", msg: errorToText(error) });
      console.log("err: ", { ...error });
    }
  };
};

export const DownloadCertificate = async (
  employee_travel_id: string,
  callback: (
    loading: boolean,
    feedback: {
      type: "success" | "error";
      msg: string;
    } | null
  ) => void
) => {
  callback(true, null);
  setAxiosToken();
  try {
    const res = await axios.get(
      `${API_URL}/download/travel/clearance/${employee_travel_id}`
    );
    if (res) {
      FileSaver.saveAs(
        `${API_URL}/download/travel/clearance/${employee_travel_id}`,
        "Travel Clearance.pdf"
      );
      callback(false, {
        type: "success",
        msg: "Travel clearance has been downloaded!",
      });
    }
  } catch (error: any) {
    callback(false, {
      type: "error",
      msg: errorToText(error),
    });
    console.log("Errr: ", { ...error });
  }
};
