import { Dispatch } from "redux";
import { setAxiosToken } from "../utils/AxiosToken";
import axios from "axios";
import { SUPPORT_API_URL } from "../utils/api";
import {
  SetSystemErrorMessageAction,
  SetSystemSuccessMessageAction,
} from "./system.action";
import { ActionTypes } from "./types";
import { errorToText } from "../utils/functions";
import {
  ComplaintStatus,
  LanguageEnum,
} from "../containers/ComplaintsList/ComplaintsList";

export interface ServiceComplaintType {
  complaint_reason_id: string;
  complaint_reason: string;
}

export interface CommunicationChannelItem {
  communication_channel_id: string;
  communication_channel: string;
}

export interface ServiceComplaintItemInterface {
  service_complaint_id: string;
  customer_id: string;
  document_version_id: string;
  service_id: string;
  service_category_id: string;
  unit_id: string;
  expiration_date: string | null;
  service_render_date: string | null;
  rfd_offer_name: string | null;
  short_reason: string;
  description: string;
  support_doc_name: string | null;
  country_code: string | null;
  district_code: string | null;
  complaint_tracking_code: string;
  created_at: string;
  complaint_status: ComplaintStatus;
  progress_reason: string | null;
  progress_date: string | null;
  completed_reason: string | null;
  completed_date: string | null;
  rejected_reason: string | null;
  rejected_date: string | null;
  customer_feedback: string | null;
  customer_feedback_date: string | null;
  names: string | null;
  phone_number: string | null;
  email: string | null;
  gender: string | null;
  age: string | null;
  kiny_service: string | null;
  eng_service: string | null;
  fr_service: string | null;
  kiny_service_category: string;
  eng_service_category: string;
  fr_service_category: string;
  complaint_reason_en: string;
  complaint_reason_fr: string;
  complaint_reason_kiny: string;
  communication_channel_id: string;
  complaint_lang: LanguageEnum;
}

export interface ProductComplaintItemInterface {
  product_complaint_id: string;
  customer_id: string;
  document_version_id: string;
  service_id: string;
  service_category_id: string;
  unit_id: string;
  short_reason: string;
  description: string;
  product_image_name: string | null;
  country_code: string | null;
  district_code: string | null;
  complaint_tracking_code: string;
  created_at: string;
  complaint_status: ComplaintStatus;
  progress_reason: string | null;
  progress_date: string | null;
  completed_reason: string | null;
  completed_date: string | null;
  rejected_reason: string | null;
  rejected_date: string | null;
  customer_feedback: string | null;
  customer_feedback_date: string | null;
  names: string | null;
  phone_number: string | null;
  email: string | null;
  gender: string | null;
  age: string | null;
  kiny_service: string | null;
  eng_service: string | null;
  fr_service: string | null;
  kiny_service_category: string;
  eng_service_category: string;
  fr_service_category: string;
  product_name: string;
  progress_description: string | null;
  completed_description: string | null;
  rejected_description: string | null;
  complaint_reason_en: string;
  complaint_reason_fr: string;
  complaint_reason_kiny: string;
  communication_channel_id: string;
  complaint_lang: LanguageEnum;
}

export interface TotalCountsByStatusInterface {
  total_complaint: number;
  total_pending: number;
  total_progress: number;
  total_completed: number;
  total_rejected: number;
}

export interface AllUnitsTotalCountsByStatusInterface {
  unit_id: string;
  total_complaint: number;
  total_pending: number;
  total_progress: number;
  total_completed: number;
  total_rejected: number;
}

export interface TotalCountsGroupedInReasons {
  short_reason: string;
  total: number;
  complaint_reason_en: string;
  complaint_reason_fr: string;
  complaint_reason_kiny: string;
}

export interface AllUnitsTotalCountsGroupedInReasons {
  short_reason: string;
  complaint_reason_en: string;
  complaint_reason_fr: string;
  complaint_reason_kiny: string;
  total: number;
  unit_id: string;
}

export interface TotalCountsInterface {
  service_count: TotalCountsByStatusInterface[];
  product_count: TotalCountsByStatusInterface[];
  service_reason_count: TotalCountsGroupedInReasons[];
  product_reason_count: TotalCountsGroupedInReasons[];
}

export interface MainCustomerSupportMonitorDataInterface {
  unit_id: string;
  short_reason: string;
  complaint_reason_en: string;
  total_pending: number;
  total_progress: number;
  total_completed: number;
  total_rejected: number;
}

export interface AllUnitsTotalCountsInterface {
  service_count: AllUnitsTotalCountsByStatusInterface[];
  product_count: AllUnitsTotalCountsByStatusInterface[];
  service_reason_count: AllUnitsTotalCountsGroupedInReasons[];
  product_reason_count: AllUnitsTotalCountsGroupedInReasons[];
}

export interface ComplaintStoreInterface {
  complaintLiveDashboard: MainCustomerSupportMonitorDataInterface[] | null;
  openStatusChangedPopup: ComplaintStatus | null;
}

export interface GetLiveMainDashboardDataAction {
  type: ActionTypes.GET_LIVE_MAIN_DASHBOARD;
  payload: MainCustomerSupportMonitorDataInterface[];
}

export interface SetComplaintStatusChangedAction {
  type: ActionTypes.COMPLAINT_STATUS_CHANGED;
  payload: ComplaintStatus | null;
}

export const FC_GetAllServiceComplaintsList = (
  data: {
    start_date: string;
    ending_date: string;
  },
  callback: (
    loading: boolean,
    res: {
      type: "SUCCESS" | "ERROR";
      msg: string;
      data: ServiceComplaintItemInterface[];
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.get(
        `${SUPPORT_API_URL}/complaints/service/${data.start_date}/${data.ending_date}`
      );
      console.log({ getServiceComplaints: res.data });
      dispatch<SetSystemSuccessMessageAction>({
        type: ActionTypes.SET_SYSTEM_SUCCESS_MESSAGE,
        payload: "",
      });
      callback(false, {
        type: "SUCCESS",
        msg: "",
        data: res.data,
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, {
        type: "ERROR",
        msg: errorToText(error),
        data: [],
      });
      console.log("err: ", { ...error });
    }
  };
};

export const FC_GetAllProductComplaintsList = (
  data: {
    start_date: string;
    ending_date: string;
  },
  callback: (
    loading: boolean,
    res: {
      type: "SUCCESS" | "ERROR";
      msg: string;
      data: ProductComplaintItemInterface[];
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.get<ProductComplaintItemInterface[]>(
        `${SUPPORT_API_URL}/complaints/product/${data.start_date}/${data.ending_date}`
      );
      console.log({ getServiceComplaints: res.data });
      dispatch<SetSystemSuccessMessageAction>({
        type: ActionTypes.SET_SYSTEM_SUCCESS_MESSAGE,
        payload: "",
      });
      callback(false, {
        type: "SUCCESS",
        msg: "",
        data: res.data,
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, {
        type: "ERROR",
        msg: errorToText(error),
        data: [],
      });
      console.log("err: ", { ...error });
    }
  };
};

export const FC_GetServiceComplaintsListByUnit = (
  data: {
    unit_id: string;
    start_date: string;
    ending_date: string;
  },
  callback: (
    loading: boolean,
    res: {
      type: "SUCCESS" | "ERROR";
      msg: string;
      data: ServiceComplaintItemInterface[];
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.get(
        `${SUPPORT_API_URL}/complaints/service/unit/${data.unit_id}/${data.start_date}/${data.ending_date}`
      );
      console.log({ getServiceComplaints: res.data });
      dispatch<SetSystemSuccessMessageAction>({
        type: ActionTypes.SET_SYSTEM_SUCCESS_MESSAGE,
        payload: "",
      });
      callback(false, {
        type: "SUCCESS",
        msg: "",
        data: res.data,
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, {
        type: "ERROR",
        msg: errorToText(error),
        data: [],
      });
      console.log("err: ", { ...error });
    }
  };
};

export const FC_GetProductComplaintsListByUnit = (
  data: {
    unit_id: string;
    start_date: string;
    ending_date: string;
  },
  callback: (
    loading: boolean,
    res: {
      type: "SUCCESS" | "ERROR";
      msg: string;
      data: ProductComplaintItemInterface[];
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.get<ProductComplaintItemInterface[]>(
        `${SUPPORT_API_URL}/complaints/product/unit/${data.unit_id}/${data.start_date}/${data.ending_date}`
      );
      console.log({ getServiceComplaints: res.data });
      dispatch<SetSystemSuccessMessageAction>({
        type: ActionTypes.SET_SYSTEM_SUCCESS_MESSAGE,
        payload: "",
      });
      callback(false, {
        type: "SUCCESS",
        msg: "",
        data: res.data,
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, {
        type: "ERROR",
        msg: errorToText(error),
        data: [],
      });
      console.log("err: ", { ...error });
    }
  };
};

export const FC_SubmitServiceComplaintResponse = (
  data: {
    complaint_status: ComplaintStatus;
    complaint_reason: string;
    service_complaint_id: string;
    complaint_lang: string;
  },
  callback: (
    loading: boolean,
    res: {
      type: "SUCCESS" | "ERROR";
      msg: string;
      data: ServiceComplaintItemInterface[];
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.patch(
        `${SUPPORT_API_URL}/complaints/service/response`,
        data
      );
      console.log({ submitComplaintResponse: res.data });
      dispatch<SetSystemSuccessMessageAction>({
        type: ActionTypes.SET_SYSTEM_SUCCESS_MESSAGE,
        payload: "Response Submitted Successfully!",
      });
      callback(false, {
        type: "SUCCESS",
        msg: "Response Submitted Successfully!",
        data: res.data,
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, {
        type: "ERROR",
        msg: errorToText(error),
        data: [],
      });
      console.log("err: ", { ...error });
    }
  };
};

export const FC_SubmitProductComplaintResponse = (
  data: {
    complaint_status: ComplaintStatus;
    complaint_reason: string;
    product_complaint_id: string;
    complaint_lang: string;
  },
  callback: (
    loading: boolean,
    res: {
      type: "SUCCESS" | "ERROR";
      msg: string;
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.patch(
        `${SUPPORT_API_URL}/complaints/product/response`,
        data
      );
      console.log({ submitComplaintResponse: res.data });
      dispatch<SetSystemSuccessMessageAction>({
        type: ActionTypes.SET_SYSTEM_SUCCESS_MESSAGE,
        payload: "Response has Submitted Successfully!",
      });
      callback(false, {
        type: "SUCCESS",
        msg: "Response has Submitted Successfully!",
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, {
        type: "ERROR",
        msg: errorToText(error),
      });
      console.log("err: ", { ...error });
    }
  };
};

export const FC_GetTotalComplaintsByUnit = (
  data: {
    unit_id: string;
    start_date: string;
    ending_date: string;
  },
  callback: (
    loading: boolean,
    res: {
      type: "SUCCESS" | "ERROR";
      msg: string;
      data: TotalCountsInterface | null;
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.get<TotalCountsInterface>(
        `${SUPPORT_API_URL}/dashboard/unit/count/${data.unit_id}/${data.start_date}/${data.ending_date}`
      );
      console.log({ getUnitDashboard: res.data });
      dispatch<SetSystemSuccessMessageAction>({
        type: ActionTypes.SET_SYSTEM_SUCCESS_MESSAGE,
        payload: "",
      });
      callback(false, {
        type: "SUCCESS",
        msg: "",
        data: res.data,
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, {
        type: "ERROR",
        msg: errorToText(error),
        data: null,
      });
      console.log("err: ", { ...error });
    }
  };
};

export const FC_GetTotalComplaintsAllUnits = (
  data: {
    start_date: string;
    ending_date: string;
  },
  callback: (
    loading: boolean,
    res: {
      type: "SUCCESS" | "ERROR";
      msg: string;
      data: AllUnitsTotalCountsInterface | null;
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.get<AllUnitsTotalCountsInterface>(
        `${SUPPORT_API_URL}/dashboard/grouped/count/${data.start_date}/${data.ending_date}`
      );
      console.log({ getAllUnitsDashboard: res.data });
      dispatch<SetSystemSuccessMessageAction>({
        type: ActionTypes.SET_SYSTEM_SUCCESS_MESSAGE,
        payload: "",
      });
      callback(false, {
        type: "SUCCESS",
        msg: "",
        data: res.data,
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, {
        type: "ERROR",
        msg: errorToText(error),
        data: null,
      });
      console.log("err: ", { ...error });
    }
  };
};

export const FC_GetMainMonitorDashboardData = (
  callback: (
    loading: boolean,
    res: {
      type: "SUCCESS" | "ERROR";
      msg: string;
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.get<MainCustomerSupportMonitorDataInterface[]>(
        `${SUPPORT_API_URL}/dashboard/main/live/count`
      );
      dispatch<GetLiveMainDashboardDataAction>({
        type: ActionTypes.GET_LIVE_MAIN_DASHBOARD,
        payload: res.data,
      });
      callback(false, {
        type: "SUCCESS",
        msg: "",
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, {
        type: "ERROR",
        msg: errorToText(error),
      });
      console.log("err: ", { ...error });
    }
  };
};

export const SetNewComplaintStatusChanged = (
  status: ComplaintStatus | null
) => {
  return (dispatch: Dispatch) => {
    dispatch<SetComplaintStatusChangedAction>({
      type: ActionTypes.COMPLAINT_STATUS_CHANGED,
      payload: status,
    });
  };
};

// Query forwarding
export const FC_ComplaintForwarding = (
  data: {
    query_id: string;
    forward_from_unit_id: string;
    forward_from_unit_name: string;
    forward_to_unit_id: string;
    forward_to_unit_name: string;
    forwarded_by: string; // UserId
  },
  callback: (
    loading: boolean,
    res: {
      type: "SUCCESS" | "ERROR";
      msg: string;
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.post(`${SUPPORT_API_URL}/farward/query`, data);
      console.log({ submitComplaintResponse: res.data });
      dispatch<SetSystemSuccessMessageAction>({
        type: ActionTypes.SET_SYSTEM_SUCCESS_MESSAGE,
        payload: "Request Forwarded Successfully!",
      });
      callback(false, {
        type: "SUCCESS",
        msg: "Request Forwarded Successfully!",
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, {
        type: "ERROR",
        msg: errorToText(error),
      });
      console.log("err: ", { ...error });
    }
  };
};
