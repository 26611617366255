import React, { Component } from "react";
import { BsCheckCircleFill } from "react-icons/bs";
import Alert, { AlertType } from "../../components/Alert/Alert";
import { NoResultFound } from "../../components/Fragments/NoResultFound";
import SearchInput from "../../components/Fragments/SearchInput";
import LoaderComponent from "../../components/Loading/LoaderComponent";
import { DATE, commaFy, search } from "../../utils/functions";
import {
  ComplaintStatus,
  LanguageEnum,
  ComplaintStatusDiv,
  ComplaintTypeEnum,
} from "./ComplaintsList";
import { ProductComplaintItemInterface } from "../../actions/complaints.action";
import { Auth, System, UnitInterface } from "../../actions";
import BackButton from "../../components/Fragments/BackButton";
import ExportToExcel from "../../components/GenerateReport/ExportToExcel";
import ReactPaginate from "react-paginate";

interface ListOfComplaintsProps {
  auth: Auth;
  selectedComplaint: ProductComplaintItemInterface | null;
  setSelectedComplaint: (
    selectedComplaint: ProductComplaintItemInterface | null
  ) => void;
  loadComplaintsList: (start_date: string, ending_date: string) => void;
  complaintsList: ProductComplaintItemInterface[] | null;
  loading: boolean;
  error: string;
  success: string;
  language: LanguageEnum;
  setLanguage: (language: LanguageEnum) => void;
  onGoBack: () => void;
  selectedStatus: ComplaintStatus;
  serviceType: ComplaintTypeEnum;
  system: System;
  setDate: (start_date: string, ending_date: string) => void;
  start_date: string;
  ending_date: string;
  showUnits: boolean;
  units: UnitInterface[];
}
interface ListOfComplaintsState {
  openSelectComplaintType: boolean;
  loading: boolean;
  searchData: string;
  openSelectLanguage: boolean;
  error: string;
  success: string;
  itemsPerPage: number;
  currentPage: number;
}

export class ListOfProductComplaints extends Component<
  ListOfComplaintsProps,
  ListOfComplaintsState
> {
  constructor(props: ListOfComplaintsProps) {
    super(props);
    this.state = {
      openSelectComplaintType: false,
      loading: false,
      searchData: "",
      openSelectLanguage: false,
      error: this.props.error,
      success: this.props.success,
      itemsPerPage: 10,
      currentPage: 0,
    };
  }

  FilteredData = (): ProductComplaintItemInterface[] => {
    if (this.props.complaintsList === null) {
      return [];
    }
    var response = this.props.complaintsList;
    return search(
      response,
      this.state.searchData
    ) as ProductComplaintItemInterface[];
  };
  componentDidMount(): void {
    if (this.props.auth.selectedEmployment !== null) {
      this.props.loadComplaintsList(
        this.props.start_date,
        this.props.ending_date
      );
    }
  }
  handlePageClick = (selectedItem: { selected: number }) => {
    this.setState({ currentPage: selectedItem.selected });
  };
  render() {
    const pageCount = Math.ceil(
      this.FilteredData().length / this.state.itemsPerPage
    );
    const displayedItems = this.FilteredData().slice(
      this.state.currentPage * this.state.itemsPerPage,
      (this.state.currentPage + 1) * this.state.itemsPerPage
    );
    return (
      <div>
        <div className="bg-white p-2 mt-3 rounded-md">
          <div className="flex flex-row items-center gap-3">
            <div>
              <BackButton
                title="Back"
                onClick={this.props.onGoBack}
                className="bg-primary-50 text-primary-800 hover:bg-primary-700 hover:text-white rounded-md"
              />
            </div>
            <div className="flex flex-row items-center  justify-between gap-2 w-full">
              <div>
                <div className="text-lg font-bold">List of queries</div>
                <div className="text-sm text-gray-500">
                  List of queries for the selected status
                </div>
              </div>
              <div className="flex flex-row items-center justify-end gap-2 pr-3">
                <div className="flex flex-row items-center justify-end gap-2">
                  <div>
                    <div className="text-xs">Start date</div>
                    <input
                      type="date"
                      className="bg-gray-100 px-3 py-2 rounded-md text-sm font-bold"
                      value={this.props.start_date}
                      onChange={(e) => {
                        if (e.target.value !== "") {
                          this.props.loadComplaintsList(
                            e.target.value,
                            this.props.ending_date
                          );
                          this.props.setDate(
                            e.target.value,
                            this.props.ending_date
                          );
                        }
                      }}
                    />
                  </div>
                  <div>
                    <div className="text-xs">Ending date</div>
                    <input
                      type="date"
                      className="bg-gray-100 px-3 py-2 rounded-md text-sm font-bold"
                      value={this.props.ending_date}
                      onChange={(e) => {
                        if (e.target.value !== "") {
                          this.props.loadComplaintsList(
                            this.props.start_date,
                            e.target.value
                          );
                          this.props.setDate(
                            this.props.start_date,
                            e.target.value
                          );
                        }
                      }}
                    />
                  </div>
                </div>
                <div>{ComplaintStatusDiv(this.props.selectedStatus)}</div>
                {this.props.complaintsList !== null && (
                  <div className="text-2xl font-bold">
                    {commaFy(this.props.complaintsList.length)}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {this.props.complaintsList === null ||
        this.state.loading === true ||
        this.props.loading === true ? (
          <div className="bg-white rounded-md overflow-hidden mt-2 px-3 pb-3 animate__animated animate__fadeIn">
            <LoaderComponent />
          </div>
        ) : (
          <div className="mt-2 bg-white rounded-md p-3">
            <div className="flex flex-row items-center justify-between gap-2">
              <div className="w-full">
                <SearchInput
                  searchData={this.state.searchData}
                  onChange={(value: string) =>
                    this.setState({ searchData: value })
                  }
                />
              </div>
              <div>
                <div
                  onClick={() =>
                    this.setState({
                      openSelectLanguage: !this.state.openSelectLanguage,
                    })
                  }
                  className="p-1 rounded-md border flex flex-row items-center justify-between gap-3 w-60 hover:bg-primary-50 hover:border-primary-300 cursor-pointer hover:text-primary-800"
                >
                  <div className="font-bold pl-3">{this.props.language}</div>
                  <div className="p-2 py-1 rounded-md bg-primary-100 text-primary-800">
                    Change
                  </div>
                </div>
                {this.state.openSelectLanguage === true && (
                  <div className="absolute w-60 right-5 pt-1 z-10">
                    <div className="bg-white rounded-md border shadow-md animate__animated animate__fadeInUp animate__faster">
                      <div className="flex flex-row items-center justify-between gap-2 w-full border-b px-3 pr-1 py-1">
                        <div className="font-bold">Choose language</div>
                        <div
                          onClick={() =>
                            this.setState({ openSelectLanguage: false })
                          }
                          className="p-1 px-2 rounded-md bg-red-50 hover:bg-red-100 text-red-800 cursor-pointer"
                        >
                          Close
                        </div>
                      </div>
                      <div className="p-2">
                        {[
                          LanguageEnum.ENG,
                          LanguageEnum.FR,
                          LanguageEnum.KINY,
                        ].map((item, i) => (
                          <div
                            className={`px-3 py-2 text-sm border ${
                              this.props.language === item
                                ? "border-green-400 text-green-600 animate__animated animate__fadeIn"
                                : "hover:border-primary-300 hover:bg-primary-100 hover:text-primary-900"
                            } rounded-md mb-1 cursor-pointer flex flex-row items-center gap-2`}
                            key={i + 1}
                            onClick={() => {
                              this.props.setLanguage(item);
                              this.setState({
                                openSelectLanguage: false,
                              });
                            }}
                          >
                            {item === this.props.language && (
                              <div>
                                <BsCheckCircleFill className="text-xl text-green-500" />
                              </div>
                            )}
                            <span>{item}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <ExportToExcel
                fileData={this.FilteredData().map((item) => ({
                  complaint_tracking_code: item.complaint_tracking_code,
                  complaint_status: item.complaint_status,
                  description: item.description,
                  country_code: item.country_code,
                  district_code: item.district_code,
                  created_at: item.created_at,
                  progress_reason: item.progress_reason,
                  progress_date: item.progress_date,
                  completed_reason: item.completed_reason,
                  completed_date: item.completed_date,
                  rejected_reason: item.rejected_reason,
                  rejected_date: item.rejected_date,
                  customer_feedback: item.customer_feedback,
                  customer_feedback_date: item.customer_feedback_date,
                  names: item.names,
                  phone_number: item.phone_number,
                  email: item.email,
                  gender: item.gender,
                  age: item.age,
                  kiny_service: item.kiny_service,
                  eng_service: item.eng_service,
                  fr_service: item.fr_service,
                  kiny_service_category: item.kiny_service_category,
                  eng_service_category: item.eng_service_category,
                  fr_service_category: item.fr_service_category,
                  product_name: item.product_name,
                  progress_description: item.progress_description,
                  completed_description: item.completed_description,
                  rejected_description: item.rejected_description,
                  complaint_reason_en: item.complaint_reason_en,
                  complaint_reason_fr: item.complaint_reason_fr,
                  complaint_reason_kiny: item.complaint_reason_kiny,
                  unit: this.props.units.find(
                    (itm) => itm.unit_id.toString() === item.unit_id.toString()
                  )?.unit_name,
                  communication_channel:
                    this.props.system.communication_channels?.find(
                      (itm) =>
                        itm.communication_channel_id ===
                        item.communication_channel_id
                    )?.communication_channel,
                  complaint_lang: item.complaint_lang,
                }))}
                fileName={`Product queries for ${DATE(
                  this.props.start_date
                )} - ${DATE(this.props.ending_date)}`}
                btnName="Download"
              />
            </div>
            {this.state.error !== "" && (
              <div className="py-2">
                <Alert
                  alertType={AlertType.DANGER}
                  title={this.state.error}
                  close={() => this.setState({ error: "", success: "" })}
                />
              </div>
            )}
            {this.state.success !== "" && (
              <div className="py-2">
                <Alert
                  alertType={AlertType.SUCCESS}
                  title={this.state.success}
                  close={() => this.setState({ error: "", success: "" })}
                />
              </div>
            )}
            <div className="w-full overflow-x-auto overflow-y-hidden mt-4">
              {this.props.complaintsList.length === 0 ? (
                <NoResultFound title="No Complaints available" />
              ) : this.FilteredData().length === 0 ? (
                <NoResultFound />
              ) : (
                <div>
                  <table className="min-w-full text-left text-sm">
                    <thead>
                      <tr>
                        <th className="p-2 border w-2 text-center">#</th>
                        <th className="p-2 border truncate">Tracking Number</th>
                        <th className="p-2 border">Product</th>
                        <th className="p-2 border">Reason</th>
                        <th className="p-2 border">Submission Date</th>
                        <th className="p-2 border">Customer</th>
                        {this.props.showUnits && (
                          <th className="p-2 border">Unit</th>
                        )}
                        {this.props.system.communication_channels && (
                          <th className="p-2 border">Channel</th>
                        )}
                        <th className="p-2 border w-2">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {displayedItems.map((item, i) => (
                        <tr
                          key={i + 1}
                          className="hover:text-primary-800 cursor-pointer hover:bg-primary-50 text-sm"
                          title="Click to view details"
                          onClick={() => this.props.setSelectedComplaint(item)}
                        >
                          <td className="p-2 border w-2 text-center">
                            {i +
                              1 +
                              this.state.currentPage * this.state.itemsPerPage}
                          </td>
                          <td className="p-2 border truncate">
                            {item.complaint_tracking_code}
                          </td>
                          <td className="p-2 border">
                            {this.props.language === LanguageEnum.ENG
                              ? item.eng_service
                              : this.props.language === LanguageEnum.FR
                              ? item.fr_service
                              : item.kiny_service}
                          </td>
                          <td className="p-2 border">
                            {this.props.language === LanguageEnum.ENG
                              ? item.complaint_reason_en
                              : this.props.language === LanguageEnum.FR
                              ? item.complaint_reason_fr
                              : item.complaint_reason_kiny}
                          </td>
                          <td className="p-2 border">
                            {DATE(item.created_at)}
                          </td>
                          <td className="p-2 border">{item.names}</td>
                          {this.props.showUnits && (
                            <td className="p-2 border">
                              {
                                this.props.units.find(
                                  (itm) =>
                                    itm.unit_id.toString() ===
                                    item.unit_id.toString()
                                )?.unit_name
                              }
                            </td>
                          )}
                          {this.props.system.communication_channels && (
                            <td className="p-2 border">
                              <div>
                                {
                                  this.props.system.communication_channels.find(
                                    (itm) =>
                                      itm.communication_channel_id ===
                                      item.communication_channel_id
                                  )?.communication_channel
                                }
                              </div>
                            </td>
                          )}
                          <td className="p-2 w-2 border">
                            {ComplaintStatusDiv(item.complaint_status)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <div className="pt-6 pb-6">
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                      activeLinkClassName="border-white text-white bg-primary-700 py-1 px-4 rounded-full"
                      nextLinkClassName="rounded-full bg-primary-50 hover:bg-primary-100 text-primary-800"
                      previousLinkClassName="rounded-full bg-primary-50 hover:bg-primary-100 text-primary-800"
                      pageLinkClassName="rounded-full text-black hover:bg-primary-700 hover:text-white hover:border-white"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}
