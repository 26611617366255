import React, { Component } from "react";
import { connect } from "react-redux";
import { StoreState } from "../../reducers";
import { Auth } from "../../actions";
import {
  FC_GetTotalComplaintsByUnit,
  TotalCountsInterface,
} from "../../actions/complaints.action";
import {
  BsBarChartSteps,
  BsCheckCircle,
  BsInfoCircle,
  BsQuestionCircle,
} from "react-icons/bs";
import { MdClose, MdDashboard } from "react-icons/md";
import LoaderComponent from "../../components/Loading/LoaderComponent";
import {
  DATE,
  GetDaysFromTwoDates,
  calculateTimingFromDays,
  commaFy,
} from "../../utils/functions";
import ReactApexChart from "react-apexcharts";
import { FaChartPie } from "react-icons/fa";
import {
  ComplaintStatus,
  ComplaintTypeEnum,
  ServiceComplaintType,
} from "./ComplaintsList";

interface UnitDashboardProps {
  auth: Auth;
  FC_GetTotalComplaintsByUnit: (
    data: {
      unit_id: string;
      start_date: string;
      ending_date: string;
    },
    callback: (
      loading: boolean,
      res: {
        type: "SUCCESS" | "ERROR";
        msg: string;
        data: TotalCountsInterface | null;
      } | null
    ) => void
  ) => void;
  onOpenList: (status: ComplaintStatus) => void;
  selectedType: ComplaintTypeEnum;
  start_date: string;
  ending_date: string;
  setDate: (start_date: string, ending_date: string) => void;
}

interface UnitDashboardState {
  loading: boolean;
  data: TotalCountsInterface | null;
  error: string;
  selectedReason: ServiceComplaintType | null;
  openSelectReason: boolean;
}

class _UnitDashboard extends Component<UnitDashboardProps, UnitDashboardState> {
  constructor(props: UnitDashboardProps) {
    super(props);
    this.state = {
      loading: false,
      data: null,
      error: "",
      selectedReason: null,
      openSelectReason: false,
    };
  }

  LoadDashboard = (start_date: string, ending_date: string) => {
    this.setState({ loading: true });
    if (this.props.auth.selectedEmployment !== null) {
      this.props.FC_GetTotalComplaintsByUnit(
        {
          unit_id: this.props.auth.selectedEmployment.unit_id,
          start_date: DATE(start_date, "YYYY/MM/DD"),
          ending_date: DATE(ending_date, "YYYY/MM/DD"),
        },
        (
          loading: boolean,
          res: {
            type: "SUCCESS" | "ERROR";
            msg: string;
            data: TotalCountsInterface | null;
          } | null
        ) => {
          this.setState({ loading: loading });
          if (res?.type === "SUCCESS") {
            this.setState({ loading: false, data: res.data, error: "" });
          }
          if (res?.type === "ERROR") {
            this.setState({ error: res.msg, loading: false, data: null });
          }
        }
      );
    }
  };

  GetReasonsSummary = (data: TotalCountsInterface, type: ComplaintTypeEnum) => {
    var response: { complaint_reason: ServiceComplaintType; total: number }[] =
      [];
    for (const item of type === ComplaintTypeEnum.SERVICE
      ? data.service_reason_count
      : data.product_reason_count) {
      if (
        response.find(
          (itm) =>
            itm.complaint_reason.complaint_reason_id.toString() ===
            item.short_reason.toString()
        ) === undefined
      ) {
        response.push({
          complaint_reason: {
            complaint_reason: item.complaint_reason_en,
            complaint_reason_id: item.short_reason,
          },
          total: item.total,
        });
      } else {
        response = response.map((itm) => ({
          ...itm,
          total:
            itm.complaint_reason.complaint_reason_id.toString() ===
            item.short_reason.toString()
              ? itm.total + item.total
              : itm.total,
        }));
      }
    }
    return response;
  };

  componentDidMount(): void {
    this.LoadDashboard(this.props.start_date, this.props.ending_date);
  }

  render() {
    if (this.state.loading === true || this.state.data === null) {
      return <LoaderComponent />;
    }
    return (
      <div className="py-4">
        <div className="bg-white mb-2 rounded-lg p-3 py-2 flex flex-row items-center gap-3 justify-between w-full">
          <div className="flex flex-row items-center gap-3 w-full">
            <div>
              <MdDashboard className="text-gray-300 text-4xl" />
            </div>
            <div>
              <div className="text-lg font-bold">
                Dashboard{" "}
                {GetDaysFromTwoDates(
                  this.props.start_date,
                  this.props.ending_date
                ) < 0 ? (
                  <span
                    style={{ paddingTop: "1px", paddingBottom: "1px" }}
                    className="text-gray-500 text-sm bg-gray-100 rounded-md italic px-2"
                  >
                    Invalid dates
                  </span>
                ) : (
                  <span
                    style={{ paddingTop: "1px", paddingBottom: "1px" }}
                    className="bg-yellow-100 text-yellow-700 font-bold text-sm px-2 border border-yellow-200 rounded-lg"
                  >
                    for{" "}
                    {calculateTimingFromDays(
                      GetDaysFromTwoDates(
                        this.props.start_date,
                        this.props.ending_date
                      )
                    )}
                  </span>
                )}
              </div>
              <div className="text-sm text-gray-500">
                This shows graphic representation of submitted queries grouped
                in their statuses
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center justify-end gap-2">
            <div>
              <div className="text-xs">Start date</div>
              <input
                type="date"
                className="bg-gray-100 px-3 py-2 rounded-md text-sm font-bold"
                value={this.props.start_date}
                onChange={(e) => {
                  if (e.target.value !== "") {
                    this.LoadDashboard(e.target.value, this.props.ending_date);
                    this.props.setDate(e.target.value, this.props.ending_date);
                  }
                }}
              />
            </div>
            <div>
              <div className="text-xs">Ending date</div>
              <input
                type="date"
                className="bg-gray-100 px-3 py-2 rounded-md text-sm font-bold"
                value={this.props.ending_date}
                onChange={(e) => {
                  if (e.target.value !== "") {
                    this.LoadDashboard(this.props.start_date, e.target.value);
                    this.props.setDate(this.props.start_date, e.target.value);
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-3">
          <div className="col-span-12 md:col-span-6">
            <div className="h-max bg-white rounded-md p-3 grid grid-cols-12 gap-3">
              <div className="col-span-12 pb-2 pt-1">
                <div className="flex flex-row items-center gap-3">
                  <div>
                    <FaChartPie className="text-3xl text-primary-700" />
                  </div>
                  <div className="text-base text-gray-500 font-bold">
                    Summary by status
                  </div>
                </div>
              </div>
              <div
                onClick={() => this.props.onOpenList(ComplaintStatus.PENDING)}
                className="col-span-12 md:col-span-6 border border-yellow-400 text-black p-4 rounded-lg flex flex-row items-center gap-4 cursor-pointer hover:bg-yellow-50 group h-max animate__animated animate__fadeIn"
              >
                <div>
                  <BsInfoCircle
                    className={`text-5xl text-yellow-600 group-hover:text-yellow-700 ${
                      (this.props.selectedType === ComplaintTypeEnum.SERVICE
                        ? this.state.data.service_count[0]
                        : this.state.data.product_count[0]
                      ).total_pending > 0
                        ? "animate__animated animate__bounceIn animate__infinite animate__slower"
                        : ""
                    }`}
                  />
                </div>
                <div>
                  <div className="text-gray-500 group-hover:text-black">
                    Pending
                  </div>
                  <div className="text-3xl font-bold">
                    {commaFy(
                      (this.props.selectedType === ComplaintTypeEnum.SERVICE
                        ? this.props.selectedType === ComplaintTypeEnum.SERVICE
                          ? this.state.data.service_count[0]
                          : this.state.data.product_count[0]
                        : this.state.data.product_count[0]
                      ).total_pending
                    )}
                  </div>
                </div>
              </div>
              <div
                onClick={() => this.props.onOpenList(ComplaintStatus.PROGRESS)}
                className="col-span-12 md:col-span-6 border border-primary-300 text-black p-4 rounded-lg flex flex-row items-center gap-4 cursor-pointer hover:bg-primary-50 group h-max animate__animated animate__fadeIn"
              >
                <div>
                  <BsQuestionCircle className="text-5xl text-primary-700" />
                </div>
                <div>
                  <div className="text-gray-500 group-hover:text-black">
                    In Progress
                  </div>
                  <div className="text-3xl font-bold">
                    {commaFy(
                      (this.props.selectedType === ComplaintTypeEnum.SERVICE
                        ? this.state.data.service_count[0]
                        : this.state.data.product_count[0]
                      ).total_progress
                    )}
                  </div>
                </div>
              </div>
              <div
                onClick={() => this.props.onOpenList(ComplaintStatus.COMPLETED)}
                className="col-span-12 md:col-span-6 border border-green-400 text-black p-4 rounded-lg flex flex-row items-center gap-4 cursor-pointer hover:bg-green-50 group h-max animate__animated animate__fadeIn"
              >
                <div>
                  <BsCheckCircle className="text-5xl text-green-500" />
                </div>
                <div>
                  <div className="text-gray-500 group-hover:text-black">
                    Completed
                  </div>
                  <div className="text-3xl font-bold">
                    {commaFy(
                      (this.props.selectedType === ComplaintTypeEnum.SERVICE
                        ? this.state.data.service_count[0]
                        : this.state.data.product_count[0]
                      ).total_completed
                    )}
                  </div>
                </div>
              </div>
              <div
                onClick={() => this.props.onOpenList(ComplaintStatus.REJECTED)}
                className="col-span-12 md:col-span-6 border border-red-400 text-black p-4 rounded-lg flex flex-row items-center gap-4 cursor-pointer hover:bg-red-50 group h-max animate__animated animate__fadeIn"
              >
                <div>
                  <MdClose className="text-5xl text-red-500" />
                </div>
                <div>
                  <div className="text-gray-500 group-hover:text-black">
                    Rejected
                  </div>
                  <div className="text-3xl font-bold">
                    {commaFy(
                      (this.props.selectedType === ComplaintTypeEnum.SERVICE
                        ? this.state.data.service_count[0]
                        : this.state.data.product_count[0]
                      ).total_rejected
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-12 md:col-span-6">
            <div className="bg-white p-3 py-4 rounded-md">
              <ReactApexChart
                options={{
                  chart: {
                    width: 380,
                    type: "pie",
                  },
                  labels: ["Pending", "In Progress", "Completed", "Rejected"],
                  responsive: [
                    {
                      breakpoint: 480,
                      options: {
                        chart: {
                          width: 200,
                        },
                        legend: {
                          position: "bottom",
                        },
                      },
                    },
                  ],
                  colors: ["#fbbf24", "#1999d1", "#12b981", "#ef4444"],
                  fill: {
                    colors: ["#fbbf24", "#1999d1", "#12b981", "#ef4444"],
                  },
                }}
                series={[
                  (this.props.selectedType === ComplaintTypeEnum.SERVICE
                    ? this.state.data.service_count[0]
                    : this.state.data.product_count[0]
                  ).total_pending,
                  (this.props.selectedType === ComplaintTypeEnum.SERVICE
                    ? this.state.data.service_count[0]
                    : this.state.data.product_count[0]
                  ).total_progress,
                  (this.props.selectedType === ComplaintTypeEnum.SERVICE
                    ? this.state.data.service_count[0]
                    : this.state.data.product_count[0]
                  ).total_completed,
                  (this.props.selectedType === ComplaintTypeEnum.SERVICE
                    ? this.state.data.service_count[0]
                    : this.state.data.product_count[0]
                  ).total_rejected,
                ]}
                type="pie"
                height={244}
              />
            </div>
          </div>
          <div className="col-span-12">
            <div className="bg-white rounded-md p-3">
              <div className="flex flex-row items-center gap-3">
                <div>
                  <BsBarChartSteps className="text-3xl text-gray-400" />
                </div>
                <div className="flex flex-row justify-between gap-3 w-full">
                  <div className="w-full">
                    <div className="text-lg font-bold">Queries by reason</div>
                    <div className="text-sm text-gray-500">
                      Summary of all queries grouped in reasons
                    </div>
                  </div>
                </div>
              </div>
              <div className="pt-3">
                <div>
                  <ReactApexChart
                    options={{
                      chart: {
                        type: "bar",
                        height: 430,
                      },
                      plotOptions: {
                        bar: {
                          horizontal: true,
                          dataLabels: {
                            position: "top",
                          },
                        },
                      },
                      dataLabels: {
                        enabled: true,
                        offsetX: -6,
                        style: {
                          fontSize: "9px",
                          colors: ["#fff"],
                        },
                      },
                      stroke: {
                        show: true,
                        width: 1,
                        colors: ["#fff"],
                      },
                      tooltip: {
                        shared: true,
                        intersect: false,
                      },
                      xaxis: {
                        categories: this.GetReasonsSummary(
                          this.state.data,
                          this.props.selectedType
                        ).map((itm) => itm.complaint_reason.complaint_reason),
                      },
                    }}
                    series={[
                      {
                        data: this.GetReasonsSummary(
                          this.state.data,
                          this.props.selectedType
                        ).map((itm) => itm.total),
                        name: "Total Queries",
                      },
                    ]}
                    type="bar"
                    height={
                      this.GetReasonsSummary(
                        this.state.data,
                        this.props.selectedType
                      ).length <= 2
                        ? 130 *
                          this.GetReasonsSummary(
                            this.state.data,
                            this.props.selectedType
                          ).length
                        : this.GetReasonsSummary(
                            this.state.data,
                            this.props.selectedType
                          ).length > 2
                        ? 80 *
                          this.GetReasonsSummary(
                            this.state.data,
                            this.props.selectedType
                          ).length
                        : undefined
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  auth,
}: StoreState): {
  auth: Auth;
} => {
  return { auth };
};

export const UnitDashboard = connect(mapStateToProps, {
  FC_GetTotalComplaintsByUnit,
})(_UnitDashboard);
