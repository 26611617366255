import {
  Action,
  ActionPlanStore,
  ActionTypes,
  QuarterInterface,
} from "../actions";
import { CURRENT_DATE, DATE, isDateBetween } from "../utils/functions";
// default state
const defaultState: ActionPlanStore = {
  actionPlanPeriods: null,
  actionPlans: null,
  selectedPeriod: null,
  selectedQuarter: null,
  submittedReportsSummary: null,
  generalDashboardSummary: null,
};

/**
 * this is the action
 * @param state
 * @param action
 * @returns
 */

export const actionPlanReducer = (
  state: ActionPlanStore = defaultState,
  action: Action
) => {
  switch (action.type) {
    case ActionTypes.SET_GET_ACTION_PLAN_PERIODS:
      const selectedPeriod = action.payload.find((itm) => itm.status === 1);
      var selectedQuarter: QuarterInterface | null = null;
      if (selectedPeriod !== undefined) {
        const testQuarter = selectedPeriod.quarters.find(
          (itm) =>
            isDateBetween(
              DATE(CURRENT_DATE(), "DD/MM/YYYY"),
              DATE(itm.start_date, "DD/MM/YYYY"),
              DATE(itm.end_date, "DD/MM/YYYY")
            ) === true
        );
        if (testQuarter !== undefined) {
          selectedQuarter = testQuarter;
        }
      }
      return {
        ...state,
        actionPlanPeriods: action.payload,
        selectedPeriod: selectedPeriod === undefined ? null : selectedPeriod,
        selectedQuarter: selectedQuarter,
      };
    case ActionTypes.SET_SELECTED_PERIOD:
      const selectedQuarterForUpdate = action.payload.quarters.find(
        (itm) =>
          isDateBetween(
            DATE(CURRENT_DATE(), "DD/MM/YYYY"),
            DATE(itm.start_date, "DD/MM/YYYY"),
            DATE(itm.end_date, "DD/MM/YYYY")
          ) === true
      );
      return {
        ...state,
        selectedPeriod: action.payload,
        selectedQuarter:
          action.payload.status === 1
            ? selectedQuarterForUpdate ?? null
            : action.payload.quarters[0],
      };
    case ActionTypes.SET_SELECTED_QUARTER:
      return {
        ...state,
        selectedQuarter: action.payload,
      };
    case ActionTypes.SET_INDICATORS_ACTION_PLANS_LIST:
      return {
        ...state,
        actionPlans: action.payload,
      };
    case ActionTypes.SET_UNIT_SUBMITTED_ACTION_PLAN_REPORTS_SUMMARY:
      return {
        ...state,
        submittedReportsSummary: action.payload,
      };
    case ActionTypes.SET_ACTION_PLAN_GENERAL_DASHBOARD_SUMMARY:
      return {
        ...state,
        generalDashboardSummary: action.payload,
      };
    case ActionTypes.LOGOUT:
      return {
        ...state,
        actionPlans: null,
        submittedReportsSummary: null,
      };

    default:
      return state;
  }
};
