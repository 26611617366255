import React, { Component } from "react";
import { StoreState } from "../../reducers";
import {
  Auth,
  FC_GetAllUnits,
  FC_GetLocationsDetails,
  LocationAPI,
  System,
  UnitStore,
} from "../../actions";
import { connect } from "react-redux";
import {
  FC_ComplaintForwarding,
  ServiceComplaintItemInterface,
} from "../../actions/complaints.action";
import { ComplaintStatus, LanguageEnum } from "./ComplaintsList";
import { MdClose } from "react-icons/md";
import BackButton from "../../components/Fragments/BackButton";
import { BiLoaderCircle, BiMessageRoundedCheck } from "react-icons/bi";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { ServiceComplaintResponseForm } from "./ServiceComplaintResponseForm";
import { DATE } from "../../utils/functions";
import { UserAccessList, isAccessAuthorized } from "../../config/userAccess";
import LoaderComponent from "../../components/Loading/LoaderComponent";
import PdfViewer from "../../components/PdfViewer/PdfViewer";
import { SUPPORT_API_URL } from "../../utils/api";
import ForwardQuery from "./ForwardQuery";
import { FaForward } from "react-icons/fa";

interface ComplaintDetailsProps {
  auth: Auth;
  system: System;
  complaintDetails: ServiceComplaintItemInterface;
  selectedLanguage: LanguageEnum;
  units: UnitStore;
  onBack: () => void;
  onUpdateStatus: (
    data: {
      complaint_status: ComplaintStatus;
      complaint_reason: string;
      service_complaint_id: string;
    },
    callBack: (completedChanges: boolean) => void
  ) => void;
  loading: boolean;
  FC_GetCommunicationChannels: (callback: (loading: boolean) => void) => void;
  FC_GetAllUnits: (callback: (loading: boolean, error: string) => void) => void;
  onReload: () => void;
  FC_ComplaintForwarding: (
    data: {
      query_id: string;
      forward_from_unit_id: string;
      forward_from_unit_name: string;
      forward_to_unit_id: string;
      forward_to_unit_name: string;
      forwarded_by: string; // UserId
    },
    callback: (
      loading: boolean,
      res: {
        type: "SUCCESS" | "ERROR";
        msg: string;
      } | null
    ) => void
  ) => void;
}
interface ComplaintDetailsState {
  loading: boolean;
  addComplaintResponse: {
    status: ComplaintStatus;
  } | null;
  locations_loading: boolean;
  locations: LocationAPI | null;
  error: string;
  viewSupportingDoc: boolean;
  onForwardQuery: boolean;
}

class _ComplaintDetails extends Component<
  ComplaintDetailsProps,
  ComplaintDetailsState
> {
  constructor(props: ComplaintDetailsProps) {
    super(props);
    this.state = {
      loading: false,
      addComplaintResponse: null,
      locations_loading: false,
      locations: null,
      error: "",
      viewSupportingDoc: false,
      onForwardQuery: false,
    };
  }
  GetLocations = () => {
    this.setState({ locations_loading: true });
    FC_GetLocationsDetails(
      (
        loading: boolean,
        res: {
          data: LocationAPI | null;
          type: "Error" | "Success";
          msg: string;
        } | null
      ) => {
        if (res?.type === "Success") {
          this.setState({
            locations_loading: loading,
            locations: res.data,
            error: "",
          });
        }
        if (res?.type === "Error") {
          this.setState({
            locations_loading: false,
            error: res.msg,
          });
        }
      }
    );
  };
  GetCountryName = (countryCode: string) => {
    const country = this.state.locations?.countries.find(
      (itm) => itm.country_code === countryCode
    );
    if (country !== undefined) {
      return country.country_name;
    }
    return "";
  };
  GetDistrictName = (districtCode: string) => {
    const country = this.state.locations?.districts.find(
      (itm) => itm.district_code === districtCode
    );
    if (country !== undefined) {
      return country.district_name;
    }
    return "";
  };
  componentDidMount(): void {
    this.state.locations === null && this.GetLocations();
    if (this.props.system.communication_channels === null) {
      this.props.FC_GetCommunicationChannels((loading: boolean) =>
        this.setState({ loading: loading })
      );
    }
    this.props.units.units === null &&
      this.props.FC_GetAllUnits((loading: boolean) =>
        this.setState({ loading: loading })
      );
  }
  render() {
    return (
      <div>
        <div
          className="pb-4"
          style={{
            maxHeight: `calc(100vh - ${
              this.state.addComplaintResponse === null ? "100px" : "500px"
            })`,
            overflowY: "auto",
          }}
        >
          <div className="flex flex-row items-center justify-between gap-3 p-4 pb-4">
            <div className="flex flex-row items-center gap-2">
              <BackButton
                title={"Back"}
                onClick={this.props.onBack}
                className="bg-primary-50 hover:bg-primary-100 text-primary-900"
              />
              <div>
                <div className="text-xl font-bold">Query Details</div>
              </div>
            </div>
            <div>
              <div
                onClick={this.props.onBack}
                className="h-10 w-10 rounded-full bg-red-50 hover:bg-red-100 text-red-700 cursor-pointer flex items-center justify-center"
              >
                <MdClose className="text-2xl" />
              </div>
            </div>
          </div>
          <div className="p-4 pb-0 pt-0">
            <div className="bg-primary-50 border border-primary-300 p-4 rounded-md">
              <div className="border-b pb-2 mb-2 flex flex-row items-center justify-between gap-2 w-full">
                <div>
                  <div className="text-sm">Tracking Code</div>
                  <div className="font-bold text-primary-700">
                    {this.props.complaintDetails.complaint_tracking_code}
                  </div>
                </div>
                {this.props.system.communication_channels !== null ? (
                  <div className="bg-primary-700 text-white font-normal w-max rounded-md px-2">
                    {
                      this.props.system.communication_channels.find(
                        (itm) =>
                          itm.communication_channel_id ===
                          this.props.complaintDetails.communication_channel_id
                      )?.communication_channel
                    }
                  </div>
                ) : (
                  <div className="text-yellow-600 animate__animated animate__fadeIn animate__infinite">
                    Loading...
                  </div>
                )}
              </div>
              <div className="border-b pb-2 mb-2">
                <div className="text-sm">Service Category</div>
                <div className="font-bold">
                  {this.props.selectedLanguage === LanguageEnum.ENG
                    ? this.props.complaintDetails.eng_service_category
                    : this.props.selectedLanguage === LanguageEnum.FR
                    ? this.props.complaintDetails.fr_service_category
                    : this.props.complaintDetails.kiny_service_category}
                </div>
              </div>
              {/*  */}
              <div className="border-b pb-2 mb-2">
                <div className="text-sm">Service</div>
                <div className="font-bold">
                  {this.props.selectedLanguage === LanguageEnum.ENG
                    ? this.props.complaintDetails.eng_service
                    : this.props.selectedLanguage === LanguageEnum.FR
                    ? this.props.complaintDetails.fr_service
                    : this.props.complaintDetails.kiny_service}
                </div>
              </div>
              <div>
                <div className="text-sm">Unit</div>
                <div className="font-bold text-black">
                  {
                    this.props.units.units?.find(
                      (itm) =>
                        itm.unit_id.toString() ===
                        this.props.complaintDetails.unit_id.toString()
                    )?.unit_name
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="p-4 py-2">
            <div className="bg-yellow-50 border border-yellow-300 p-4 rounded-md">
              <div className="border-b pb-2 mb-2">
                <div className="text-sm text-gray-500">Query type</div>
                <div className="text-yellow-700 font-bold">
                  {this.props.selectedLanguage === LanguageEnum.ENG
                    ? this.props.complaintDetails.complaint_reason_en
                    : this.props.selectedLanguage === LanguageEnum.FR
                    ? this.props.complaintDetails.complaint_reason_fr
                    : this.props.complaintDetails.complaint_reason_kiny}
                </div>
              </div>
              <div className="border-b pb-2 mb-2">
                <div className="text-sm text-gray-500">Query Description</div>
                <div className="text-sm font-bold">
                  {this.props.complaintDetails.description}
                </div>
              </div>
              {this.props.complaintDetails.rfd_offer_name !== null && (
                <div className="border-b pb-2 mb-2">
                  <div className="text-sm text-gray-500">
                    Name of Rwanda FDA Officer involved
                  </div>
                  <div className="text-sm font-bold">
                    {this.props.complaintDetails.rfd_offer_name}
                  </div>
                </div>
              )}
              {this.props.complaintDetails.support_doc_name !== null &&
                (this.state.viewSupportingDoc === false ? (
                  <div className="mb-2">
                    <div className="text-sm text-gray-500">
                      Supporting Document
                    </div>
                    <div className=" pt-2">
                      <div
                        onClick={() =>
                          this.setState({
                            viewSupportingDoc: !this.state.viewSupportingDoc,
                          })
                        }
                        className="px-4 py-2 rounded-md bg-white font-bold text-sm w-max cursor-pointer border border-yellow-400 hover:border-white hover:bg-yellow-600 hover:text-white"
                      >
                        Click to view supporting document
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="rounded-md overflow-hidden bg-gray-500 p-3 mb-3 text-white animate__animated animate__fadeIn">
                    <div className="flex flex-row items-center justify-between gap-2 mb-3">
                      <div className="font-bold text-lg pl-4">
                        Supporting Document
                      </div>
                      <div>
                        <div
                          onClick={() =>
                            this.setState({ viewSupportingDoc: false })
                          }
                          className="flex items-center justify-center rounded-full bg-gray-400 text-white hover:bg-gray-700 cursor-pointer h-8 w-8"
                        >
                          <MdClose className="text-2xl" />
                        </div>
                      </div>
                    </div>
                    {this.props.complaintDetails.support_doc_name.includes(
                      "pdf"
                    ) === false ? (
                      <img
                        className="w-full h-auto rounded-md"
                        src={`${SUPPORT_API_URL}/doc/${this.props.complaintDetails.support_doc_name}`}
                        alt={this.props.complaintDetails.eng_service!}
                      />
                    ) : (
                      <PdfViewer
                        file_url={`${SUPPORT_API_URL}/doc/${this.props.complaintDetails.support_doc_name}`}
                        class_name={"w-full min-h-screen rounded-md"}
                        frame_title={"Product preview"}
                        setLoadingFile={(state: boolean) =>
                          this.setState({ loading: state })
                        }
                      />
                    )}
                  </div>
                ))}
              <div
                className={`${
                  this.props.complaintDetails.country_code !== null
                    ? "border-b pb-2 mb-2"
                    : ""
                }`}
              >
                <div className="text-sm text-gray-500">Query Date</div>
                <div className="text-sm font-bold">
                  {DATE(this.props.complaintDetails.created_at)}
                </div>
              </div>
              <div
                className={`flex flex-row items-center justify-between w-full gap-3`}
              >
                {this.props.complaintDetails.country_code !== null && (
                  <div className={`w-full`}>
                    <div className="text-sm text-gray-500">
                      Query Country location
                    </div>
                    <div className="text-sm font-bold">
                      {this.GetCountryName(
                        this.props.complaintDetails.country_code
                      )}
                    </div>
                  </div>
                )}
                {this.props.complaintDetails.district_code !== null && (
                  <div className="w-full">
                    <div className="text-sm text-gray-500">
                      Query District location
                    </div>
                    <div className="text-sm font-bold">
                      {this.GetDistrictName(
                        this.props.complaintDetails.district_code
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {this.props.complaintDetails.phone_number !== null && (
            <div className="p-4 pt-0 pb-0">
              <div className="bg-gray-100 border border-gray-300 p-4 rounded-md">
                <div className="grid grid-cols-12 gap-4">
                  <div className="col-span-12">
                    <div className="font-bold text-xl">Customer Details</div>
                  </div>
                  <div className="col-span-12 md:col-span-6 lg:col-span-4 flex flex-col text-sm">
                    <div className="text-gray-600">Full names</div>
                    <div className="font-bold">
                      {this.props.complaintDetails.names}
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-6 lg:col-span-4 flex flex-col text-sm">
                    <div className="text-gray-600">Gender</div>
                    <div className="font-bold">
                      {this.props.complaintDetails.gender}
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-6 lg:col-span-4 flex flex-col text-sm">
                    <div className="text-gray-600">Age</div>
                    <div className="font-bold">
                      {this.props.complaintDetails.age}
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-6 lg:col-span-4 flex flex-col text-sm">
                    <div className="text-gray-600">Phone Number</div>
                    <div className="font-bold">
                      {this.props.complaintDetails.phone_number}
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-6 lg:col-span-4 flex flex-col text-sm">
                    <div className="text-gray-600">Email</div>
                    <div className="font-bold">
                      {this.props.complaintDetails.email}
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-6 lg:col-span-4 flex flex-col text-sm">
                    <div className="text-gray-600">Country</div>
                    <div className="font-bold">
                      {this.props.complaintDetails.country_code !== null &&
                        this.GetCountryName(
                          this.props.complaintDetails.country_code
                        )}
                    </div>
                  </div>
                  {this.props.complaintDetails.district_code !== null && (
                    <div className="col-span-12 md:col-span-6 lg:col-span-4 flex flex-col text-sm">
                      <div className="text-gray-600">District</div>
                      <div className="font-bold">
                        {this.GetDistrictName(
                          this.props.complaintDetails.district_code
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        {this.state.onForwardQuery === true ? (
          <div className="fixed bottom-0 left-0 right-0">
            <ForwardQuery
              productComplaint={null}
              serviceComplaint={this.props.complaintDetails}
              onClose={() => this.setState({ onForwardQuery: false })}
              onSubmitted={this.props.onReload}
              FC_ComplaintForwarding={this.props.FC_ComplaintForwarding}
              auth={this.props.auth}
            />
          </div>
        ) : this.state.addComplaintResponse === null ? (
          <div className="">
            {this.props.complaintDetails.complaint_status ===
            ComplaintStatus.PENDING ? (
              <div className="p-4 bg-gray-600 text-white border-t-4 border-yellow-500 flex flex-row items-center justify-between gap-3 w-full">
                <div>
                  <div className="text-sm">Current Status</div>
                  <div className="font-bold text-xl text-yellow-400 animate__animated animate__fadeIn animate__slower animate__infinite">
                    Waiting for response
                  </div>
                </div>
                {isAccessAuthorized(
                  this.props.auth.selectedEmployment,
                  UserAccessList.REPLY_COMPLAINT
                ).view === true && (
                  <div className="flex flex-row items-center justify-end gap-2">
                    <div
                      onClick={() =>
                        this.setState({
                          addComplaintResponse: {
                            status: ComplaintStatus.PROGRESS,
                          },
                        })
                      }
                      className="flex flex-row items-center justify-center gap-2 w-max px-3 py-2 rounded-md bg-primary-700 hover:bg-primary-800 text-white cursor-pointer"
                    >
                      <div>
                        <BiMessageRoundedCheck className="text-2xl" />
                      </div>
                      <span>Submit Response</span>
                    </div>
                    <div
                      onClick={() =>
                        this.state.loading === false &&
                        this.setState({
                          onForwardQuery: true,
                        })
                      }
                      className="flex flex-row items-center justify-center gap-2 w-max px-3 py-2 rounded-md bg-yellow-500 hover:bg-yellow-600 text-white cursor-pointer"
                    >
                      <div>
                        <FaForward className="text-2xl" />
                      </div>
                      <span>Forward the Query</span>
                    </div>
                  </div>
                )}
              </div>
            ) : this.props.complaintDetails.complaint_status ===
              ComplaintStatus.PROGRESS ? (
              <div className="p-4 bg-gray-600 text-white border-t-4 border-primary-700 flex flex-row items-center justify-between gap-3 w-full">
                <div>
                  {/* <div className="text-sm">Current Status</div> */}
                  <div className="font-bold text-xl text-primary-300 flex flex-row items-center gap-3">
                    <div className="animate__animated animate__fadeIn animate__slower animate__infinite">
                      <BiLoaderCircle className="text-5xl animate-spin text-yellow-400" />
                    </div>
                    <div>
                      <div className="text-yellow-200">In Progress</div>
                      <div className="text-sm text-gray-300 font-normal">
                        {this.props.complaintDetails.progress_reason}
                      </div>
                      <div className="text-xs text-white font-normal bg-gray-500 w-max px-2 rounded-full">
                        Date:{" "}
                        {this.props.complaintDetails.progress_date !== null &&
                          DATE(this.props.complaintDetails.progress_date)}
                      </div>
                    </div>
                  </div>
                </div>
                {isAccessAuthorized(
                  this.props.auth.selectedEmployment,
                  UserAccessList.REPLY_COMPLAINT
                ).view === true && (
                  <div>
                    <div
                      onClick={() =>
                        this.setState({
                          addComplaintResponse: {
                            status: ComplaintStatus.COMPLETED,
                          },
                        })
                      }
                      className="flex flex-row items-center justify-center gap-2 w-max px-3 py-2 rounded-md bg-primary-700 hover:bg-primary-800 text-white cursor-pointer"
                    >
                      <div>
                        <BiMessageRoundedCheck className="text-2xl" />
                      </div>
                      <span>Submit Response</span>
                    </div>
                  </div>
                )}
              </div>
            ) : this.props.complaintDetails.complaint_status ===
              ComplaintStatus.COMPLETED ? (
              <div className="p-4 bg-gray-600 text-white border-t-4 border-green-600 flex flex-row items-center justify-between gap-3 w-full">
                <div className="w-full">
                  {/* <div className="text-sm">Current Status</div> */}
                  <div className="font-bold text-xl text-green-300 flex flex-row items-center gap-3">
                    <div className="">
                      <BsFillCheckCircleFill className="text-5xl text-green-300" />
                    </div>
                    <div>
                      <div className="text-white">Completed</div>
                      <div className="text-sm text-gray-300 font-normal">
                        {this.props.complaintDetails.completed_reason}
                      </div>
                      <div className="text-xs text-white font-normal bg-gray-500 w-max px-2 rounded-full">
                        Date:{" "}
                        {this.props.complaintDetails.completed_date !== null &&
                          DATE(this.props.complaintDetails.completed_date)}
                      </div>
                    </div>
                  </div>
                </div>
                {this.props.complaintDetails.customer_feedback !== null ? (
                  <div className="w-full">
                    <div>
                      <div className="font-bold">Customer Feedback</div>
                      <div className="text-sm text-gray-200 font-light">
                        {this.props.complaintDetails.customer_feedback}
                      </div>
                      <div className="text-xs text-white font-normal bg-gray-500 w-max px-2 rounded-full">
                        Date:{" "}
                        {this.props.complaintDetails.customer_feedback_date !==
                          null &&
                          DATE(
                            this.props.complaintDetails.customer_feedback_date
                          )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="bg-gray-500 rounded-md px-4 py-3 w-2/3">
                    <div className="text-xl font-bold">Customer Feedback</div>
                    <div className="text-sm text-gray-300">
                      No Feedback has been submitted
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="p-4 bg-gray-600 text-white border-t-4 border-red-500 flex flex-row items-center justify-between gap-3 w-full">
                <div className="w-full">
                  {/* <div className="text-sm">Current Status</div> */}
                  <div className="font-bold text-xl text-red-300 flex flex-row items-center gap-3">
                    <div className="">
                      <MdClose className="text-5xl text-red-300" />
                    </div>
                    <div>
                      <div className="text-white">Rejected</div>
                      <div className="text-sm text-gray-300 font-normal">
                        {this.props.complaintDetails.rejected_reason}
                      </div>
                      <div className="text-xs text-white font-normal bg-gray-500 w-max px-2 rounded-full">
                        Date:{" "}
                        {this.props.complaintDetails.rejected_date !== null &&
                          DATE(this.props.complaintDetails.rejected_date)}
                      </div>
                    </div>
                  </div>
                </div>
                {this.props.complaintDetails.customer_feedback !== null ? (
                  <div className="w-full">
                    <div>
                      <div className="font-bold">Customer Feedback</div>
                      <div className="text-sm text-gray-200 font-light">
                        {this.props.complaintDetails.customer_feedback}
                      </div>
                      <div className="text-xs text-white font-normal bg-gray-500 w-max px-2 rounded-full">
                        Date:{" "}
                        {this.props.complaintDetails.customer_feedback_date !==
                          null &&
                          DATE(
                            this.props.complaintDetails.customer_feedback_date
                          )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="bg-gray-500 rounded-md px-4 py-3 w-2/3">
                    <div className="text-xl font-bold">Customer Feedback</div>
                    <div className="text-sm text-gray-300">
                      No Feedback has been submitted
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        ) : this.props.loading === true ? (
          <LoaderComponent />
        ) : (
          <div className="p-4 animate__animated animate__fadeInUp animate__faster">
            <ServiceComplaintResponseForm
              complaintDetails={this.props.complaintDetails}
              onBack={() => this.setState({ addComplaintResponse: null })}
              onSubmitted={this.props.onUpdateStatus}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({
  auth,
  system,
  units,
}: StoreState): {
  auth: Auth;
  system: System;
  units: UnitStore;
} => {
  return { auth, system, units };
};

export const ServiceComplaintDetails = connect(mapStateToProps, {
  FC_GetAllUnits,
  FC_ComplaintForwarding,
})(_ComplaintDetails);
